import config from "../../config";
const getCancellationPolicy = async (
  hotelId,
  setCancellationPolicy,
  setNote,
  // setDisplayLoader
  setOpenCancellationPolicyModal
) => {
  fetch(
    config.apiEndpoint +
    "/be_cancellation_policy/fetch_cancellation_policy_frontview/" +
    hotelId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        // setDisplayLoader(false);
        if (responseJson.cancellation_policy) {
          setCancellationPolicy(
            responseJson.cancellation_policy.policy_data_array
          );
        }
        setNote(responseJson.policy_info);

        setOpenCancellationPolicyModal(true);
      } else {
        setOpenCancellationPolicyModal(true);
        // setDisplayLoader(false);
      }
    })
    .catch((error) => { });
};
export default getCancellationPolicy;
