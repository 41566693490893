import config from "../../../config";
import moment from "moment";

const modifyBookRequest = (hotelId, modifyBookingData, datesSelected, modifyGuestFirstName, modifyGuestLastName, modifyCart, setUpdateUserDetails, totalAmountToPay, setModifyBookingLoader, bool_value, setCallUserAPI) => {

    let paid_amount = parseInt(modifyBookingData.paid_amount);
    let to_pay = parseInt(totalAmountToPay);

    let url = config.apiEndpoint + "be_modification";
    let from_date = moment(datesSelected.endDate).format("YYYY-MM-DD");
    let to_date = moment(datesSelected.startDate).format("YYYY-MM-DD");
    let invoice_id = parseInt(modifyBookingData.invoice_id)
    let booking_id = modifyBookingData.booking_id

    fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "content-type": "text/json",
        },
        body: JSON.stringify({
            hotel_id: hotelId,
            invoice_id: invoice_id,
            check_in: to_date,
            check_out: from_date,
            first_name: modifyGuestFirstName,
            last_name: modifyGuestLastName,
            cart: modifyCart,
            booking_id: booking_id
        }),
    })
        .then((response) => response.json())
        .then((responseJson) => {

            if (responseJson.status === 1) {

                if (responseJson.securehash && paid_amount < to_pay) {
                    let send_invoice_id = btoa(invoice_id);
                    let str = window.location.origin;
                    if (str.search("bookingjini.com") >= 0) {
                        var url =
                            "https://be.bookingjini.com/payment/" +
                            send_invoice_id +
                            "/" +
                            responseJson.securehash;
                    } else {
                        var url =
                            str +
                            "/payment/" +
                            send_invoice_id +
                            "/" +
                            responseJson.securehash;
                    }
                    window.location.assign(url);
                }
                else {

                    setModifyBookingLoader(false);
                    setUpdateUserDetails({ status: true, message: responseJson.message, class: "success-icon" });
                    setCallUserAPI(!bool_value);
                }

            }
            else {
                setModifyBookingLoader(false);
                setUpdateUserDetails({ status: true, message: "Modification Failed", class: "cancel-icon" });
            }
        });
};



export default modifyBookRequest;