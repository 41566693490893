import React, { useState, useContext, useEffect } from 'react';
import fetchOtaRates from './fetchOtaRates';
import {
  HotelIdContext,
  DateRangeContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  CurrencyContext,
  ApiKeyContext,

  PriceWidgetContext

} from '../../settings/Store';
import getCurrencyValue from '../BookingPanel/Cart/getCurrencyValue';

const FairRatesComponent = props => {
  const [otaRates, setOtaRates] = useState([]);
  const [hotelid] = useContext(HotelIdContext);
  const [dateRange] = useContext(DateRangeContext);
  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);
  const [currency] = useContext(CurrencyContext);
  const [apiKey] = useContext(ApiKeyContext);

  const [displayPriceWidget] = useContext(PriceWidgetContext);

  //Get the currency value
  const [currencyValue, setCurrencyValue] = useState(1);
  useEffect(() => {
    if (baseCurrency !== null && baseCurrency !== '' && currency !== null) {
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency]);
  //Api call to get OTA rates
  useEffect(() => {
    if (hotelid !== 0) {
      fetchOtaRates(hotelid, dateRange, baseCurrency, apiKey, setOtaRates);
    }
  }, [hotelid, dateRange, baseCurrency, apiKey, setOtaRates]);
  const [modalStatus, setModalStatus] = useState(
    window.screen.width <= 480 ? false : true
  );
  const closeModal = () => {
    setModalStatus(false);
  };
  const openModal = () => {
    setModalStatus(true);
  };
  const leftStyle = {
    left: '-281px'
  };
  const price =
    otaRates &&
    Object.entries(otaRates).map(otaRate => {
      return otaRate[1][0];
    });

  return (
    <div>
      {displayPriceWidget === 1 && <div>
        {otaRates && Object.keys(otaRates).length > 0 && (
          <div
            className='fare-calendar-wrapper'
            style={!modalStatus ? leftStyle : { left: 0 }}>
            <span
              className='fare-rates'
              style={{ cursor: 'pointer' }}
              onClick={() => openModal()}>
              Deal of the day
          </span>
            <div className='border'>
              <span className='btn-close' onClick={() => closeModal()} />
              <div className='calendar-heading'>
                <div className='book-with-us'>Book with us</div>
                {otaRates &&
                  Object.entries(otaRates).map((otaRate, index) => {
                    if (otaRate[0] === 'BE') {
                      const strikedTextApplicable =
                        currencyValue * otaRate[1].min_rack_price.toFixed(2) >
                          (currencyValue * otaRate[1].min_room_price).toFixed(2)
                          ? true
                          : false;
                      return (
                        <div key={index} className='price-tag'>
                          {strikedTextApplicable && (
                            <span>
                              <strike>
                                <i className={currencySymbol} />
                                {parseFloat((
                                  currencyValue * otaRate[1].min_rack_price
                                ).toFixed(2)).toLocaleString('en-IN')}
                              </strike>
                            </span>
                          )}

                          <br />
                          <i className={currencySymbol} />
                          <strong>
                            {parseFloat((currencyValue * otaRate[1].min_room_price).toFixed(
                              2
                            )).toLocaleString('en-IN')}
                          </strong>
                        </div>
                      );
                    }
                  })}
              </div>
              {otaRates &&
                Object.entries(otaRates).map((otaRate, index) => {
                  if (otaRate[0] !== 'BE') {
                    return (
                      <div key={index} className='ota-list'>
                        <span className='list-col'>{otaRate[0]}</span>
                        <span className='list-col'>
                          <i className={currencySymbol} />
                          {parseFloat((currencyValue * otaRate[1][0]).toFixed(2)).toLocaleString('en-IN')}
                        </span>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </div>}
    </div>
  );
};
//Export Default FairRatesComponent
export default FairRatesComponent;
