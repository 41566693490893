import config from '../../config';
const fetchHotelInfo = async (
  hotelId,
  apiKey,
  setHotelInfo,
  // setAdvanceDays,
  setOverSeaFilter,
  setCountryID,
  setIsTaxable
) => {

  fetch(
    config.apiEndpoint +
    'bookingEngine/get-hotel-info/' +
    apiKey +
    '/' +
    hotelId,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.status === 1) {

        if (responseJson.data) {
          let star_property = [];
          if (responseJson.data.star_of_property && responseJson.data.star_of_property !== '' && responseJson.data.star_of_property !== null && responseJson.data.star_of_property !== 0) {
            for (let i = 1; i <= responseJson.data.star_of_property; i++) {
              star_property.push(1);
            }
          }
          responseJson.data.total_star_property = star_property;
        }

        setHotelInfo(responseJson.data);

        // setAdvanceDays(responseJson.data.advance_booking_days);

        setOverSeaFilter(responseJson.data.is_overseas);
        setCountryID(responseJson.data.country_id);

        setIsTaxable(responseJson.data.is_taxable)

        sessionStorage.setItem('facebook_pixel_data', JSON.stringify(responseJson.data.facebook_pixel));
        sessionStorage.setItem('google_tag_manager', JSON.stringify(responseJson.data.google_tag_manager));
      }
    })
    .catch(error => { });
};
//Export getAccess
export default fetchHotelInfo;
