import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import ManageBookings from "./ManageBookings";
import ManagePackage from "./ManagePackage";
import CancellationPolicy from "./CancellationPolicy";
import HotelPolicy from "./HotelPolicy";

import CancelledBookingReport from "./CancelledBookingReport";
const UserBooings = () => {
  const [isOpenModal, setOpenModal] = useState(false);
  const openBooking = () => {
    setOpenModal(true);
  };
  return (
    <section className="login-section">
      <div className="container container-width top-margin">
        <div
          // className="table-content-wrapper"
          style={{ border: "none", boxShadow: "none" }}
        >
          <Tabs>
            <TabList>
              <Tab>Confirm Bookings</Tab>
              <Tab>Cancelled Bookings</Tab>
              {/* <Tab>Cancellation policy</Tab> */}
              {/* <Tab>Hotel policy</Tab> */}
            </TabList>

            <TabPanel>
              <ManageBookings />
            </TabPanel>

            <TabPanel>
              <CancelledBookingReport />
            </TabPanel>

            {/* <TabPanel>
              <CancellationPolicy />
            </TabPanel> */}

            {/* <TabPanel>
              <HotelPolicy />
            </TabPanel> */}
          </Tabs>
        </div>
      </div>
    </section>
  );
};
//Export UserBookings
export default UserBooings;
