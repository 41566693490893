import React, { Component } from 'react';
import ReactSnackBar from 'react-js-snackbar';

class Snackbar extends Component {
  state = {
    Show: false,
    Showing: false
  };

  componentDidMount() {
    if (this.state.Showing) return;

    this.setState({ Show: true, Showing: true });
    setTimeout(() => {
      this.setState({ Show: false, Showing: false });
      this.props.setPopupOpen(false);
    }, 2000);
  }
  render() {
    return (
      <div>
        <ReactSnackBar Show={this.state.Show}>
          {this.props.message}
        </ReactSnackBar>
      </div>
    );
  }
}

export default Snackbar;
