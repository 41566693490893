import React, { useState, useEffect } from "react";
import moment from "moment";
import getAccess from "./getAccess";
// import { withRouter } from 'react-router-dom';
import VisitorSaveComponent from "../components/VisitorSaveComponet";
export const CompanyContext = React.createContext();
export const ApiKeyContext = React.createContext();
export const CompHashContext = React.createContext();
export const CompanyIdContext = React.createContext();
export const ChatOptionContext = React.createContext();
export const DateRangeContext = React.createContext();
export const TempDateRangeContext = React.createContext();
export const HotelIdContext = React.createContext();
export const CurrencyContext = React.createContext();
export const CurrencySymbolContext = React.createContext();
export const BaseCurrencyContext = React.createContext();
export const ReferenceFromContext = React.createContext();
export const PackageDateContext = React.createContext();
export const GemsRequestContext = React.createContext();
export const BeUrlContext = React.createContext();
export const TotalPriceContext = React.createContext();
export const TotalDiscountContext = React.createContext();
export const NotFoundContext = React.createContext();
export const AdvanceBookingContext = React.createContext();

export const PriceWidgetContext = React.createContext();

//Refund Protect
export const RefundProtectContext = React.createContext();
//Refund Protect

export const GuestPermissionContext = React.createContext();

const Store = ({ children, setNotFound, setThemeColor }) => {
  //fetchCompanyApi
  const [compState, setCompState] = useState(0);
  const [advanceDays, setAdvanceDays] = useState(0);
  const [compHash, setCompHash] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [chatOption, setChatOption] = useState(0);
  const [hotelId, setHotelId] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [tempDateRange, setTempDateRange] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });

  const [currency, setCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("fa fa-inr");
  const [baseCurrency, setBaseCurrency] = useState("");
  const [loading, setLoading] = useState(true);
  const [referencefrom, setReferenceFrom] = useState("");
  const [packageDate, setPackageDate] = useState(moment());
  const [gemsRequest, setGemsRequest] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const [priceWidget, setPriceWidget] = useState(0);

  //Refund Protect
  const [optedForRefund, setOptedForRefund] = useState({ status: 0, mode: "" });
  //Refund Protect

  const [guestPermission, setGuestPermission] = useState({
    modify: "",
    cancel: "",
  });

  let comp = window.location.origin;

  // These are some url of hotels for working in local dev
  // let comp = "https://silversandhotels.bookingjini.com";

  const [beUrl, setBeUrl] = useState(comp);
  let company_url =
    comp.search("https") > -1
      ? comp.replace("https://", "")
      : comp.replace("http://", "");
  useEffect(() => {
    if (hotelId === 0 && company_url) {
      getAccess(
        company_url,
        setCompState,
        setCompHash,
        setApiKey,
        setChatOption,
        setHotelId,
        setCurrency,
        hotelId,
        comp,
        setNotFound,

        setPriceWidget,

        //Refund Protect
        setOptedForRefund,
        //Refund Protect

        setGuestPermission,
        setThemeColor
      );
    }
  }, [
    company_url,
    setCompState,
    setCompHash,
    setApiKey,
    setChatOption,
    setHotelId,
    setCurrency,
    hotelId,
    setPriceWidget,
  ]);
  useEffect(() => {
    if (hotelId !== 0) {
      setLoading(false);
    }
  }, [hotelId]);
  let deviceInfo = navigator.userAgent;
  let href = window.location.href;
  let previousUrl = document.referrer;
  //API call to save the visitor
  useEffect(() => {
    if (compState !== 0 && hotelId !== 0) {
      VisitorSaveComponent(compState, hotelId, deviceInfo, href, previousUrl);
    }
  }, [hotelId, compState]);
  return (
    <div>
      {loading && (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {hotelId !== 0 && (
        <CompanyContext.Provider value={[compState, setCompState]}>
          <ApiKeyContext.Provider value={[apiKey, setApiKey]}>
            <CompHashContext.Provider value={[compHash, setCompHash]}>
              <PriceWidgetContext.Provider
                value={[priceWidget, setPriceWidget]}
              >
                <GemsRequestContext.Provider
                  value={[gemsRequest, setGemsRequest]}
                >
                  <ChatOptionContext.Provider
                    value={[chatOption, setChatOption]}
                  >
                    <DateRangeContext.Provider
                      value={[dateRange, setDateRange]}
                    >
                      <TempDateRangeContext.Provider
                        value={[tempDateRange, setTempDateRange]}
                      >
                        <HotelIdContext.Provider value={[hotelId, setHotelId]}>
                          <CurrencyContext.Provider
                            value={[currency, setCurrency]}
                          >
                            <BaseCurrencyContext.Provider
                              value={[baseCurrency, setBaseCurrency]}
                            >
                              <CurrencySymbolContext.Provider
                                value={[currencySymbol, setCurrencySymbol]}
                              >
                                <PackageDateContext.Provider
                                  value={[packageDate, setPackageDate]}
                                >
                                  <BeUrlContext.Provider
                                    value={[beUrl, setBeUrl]}
                                  >
                                    <TotalDiscountContext.Provider
                                      value={[totalDiscount, setTotalDiscount]}
                                    >
                                      <TotalPriceContext.Provider
                                        value={[totalPrice, setTotalPrice]}
                                      >
                                        <AdvanceBookingContext.Provider
                                          value={[advanceDays, setAdvanceDays]}
                                        >
                                          <RefundProtectContext.Provider
                                            value={[
                                              optedForRefund,
                                              setOptedForRefund,
                                            ]}
                                          >
                                            <GuestPermissionContext.Provider
                                              value={[
                                                guestPermission,
                                                setGuestPermission,
                                              ]}
                                            >
                                              {children}
                                            </GuestPermissionContext.Provider>
                                          </RefundProtectContext.Provider>
                                        </AdvanceBookingContext.Provider>
                                      </TotalPriceContext.Provider>
                                    </TotalDiscountContext.Provider>
                                  </BeUrlContext.Provider>
                                </PackageDateContext.Provider>
                              </CurrencySymbolContext.Provider>
                            </BaseCurrencyContext.Provider>
                          </CurrencyContext.Provider>
                        </HotelIdContext.Provider>
                      </TempDateRangeContext.Provider>
                    </DateRangeContext.Provider>
                  </ChatOptionContext.Provider>
                </GemsRequestContext.Provider>
              </PriceWidgetContext.Provider>
            </CompHashContext.Provider>
          </ApiKeyContext.Provider>
        </CompanyContext.Provider>
      )}
    </div>
  );
};
export default Store;
