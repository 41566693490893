import React, { useContext, useState, useEffect } from "react";
// import getTaxRules from "../../BookingPanel/Rooms/getTaxRules";
import {
  HotelIdContext,
  CompHashContext,
  CurrencyContext,
  CurrencySymbolContext,
  ApiKeyContext,//-----------gst and non gst calculation------------//
  DateRangeContext,
  CompanyContext,
  BaseCurrencyContext,
  TempDateRangeContext,
  PackageDateContext,
} from "../../../settings/Store";
import fetchPackages from "./fetchPackages";
import fetchPackageDetails from "./fetchPackageDetails";
import getCurrencyValue from "../../BookingPanel/Cart/getCurrencyValue";
import RoomPreviewLoader from "../../BookingPanel/Rooms/RoomPreviewLoader";
import fetchHotels from "../../HotelListPanel/fetchHotels";
import ExternalImageSlide from "../../HotelInfo/externalImageSlide";
import moment from "moment";
import config from "../../../config";
import "../../../css/packages.css";

import ImageSlider from "../../BookingPanel/Rooms/RoomImageSlider"; //-----package image & description display modal------//
import RoomMultipleImagesSlider from "../../BookingPanel/Rooms/RoomMultipleImagesSlider"; //-----package image & description display modal------//
import parse from "html-react-parser";
import fetchHotelInfo from "../Cart/fetchHotelInfo"; //-----------gst and non gst calculation------------//
import { SingleDatePicker } from "react-dates"; //----------------- datepicker for check-in date---------------//
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import getLocaleDetails from "../Cart/getLocaleDetails";

const Packages = (props) => {
  const [hotelId, setHotelId] = useContext(HotelIdContext);
  const [apiKey] = useContext(ApiKeyContext);//-----------gst and non gst calculation------------//
  const [companyId] = useContext(CompanyContext);
  const [compHash] = useContext(CompHashContext);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [currencySymbol, setCurrencySymbol] = useContext(CurrencySymbolContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);
  const [packageInventory, setPackageInventory] = useState([]);
  const [convertedPackageInventory, setConvertedPackageInventory] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useContext(DateRangeContext);
  const [tempDateRange, setTempDateRange] = useContext(TempDateRangeContext);
  const [packageDate, setPackageDate] = useContext(PackageDateContext);
  // const [taxRules, setTaxRules] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [hotelCount, setHotelCount] = useState(1);
  const [packageImagesOpen, setPackageImagesOpen] = useState(false);
  const [curImageUrl, setCurImageUrl] = useState("");
  const [selectedNoOfPackage, setSelectedNoOfPackage] = useState([]); //--------- select box for multiple room selecion--------//
  const [packageImageEndPoint, setpackageImageEndPoint] = useState(); //-----------package images listing---------------//
  const [focused, setFocused] = useState(false); //----------used in datepicker for check-in date------------------//
  //Cart Settings
  const [openDropdown, setOpenDropdown] = useState({});//----------select box for multiple package selection-----------//
  const [cart, setCart] = useState({});//-----multiple package cart-----------//
  const [addBtnStatus, setAddBtnStatus] = useState([]);

  const [disableAddPackageBtn, setdisableAddPackageBtn] = useState(false); //----------disable multiple package booking------------------//
  //-----------gst and non gst calculation------------//
  const [OverSeaFilter, setOverSeaFilter] = useState();

  const [isTaxable, setIsTaxable] = useState();

  const [hotelData, setHotelData] = useState();
  const [countryID, setCountryID] = useState();

  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      fetchHotelInfo(hotelId, apiKey, setHotelData, setOverSeaFilter, setCountryID, setIsTaxable);//-------gst and non gst calculation--------//
    }
  }, [hotelId, apiKey, setHotelData]);
  //-----------end of gst and non gst calculation------------//
  // getting the data from query string
  const queryString = window.location.search.replace("?q=", ""); // remove the "?", "&" and "="

  // sessionStorage.setItem('gems', gems);
  let data = queryString !== null ? atob(decodeURI(queryString)) : "";
  // useEffect(() => {
  //   console.log(JSON.stringify(packageInventory[0]));
  // }, [packageInventory]);
  useEffect(() => {
    if (data) {
      let queryStringData = data.split("|");
      queryStringData[3] &&
        sessionStorage.setItem("name", queryStringData[3].trim());
      queryStringData[4] &&
        sessionStorage.setItem("email", queryStringData[4].trim());
      queryStringData[5] &&
        sessionStorage.setItem("phone", queryStringData[5].trim());
    }
  }, [data]);

  useEffect(() => {
    if (compHash !== "" && companyId !== 0) {
      fetchHotels(compHash, companyId, setHotelList);
    }
  }, []);
  useEffect(() => {
    // console.log(
    //   'Package start date: ' + moment(packageDate).format('DD-MM-YYYY')
    // );
    if (hotelId !== 0 && currency !== null) {
      setLoading(true);
      fetchPackages(
        hotelId,
        packageDate,
        currency,
        setPackageInventory,
        setConvertedPackageInventory,
        setpackageImageEndPoint,
        setLoading,
        setOpenDropdown
      );
    }
    // if (hotelId !== 0 && companyId !== 0) {
    //   getTaxRules(companyId, hotelId, setTaxRules);
    // }
  }, [
    hotelId,
    companyId,
    currency,
    // packageDate,
    setPackageInventory,
    setConvertedPackageInventory,
    setpackageImageEndPoint,
    // setTaxRules,
  ]);


  const [localeDetails, setLocaleDetails] = useState();
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      getLocaleDetails(hotelId, setLocaleDetails);
    }
  }, [hotelId, apiKey]);

  //Check availability and set loder while making api call
  useEffect(() => {
    if (props.checkAvail) {
      setPackageInventory([]);
      setConvertedPackageInventory([]);
      setpackageImageEndPoint([]);
    }
    props.callBackToSetCheckAvail(false);
  }, [
    props.checkAvail,
    setConvertedPackageInventory,
    setpackageImageEndPoint,
    setPackageInventory,
    props.callBackToSetCheckAvail,
  ]);
  //After CheckAvaibality click API CALL
  const checkAvail = props.checkAvail;
  useEffect(() => {
    if (hotelId != 0 && currency != "" && checkAvail) {
      setLoading(true);
      fetchPackages(
        hotelId,
        packageDate,
        currency,
        setPackageInventory,
        setConvertedPackageInventory,
        setpackageImageEndPoint,
        setLoading,
        setOpenDropdown
      );
    }
  }, [
    hotelId,
    // packageDate,
    currency,
    setPackageInventory,
    setConvertedPackageInventory,
    setpackageImageEndPoint,
    checkAvail,
  ]);
  //Initial cart
  useEffect(() => {
    if (convertedPackageInventory.length > 0) {
      initialCart();
      callBackParent(0, 0, "", "", "");
      //setLoading(false)
    }
  }, [packageInventory, convertedPackageInventory]);
  // useEffect(()=>{
  //     if(packageInventory.length === 0 && hotelList.length > 0){
  //         if(hotelCount < hotelList.length){
  //             setHotelId(hotelList[hotelCount].hotel_id)
  //             setHotelCount(hotelCount+1);
  //         }
  //     }
  // },[packageInventory,hotelList])
  //*********************************** */
  //Initial Cart Items
  const initialCart = () => {
    let packageCartItem = {};
    setCart(packageCartItem);
    convertedPackageInventory &&
      convertedPackageInventory.length > 0 &&
      convertedPackageInventory.map((packageData) => {
        let cartItem = prepareCart(packageData.package_id);
        if (cartItem.packages.length > 0) {
          cartItem.packages[0]["bar_price"] = getPackagePrice(
            packageData.package_id
          );
          cartItem.packages[0]["bar_price"] = parseFloat(
            cartItem.packages[0]["bar_price"]
          );
          cartItem.packages[0].packages = cartItem.packages[0].packages + 1; //Concatinating number 1 to Package
          cartItem.packages[0].package_date_from = moment(
            packageData.date_from
          ).format("DD-MM-YYYY");
          cartItem.packages[0].package_date_to = moment(
            packageData.date_to
          ).format("DD-MM-YYYY");

          Object.assign(cart, {
            [packageData.package_id.toString()]: cartItem,
          }); //Set new room type into cart
        }
      });
    Object.assign(packageCartItem, cart); //Assign it new object to re render
    setCart(packageCartItem);
    updateCart();
  };
  //When Item is removed from Cart
  useEffect(() => {
    if (
      props.removedCartItem &&
      Object.keys(props.removedCartItem).length > 0
    ) {
      removePackage(props.removedCartItem.package_id, "removeCartItem", 0);
      //Scroll to id
      let id = props.removedCartItem.package_id.toString();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [props.removedCartItem]);
  //Intially Modal is Open false
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Modal Show or Hide
  const toggleModal = (package_id) => {
    setPackageDetails([]);
    if (package_id != 0) {
      getPackageDetails(package_id);
      setIsModalOpen(!isModalOpen);
    }
  };
  //API Call to get the room details and room imgaes
  const [packageDetails, setPackageDetails] = useState([]);
  const getPackageDetails = (package_id) => {
    fetchPackageDetails(package_id, setPackageDetails);
  };
  //Set Image Slider is open Or Not
  //Intially Image Slider is Open false
  const [isSliderOpen, setSliderOpen] = useState(false);

  //Show Image slider
  const toggleSlider = (package_id) => {
    setSliderOpen(!isSliderOpen);
    setPackageDetails([]);
    if (package_id != 0) {
      getPackageDetails(package_id);
    }
  };
  const noOfNights = () => {
    let date1 = new Date(dateRange.startDate);
    let date2 = new Date(dateRange.endDate);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };
  let no_nights = noOfNights();
  //Cart Settings
  // const [openDropdown, setOpenDropdown] = useState({});
  // const [addBtnStatus, setAddBtnStatus] = useState([]);
  // const [cart, setCart] = useState({});

  //Toggle Dropdown
  const toggleDropdown = (package_id, los) => {
    //--------------------select box to select multiple packages---------------------//
    if (los) {
      let default_selected_package = [];
      if (convertedPackageInventory && convertedPackageInventory.length > 0) {
        convertedPackageInventory.map((items, index) => {
          default_selected_package.push(1);
        });
      }
      setSelectedNoOfPackage(default_selected_package);
      // let length_packages =
      //   cart[package_id.toString()] &&
      //   cart[package_id.toString()].packages.length;
      // for (let i = length_packages; i > 1; i--) {
      //   let number_packages =
      //     cart[package_id.toString()] &&
      //     cart[package_id.toString()].packages.length;
      //   if (number_packages > 1) {
      //     cart[package_id.toString()] &&
      //       cart[package_id.toString()].packages.splice(
      //         number_packages - 1,
      //         1
      //       );
      //   }
      //   updateCart();
      //   setCart(cart);
      // }
    }
    //------------------end of select box to select multiple packages-----------------------//
    let dropDowns = {};
    let current_key = package_id.toString();
    packageInventory.map((packageData) => {
      let key = packageData.package_id.toString();
      let ob = { [key]: current_key == key ? !openDropdown[key] : false };
      Object.assign(dropDowns, ob);
    });
    setOpenDropdown(dropDowns);
  };
  //Toggle package add btn disable status
  const toggleAddRoomStatus = (package_id) => {
    let addRoomBtns = {};
    let current_key = package_id.toString();
    packageInventory.map((packageData) => {
      let key = packageData.package_id.toString();
      let ob = { [key]: current_key == key ? !addBtnStatus[key] : false };
      Object.assign(addRoomBtns, ob);
    });
    setAddBtnStatus(addRoomBtns);
  };
  //Prepare cart to add
  const prepareCart = (package_id) => {
    let cartItem = {
      package_type: "",
      package_id: 0,
      price: 0,
      packages: [],
      tax: [],
      discounted_price: 0,
    }; //Initialises  every time to avoid the object overwrite issue
    packageInventory &&
      packageInventory.map((packageData) => {
        if (package_id == packageData.package_id) {
          cartItem["package_type"] = packageData.package_name;
          cartItem["package_id"] = package_id;
          cartItem["room_type_id"] = packageData.room_type_id;
          cartItem["nights"] = packageData.nights;
          cartItem["price"] = 0;
          cartItem["add_room"] = true;
          let total_adults =
            parseInt(packageData.adults) + parseInt(packageData.extra_person);
          let total_child =
            parseInt(packageData.max_child) + parseInt(packageData.extra_child);

          // let total_infant = parseInt(packageData.max_infant);

          let count = [];
          let child_count = [];

          // let infant_count = [];

          for (let i = 1; i <= total_adults; i++) {
            let countdata = { option: i, value: i };
            count.push(countdata);
          }
          for (let j = 1; j <= total_child; j++) {
            let countdata = { option: j, value: j };
            child_count.push(countdata);
          }

          // for (let k = 0; k <= total_infant; k++) {
          //   let countdata = { option: k, value: k };
          //   infant_count.push(countdata);
          // }


          cartItem["packages"][0] = {
            packages: "Package",
            adults: count,
            selected_adult: parseInt(packageData.adults),
            childs: child_count,
            // infants: infant_count,
            selected_child: parseInt(packageData.max_child),
            // selected_infant: total_infant,
            selected_infant: 0,
            extra_adult_price: 0,
            extra_child_price: 0,
            bar_price: parseFloat(packageData.discounted_amount),
          };

          // added for custom tax percentage
          cartItem["tax_type"] = packageData.tax_type;
          cartItem["tax_percentage"] = packageData.tax_percentage;
          // added for custom tax percentage
        }
      });
    return cartItem;
  };
  //Get Package Price
  const getPackagePrice = (package_id) => {
    let curPrice = 0;
    packageInventory &&
      packageInventory.map((packageData) => {
        if (package_id == packageData.package_id) {
          curPrice = parseFloat(packageData.discounted_amount);
        }
      });
    return curPrice;
  };

  //get Package status
  const getPackageStatus = (packageId) => {
    const selected_date = moment(packageDate).format("YYYY-MM-DD");
    const curPackage =
      convertedPackageInventory[
      convertedPackageInventory.findIndex((p) => p.package_id === packageId)
      ];
    // if (curPackage.inv.find(i => i.date == selected_date).block_status == 1)
    //   return true;
    // else return false;
    return true;
  };

  //Add packages to cart
  const [showNightsMessage, setShowNightsMessage] = useState(false);
  const addPackage = (package_id, minimum_inventory) => {
    //Check for the rooms count not more than minimum inventory
    if (
      cart[package_id] &&
      cart[package_id].packages.length >= minimum_inventory
    ) {
      toggleAddRoomStatus(package_id);
      return false;
    }
    let cartItem = prepareCart(package_id);
    let roomCartItem = {};
    if (cartItem) {
      let noCart = true;
      if (
        cart[package_id.toString()] &&
        cart[package_id.toString()].package_id == package_id
      ) {
        cartItem.packages[0]["bar_price"] = getPackagePrice(package_id);
        cartItem.packages[0]["bar_price"] = parseFloat(
          cartItem.packages[0]["bar_price"]
        );
        let len = cart[package_id.toString()].packages.length + 1;
        cartItem.packages[0].packages = cartItem.packages[0].packages + len;
        cart[package_id.toString()].packages.push(cartItem.packages[0]);
        noCart = false;
        Object.assign(roomCartItem, cart); //Assign it new object to re render*/
      }
      if (noCart) {
        cartItem.packages[0]["bar_price"] = getPackagePrice(package_id);
        cartItem.packages[0]["bar_price"] = parseFloat(
          cartItem.packages[0]["bar_price"]
        );
        cartItem.packages[0].packages = cartItem.packages[0].packages + 1; //Concatinating number 1 to room
        Object.assign(cart, { [package_id.toString()]: cartItem }); //Set new room type into cart
        Object.assign(roomCartItem, cart); //Assign it new object to re render
      }
    }
    setCart(roomCartItem);
    updateCart();
  };
  //--------------------add multiple number of package from select box--------------------------//
  const addTotalPackage = (e, index, package_id, minimum_inventory) => {
    let length_package =
      cart[package_id.toString()] &&
      cart[package_id.toString()].packages.length;
    for (let i = length_package; i > 1; i--) {
      let total_number_packages =
        cart[package_id.toString()] &&
        cart[package_id.toString()].packages.length;
      if (total_number_packages > 1) {
        cart[package_id.toString()] &&
          cart[package_id.toString()].packages.splice(
            total_number_packages - 1,
            1
          );
      }
      updateCart();
      setCart(cart);
    }

    let total_package = parseInt(e);
    selectedNoOfPackage[index] = parseInt(e);
    setSelectedNoOfPackage([...selectedNoOfPackage]);

    for (let i = 1; i < total_package; i++) {
      if (
        cart[package_id] &&
        cart[package_id].packages.length >= minimum_inventory
      ) {
        toggleAddRoomStatus(package_id);
        return false;
      }
      let cartItem = prepareCart(package_id);
      let roomCartItem = {};
      if (cartItem) {
        let noCart = true;
        if (
          cart[package_id.toString()] &&
          cart[package_id.toString()].package_id == package_id
        ) {
          cartItem.packages[0]["bar_price"] = getPackagePrice(package_id);
          cartItem.packages[0]["bar_price"] = parseFloat(
            cartItem.packages[0]["bar_price"]
          );
          let len = cart[package_id.toString()].packages.length + 1;

          cartItem.packages[0].packages = cartItem.packages[0].packages + len;
          cart[package_id.toString()].packages.push(cartItem.packages[0]);
          noCart = false;
          Object.assign(roomCartItem, cart);
        }
        if (noCart) {
          cartItem.packages[0]["bar_price"] = getPackagePrice(package_id);
          cartItem.packages[0]["bar_price"] = parseFloat(
            cartItem.packages[0]["bar_price"]
          );
          cartItem.packages[0].packages = cartItem.packages[0].packages + 1;
          Object.assign(cart, { [package_id.toString()]: cartItem });
          Object.assign(roomCartItem, cart);
        }
      }
      setCart(roomCartItem);
      updateCart();
    }
  };
  //-----------------end of add multiple package  from select box--------------------- //
  //remove packages from cart
  const removePackage = (package_id, type, minimum_inventory, index) => {
    //---------------------select box to select multiple packages-----------------//
    if (index) {
      selectedNoOfPackage[index]--;
      setSelectedNoOfPackage([...selectedNoOfPackage]);
    }
    //---------------select box to select multiple packages-----------------------//
    //Check for the rooms count not more than minimum inventory
    if (
      minimum_inventory != 0 &&
      cart[package_id] &&
      cart[package_id].packages.length === minimum_inventory
    ) {
      toggleAddRoomStatus(package_id);
      return false;
    }
    let newCart = {};
    let no_of_rooms =
      cart[package_id.toString()] &&
      cart[package_id.toString()].packages.length;
    if (type != "removeCartItem" && no_of_rooms > 1) {
      cart[package_id.toString()] &&
        cart[package_id.toString()].packages.map((pack) => {
          cart[package_id.toString()].packages.splice(no_of_rooms - 1, 1);
          return;
        });
    }
    if (type != "removeCartItem" && no_of_rooms == 1) {
      delete cart[package_id.toString()];
    }
    if (type == "removeCartItem") {
      callBackParent(package_id, "enable", "remove", "");
    } else {
      Object.assign(newCart, cart); //TO re render the component
      setCart(newCart);
      updateCart(); //Update the cart
    }
  };
  //Update Cart
  //Update Total
  const updateCart = () => {
    let updatedCart = {};
    //Calculate the cart Total price
    if (Object.values(cart).length > 0) {
      const cartItems = Object.entries(cart);
      cartItems.map((cartItem) => {
        cartItem[1].price = 0;
        let package_price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem[1].packages.map((pack) => {
          extra_adult_price += pack.extra_adult_price;
          extra_child_price += pack.extra_child_price;
          cart[cartItem[0]].price += pack.bar_price;
          package_price += parseFloat(pack.bar_price);
        });

        //Updat the GSt Price into cart
        updateGstPrice(
          cartItem[1].nights,
          cartItem[1].packages.length,
          cartItem[1].package_id,
          package_price +
          extra_adult_price +
          extra_child_price -
          cartItem[1].discounted_price,
          package_price - cartItem[1].discounted_price,
          cartItem[1].tax_type,
          cartItem[1].tax_percentage
        );
        cartItem[1].display_price =
          cart[cartItem[0]].price + extra_adult_price + extra_child_price;
      });
      Object.assign(updatedCart, cart);
    }
    setCart(updatedCart); //Setting the updated cart to cart
  };
  //-----------for the check in date field and date checkIn date validation---------------//
  const [checkInErr, setcheckInErr] = useState(false);

  const updatePackageCheckinDates = (date, package_id) => {
    var package_start_date = cart[package_id].packages[0].package_date_from;
    var package_end_date = cart[package_id].packages[0].package_date_to;
    var selected_check_in_date = moment(date).format("DD-MM-YYYY");
    //----- date difference--------
    var date1 = selected_check_in_date;
    date1 = new Date(date1.split('-')[2], date1.split('-')[1] - 1, date1.split('-')[0]);
    var date2 = package_end_date;
    date2 = new Date(date2.split('-')[2], date2.split('-')[1] - 1, date2.split('-')[0]);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var date_diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    //------end of date difference-----

    var checkIn_date = selected_check_in_date;
    checkIn_date = checkIn_date.split("-").reverse().join("-");

    var packStart_date = package_start_date;
    packStart_date = packStart_date.split("-").reverse().join("-");

    var packEnd_date = package_end_date;
    packEnd_date = packEnd_date.split("-").reverse().join("-");

    if (date_diff < cart[package_id].nights) {
      setcheckInErr(true);
      setTimeout(() => {
        setcheckInErr(false);
      }, 3000);
      return false;
    }
    else if (checkIn_date < packStart_date) {
      setcheckInErr(true);
      setTimeout(() => {
        setcheckInErr(false);
      }, 3000);
      return false;
    }
    else if (checkIn_date > packEnd_date) {
      setcheckInErr(true);
      setTimeout(() => {
        setcheckInErr(false);
      }, 3000);
      return false;
    }
    else {
      setcheckInErr(false);
      setPackageDate(date);
    }

  };
  // ------------end of for the check in date field and date checkIn date validation--------------//

  //Call back to Parent component Package Panel to update cart Items in cart page
  const callBackParent = (package_id, statustype, type, nights) => {
    //-----------------check-in date field validation-------------//
    if (cart[package_id]) {
      var startDate = cart[package_id].packages[0].package_date_from;
      var packgStart_date = startDate;
      packgStart_date = packgStart_date.split("-").reverse().join("-");
    }
    var current_date = moment(packageDate).format("YYYY-MM-DD");

    if (current_date < packgStart_date) {
      setcheckInErr(true);
      setTimeout(() => {
        setcheckInErr(false);
      }, 3000);
      return false;
    }
    //-----------------end of check-in date field validation-------------//
    if (nights != "") {
      let startDate = dateRange.startDate;
      // console.log('Start date:' + new Date(startDate));
      let endDate = moment(dateRange.startDate).add(nights, "days");
      // console.log('End date:' + new Date(endDate));
      setDateRange({ startDate: startDate, endDate: endDate });
      setTempDateRange({ startDate: startDate, endDate: endDate });
    }
    let newCart = {};
    //Disable all other Room When one room type is selected
    disabledStatus(package_id, statustype);
    if (cart[package_id] && type == "add") {
      if (cart[package_id]) {
        cart[package_id].added_to_cart = true;
        cart[package_id].checkin_date = moment(packageDate).format(
          "DD-MM-YYYY"
        );
        cart[package_id].checkout_date = moment(packageDate)
          .add(cart[package_id].nights, "days")
          .format("DD-MM-YYYY");
        // console.log('Package Nights: ' + cart[package_id].nights);
      }
    } else {
      if (cart[package_id]) cart[package_id].added_to_cart = false;
    }
    Object.assign(newCart, cart);
    setCart(newCart);
    const cartItems = Object.values(cart);
    let addedTCartItems = [];
    if (cartItems.length > 0) {
      cartItems.map((pack) => {
        if (pack.added_to_cart) {
          addedTCartItems.push(pack);
        }
      });
    }
    toggleDropdown(package_id);
    if (package_id === 0) {
      //Cart Reset
      props.addToCartCallBack([], [], []);
    } else {
      props.addToCartCallBack(addedTCartItems, []);
    }


    //--------------------disable multiple package booking------------------//
    if (addedTCartItems.length === 1) {
      setdisableAddPackageBtn(true)
    } else {
      setdisableAddPackageBtn(false)
    }
    //-------------------end of disable multiple package booking------------------//
  };
  //Update Disable status
  const disabledStatus = (package_id, type) => {
    let newCart = {};
    let keys = Object.keys(cart);
    let cartItemsTodisable = keys.filter((k) =>
      k.includes(package_id.toString())
    );
    cartItemsTodisable &&
      cartItemsTodisable.map((key) => {
        if (type == "disable") {
          cart[key].add_room = false;
        }
        if (type == "enable") {
          cart[key].add_room = true;
        }
      });
    Object.assign(newCart, cart);
    setCart(cart);
  };
  //Handle Input changes
  //Set Extra adult message
  const [extraAdultMessage, setExtraAdultmessage] = useState("");
  const [modalTerms, setModalTerms] = useState(false);
  //Handles the adults selected
  const handleAdultChange = (e, package_id, packNo) => {
    let newCart = {};
    let invData = getAllInv(package_id);
    let selected_adults = parseInt(e.target.value);
    let update_price = 0;
    let extra_adult_price = 0;
    let no_of_extra_adults = 0;
    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      package_id: package_id,
      max_adult: cart[package_id.toString()].max_people,
      max_child: cart[package_id.toString()].max_child,
      selected_person: selected_adults,
      room: packNo,
      type: "adult",
    };
    //Check For maximum room capacity
    checkMaxRoomCapacity(checkMaxRoomCapacityData);

    if (selected_adults < invData["max_people"]) {
      extra_adult_price = 0;
      update_price = invData["bar_price"];
    }
    if (selected_adults > invData["max_people"]) {
      extra_adult_price = 0;
      update_price = getPackagePrice(package_id);
      no_of_extra_adults = selected_adults - invData["max_people"];
      extra_adult_price = no_of_extra_adults * invData["extra_adult_price"];
      if (invData["extra_adult_price"] > 0) {
        setExtraAdultmessage(
          baseCurrency !== currency
            ? invData["display_extra_adult_price"]
            : (invData["extra_adult_price"]).toLocaleString('en-IN')
        );
        setModalTerms(true);
      }
    }
    if (selected_adults === invData["max_people"]) {
      extra_adult_price = 0;
      update_price = getPackagePrice(package_id);
    }
    Object.assign(newCart, cart);
    setCart(newCart);
    updateExtraAdultPrice(package_id, extra_adult_price, packNo);
    updateCart();
  };
  //Set Extra adult message
  const [extraChildMessage, setExtraChildmessage] = useState("");
  const [modalChildTerms, setModalChildTerms] = useState(false);
  //Handles the children selected
  const handleChildChange = (e, package_id, packNo) => {
    let newCart = {};
    let selected_child = parseInt(e.target.value); //Selected child
    let invData = getAllInv(package_id);
    let extra_child_price = 0;
    let no_of_extra_child = 0;

    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      package_id: package_id,
      max_adult: cart[package_id.toString()].max_people,
      max_child: cart[package_id.toString()].max_child,
      room: packNo,
      selected_person: selected_child,
      type: "child",
    };
    checkMaxRoomCapacity(checkMaxRoomCapacityData);

    if (selected_child < invData["max_child"]) {
      extra_child_price = 0;
    }
    if (selected_child > invData["max_child"]) {
      no_of_extra_child = selected_child - invData["max_child"];
      extra_child_price = no_of_extra_child * invData["extra_child_price"];
      if (invData["extra_child_price"] > 0) {
        setExtraChildmessage(
          baseCurrency !== currency
            ? invData["display_extra_child_price"]
            : (invData["extra_child_price"]).toLocaleString('en-IN')
        );
        setModalChildTerms(true);
      }
    }
    if (selected_child === invData["max_child"]) {
      extra_child_price = 0;
    }
    Object.assign(newCart, cart);
    setCart(newCart);
    updateExtraChildPrice(package_id, extra_child_price, packNo);
    updateCart();
  };
  //Handle Infant Change
  const handleInfantChange = (e, package_id, packNo) => {
    let newCart = {};
    let selected_infant = parseInt(e.target.value); //Selected child
    cart[package_id.toString()].packages &&
      cart[package_id.toString()].packages.map((packObj) => {
        if (packObj.packageNo == packNo) {
          packObj.selected_infant = selected_infant;
        }
      });
    Object.assign(newCart, cart);
    setCart(newCart);
    updateCart();
  };
  //Update Extra Adult price
  const updateExtraAdultPrice = (package_id, extra_adult_price, packNo) => {
    let updatedCart = {};
    cart[package_id.toString()].packages &&
      cart[package_id.toString()].packages.map((packObj) => {
        if (packObj.packages == packNo) {
          packObj.extra_adult_price = extra_adult_price;
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  //Update Extra Child price
  const updateExtraChildPrice = (package_id, extra_child_price, packNo) => {
    let updatedCart = {};
    cart[package_id.toString()].packages &&
      cart[package_id.toString()].packages.map((packObj) => {
        if (packObj.packages == packNo) {
          packObj.extra_child_price = extra_child_price;
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  const [modalMaxRoomCapacityTerms, setModalMaxRoomCapacityTerms] = useState(
    false
  );
  //Check Max Capacity
  const checkMaxRoomCapacity = (roomCapacityCheckObj) => {
    let updatedCart = {};
    let package_id = roomCapacityCheckObj.package_id;
    let max_capacity_status = 0;
    let max_adult = roomCapacityCheckObj.max_adult;
    let max_child = roomCapacityCheckObj.max_child;
    let selected_person = roomCapacityCheckObj.selected_person;
    let packageName = roomCapacityCheckObj.room;
    let type = roomCapacityCheckObj.type;
    cart[package_id.toString()].packages &&
      cart[package_id.toString()].packages.map((packObj) => {
        if (packObj.packages == packageName) {
          if (max_capacity_status === 0) {
            if (type === "adult") packObj.selected_adult = selected_person;
            if (type === "child") packObj.selected_child = selected_person;
          }
          if (max_capacity_status === 1) {
            if (
              type === "adult" &&
              packObj.selected_adult + packObj.selected_child <=
              max_adult + max_child
            ) {
              packObj.selected_adult = selected_person;
            }
            if (
              type === "child" &&
              packObj.selected_adult + packObj.selected_child <=
              max_adult + max_child
            ) {
              packObj.selected_child = selected_person;
            }
            if (
              type === "adult" &&
              packObj.selected_adult + packObj.selected_child >
              max_adult + max_child
            ) {
              packObj.selected_adult = selected_person;
              packObj.selected_child =
                max_adult + max_child - packObj.selected_adult;
            }
            if (
              type === "child" &&
              packObj.selected_adult + packObj.selected_child >
              max_adult + max_child
            ) {
              packObj.selected_child = selected_person;
              packObj.selected_adult =
                max_adult + max_child - packObj.selected_child;
            }
          }
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  const [currencyValue, setCurrencyValue] = useState(1);
  useEffect(() => {
    if (baseCurrency !== null && baseCurrency !== "" && currency !== null) {
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency, setCurrencyValue]);
  const getAllInv = (package_id) => {
    var invDetail = {};
    invDetail["bar_price"] = 0;
    invDetail["extra_adult_price"] = 0;
    invDetail["extra_child_price"] = 0;
    invDetail["multiple_occupancy"] = [];
    packageInventory &&
      packageInventory.map((packageData) => {
        if (package_id == packageData.package_id) {
          invDetail.package_type = packageData.package_name;
          invDetail.max_people = packageData.adults;
          invDetail.max_child = packageData.max_child;
          invDetail.extra_person = packageData.extra_person;
          invDetail.extra_child = packageData.extra_child;
          invDetail.bar_price = packageData.discounted_amount;
          invDetail.extra_adult_price = packageData.extra_person_price;
          invDetail.extra_child_price = packageData.extra_child_price;
          invDetail.display_extra_adult_price = (
            packageData.extra_person_price * currencyValue
          ).toFixed(2);
          invDetail.display_extra_adult_price = (
            packageData.extra_person_price * currencyValue
          ).toFixed(2);
          invDetail.display_extra_child_price = (
            packageData.extra_child_price * currencyValue
          ).toFixed(2);
        }
      });
    return invDetail;
  };
  //Tax calculation rules
  const updateGstPrice = (
    no_of_nights,
    no_of_rooms,
    package_id,
    package_price,
    price_for_individual,
    tax_type_value,
    tax_percentage_value
  ) => {
    if (cart[package_id.toString()]) {
      cart[package_id.toString()].tax.length = 0;
      cart[package_id.toString()].tax.push({
        // gst_price:
        //   taxRules.tax_type == "GST"
        //     ? getGstPrice(no_of_nights, no_of_rooms, package_price)
        //     : 0,
        // other_tax:
        //   taxRules.tax_type == "NonGST"
        //     ? getOtherPrice(no_of_rooms, package_id, package_price)
        //     : [],

        gst_price: getGstPrice(no_of_nights, no_of_rooms, package_price, price_for_individual, tax_type_value, tax_percentage_value),
        other_tax: [],

      });
    }
  };
  //Get GST TAX price
  const getGstPrice = (no_of_nights, no_of_rooms, price, price_for_individual, tax_type_value, tax_percentage_value) => {


    let gstPrice = 0;
    let gstPercent;

    if (localeDetails && localeDetails.gst_slab_id === 1) {
      let per_night_price = price / no_of_nights / no_of_rooms;

      // added for custom tax percentage
      if (tax_type_value == 'custom') {
        gstPercent = tax_percentage_value
      }
      else {
        gstPercent = checkGSTPercent(per_night_price);
      }
      // added for custom tax percentage

      // gstPercent = checkGSTPercent(per_night_price);
    }

    if (localeDetails && localeDetails.gst_slab_id === 2) {
      let per_night_price = price_for_individual / no_of_nights / no_of_rooms;


      // added for custom tax percentage
      if (tax_type_value == 'custom') {
        gstPercent = tax_percentage_value
      }
      else {
        gstPercent = checkGSTPercent(per_night_price);
      }
      // added for custom tax percentage

      // gstPercent = checkGSTPercent(per_night_price);
    }





    // let chek_price = price / no_of_rooms;
    // let per_night_price = price / no_of_nights / no_of_rooms;

    // let gstPercent = checkGSTPercent(per_night_price);








    //----------------- gst and non gst calculation--------------//
    // let gstPrice
    if (isTaxable == 1) { gstPrice = (price * gstPercent) / 100; }
    if (isTaxable == 0) { gstPrice = 0; }
    //-----------------end of gst and non gst calculation--------------//
    return gstPrice;
  };
  //Get other tax rules and Tax price
  // const getOtherPrice = (no_of_rooms, package_id, price) => {
  //   let taxes = [];
  //   if (taxRules.tax_applicable) {
  //     for (let i = 0; i < taxRules.tax_rules.length; i++) {
  //       let tax_price = (price * taxRules.tax_rules[i]["tax_percent"]) / 100;
  //       let tax_object = {
  //         tax_name: taxRules.tax_rules[i]["tax_name"],
  //         tax_price: tax_price,
  //       };
  //       taxes.push(tax_object);
  //     }
  //   }
  //   return taxes;
  // };
  //Check the GST Slabs
  const checkGSTPercent = (price) => {

    let tax_percent = 0

    if (localeDetails && localeDetails.tax_range_type === 'range') {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range) && price <= parseFloat(items.end_range)) {
            tax_percent = parseFloat(items.value);
          }
        })
      }
    }


    if (localeDetails && localeDetails.tax_range_type === "single") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range)) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }



    return tax_percent;





    // if (price <= 1000) {
    //   return 0;
    // } else if (price > 1000 && price <= 7500) {
    //   return 12;
    // } else if (price > 7500) {
    //   return 18;
    // }
  };
  //----------------------package images listing--------------------//
  const changePackageImage = (img_name, idx) => {
    packageImageEndPoint[idx] = img_name;
    setpackageImageEndPoint([...packageImageEndPoint]);
  }
  //---------------------end of package images listing-----------------//
  // ---------------package description display in modal--------------//
  const htmlToReact = (template) => {
    const finalHtml = template.replace(/<p[^>]*>/g, "");
    return parse(finalHtml);
  };
  // -----------end of package description display in modal---------//


  const [themeColor, setThemeColor] = useState();

  useEffect(() => {
    setThemeColor(JSON.parse(sessionStorage.getItem('theme_color')));
  }, [])


  return (
    <div className="room-box-left">
      <span>
        <Element name="scroll" className="element" />
      </span>
      {loading && (
        <div className="box-shadow-wrapper">
          <RoomPreviewLoader />
        </div>
      )}
      {!loading &&
        // convertedPackageInventory.length > 0 &&
        convertedPackageInventory &&
        convertedPackageInventory.length > 0 &&
        convertedPackageInventory.map((packageData, index) => {
          let packageAvailable = true;
          for (let j = 0; j < packageData.nights; j++) {
            let isBlocked = false;
            //---------date validation for packages listing-----------//
            if (moment(packageData.date_from) <= moment(packageDate)) {
              let pac = packageData.inv.find(
                (i) =>
                  i.date ==
                  moment(packageDate)
                    .add(j, "days")
                    .format("YYYY-MM-DD")
              );
              if ((pac && pac.block_status == 1) || (pac && pac.no_of_rooms == 0)) isBlocked = true;
            }
            if (moment(packageData.date_from) > moment(packageDate)) { isBlocked = true };
            //---------end of date validation for packages listing-----------//
            // isBlocked =
            //   packageData.inv.find(
            //     (i) =>
            //       i.date ==
            //       moment(packageDate)
            //         .add(j, "days")
            //         .format("YYYY-MM-DD")
            //   ).block_status == 1
            if (isBlocked) packageAvailable = false;
          }


          // added for blackout dates
          let selected_date = moment(packageDate).format("YYYY-MM-DD");
          let check_for_dates = moment(selected_date);

          for (let k = 0; k < packageData.nights; k++) {
            let duration_date = moment(check_for_dates).format("DD-MM-YYYY");

            if (packageData.blackout_dates && packageData.blackout_dates.length > 0 && packageData.blackout_dates.includes(duration_date)) {
              packageAvailable = false;
            }


            if (moment(check_for_dates) > moment(packageData.date_to)) {
              packageAvailable = false;
            }

            check_for_dates = moment(check_for_dates).add(1, "days");
          }
          // added for blackout dates





          // add format("YYYY-MM-DD")
          // console.log(
          //   "Package date: ",
          //   moment(packageDate).format("YYYY-MM-DD")
          // );
          // console.log("Data: ", packageData.nights);
          return (
            <div key={index}>
              <div
                key={index}
                id={packageData.package_id.toString()}
                className="box-shadow-wrapper"
              >
                <div className="row">
                  <div className="col s12 m4">
                    <div
                      className={"image-wrap " + packageData.package_id}
                      onClick={() => {
                        // setCurImageUrl(packageData.image_name);
                        // setPackageImagesOpen(true);
                        toggleModal(packageData.package_id)
                      }}
                    >
                      {/*--------------------- package image display----------------------- */}
                      {packageImageEndPoint && packageImageEndPoint.length > 0 && <img src={config.public_image_url + packageImageEndPoint[index].replace(' ', '+')} alt="package image preview" />}
                      {/*--------------------- end of package image display----------------------- */}

                    </div>
                  </div>
                  {/*--------------------------------- package images slider--------------------------- */}
                  {isSliderOpen && (
                    <div className="modal-wrapper">
                      <div
                        id="model1"
                        className="modal scroll z-value"
                        style={{
                          backgroundColor: "transparent !important",
                        }}
                      >
                        <span
                          onClick={() => toggleSlider(0)}
                          style={{
                            zIndex: "3400",
                          }}
                          className="modalBtnClose"
                        />
                        <div
                          className="card card-padding"
                          style={{
                            marginTop: "0rem",
                            marginBottom: "0rem",
                          }}
                        >
                          {packageDetails &&
                            packageDetails.package_image &&
                            packageDetails.package_image.length > 0 && (
                              <div className="imgSlider">
                                <ImageSlider images={packageDetails.package_image} />
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className="modal-overlay"
                        style={{
                          zIndex: "1201",
                          display: "block",
                          opacity: "0.2",
                        }}
                      />
                    </div>
                  )}
                  {/*---------------------------------end of package images slider------------------------ */}
                  {/*--------------------------------modal to display package image & description--------- */}

                  {isModalOpen && (
                    <div className="modal-wrapper">
                      <div id="model1" className="modal scroll z-value room-details-modal">
                        <div className="card" style={{ margin: "0rem" }}>
                          <div className="modal-content padding-bottom-0">
                            <span
                              onClick={() => toggleModal()}
                              className="modalBtnClose"
                            />
                            <div className="row margin-bottom-0">
                              <div className="col s12 m6">
                                {packageDetails &&
                                  packageDetails.package_image &&
                                  packageDetails.package_image.length > 0 && (
                                    <div className="package-muliple-images">
                                      <RoomMultipleImagesSlider images={packageDetails.package_image} />
                                    </div>
                                  )}
                              </div>

                              <div className="col s12 m6">
                                <h3 style={{ fontWeight: "bold" }}>
                                  {packageDetails.package_name}
                                </h3>
                                {packageDetails.package_description && (
                                  <div dangerouslySetInnerHTML={{ __html: packageDetails.package_description }}>
                                    {/* {htmlToReact(packageDetails.package_description + "")} */}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal-overlay"
                        style={{
                          zIndex: "1002",
                          display: "block",
                          opacity: "0.2",
                        }}
                      />
                    </div>
                  )}
                  {/*-----------------end of modal to display package image & description--------------------- */}

                  <div className="col s12 m8">
                    <div className="room-preview-details">
                      <div className="col s12 m8 heading-wrap">
                        <h2
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleModal(packageData.package_id)}
                        >
                          {packageData.package_name}
                        </h2>
                        {packageData.adults > 0 && (
                          <p>
                            Max Package Capacity:
                            {packageData.adults + packageData.max_child}{" "}
                            <i className="fa fa-male" />
                            <i className="fa fa-child" />
                          </p>
                        )}
                        <p>
                          Package Duration {" " + packageData.nights + " "}{" "}
                          night(s)
                        </p>
                        <div className="amenities">
                          <ul>
                            <li>
                              <i className="material-icons icon">wifi</i> Free
                              Wi-fi
                            </li>
                            {/*------------------------ display package validity dates-------------------- */}
                            <br />
                            <li>
                              <i className="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                              Valid from {packageData.package_date_from} to {packageData.package_date_to}
                            </li>
                            {/*----------------------- end of display package validity dates------------------ */}
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col s12 m4"
                        style={{
                          backgroundColor: "rgb(245, 245, 245)",
                          padding: "2%",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="room-price">
                          {packageData.discounted_amount > 0 && (
                            <span>
                              <p className="save-price">
                                <span>You Save</span>{" "}
                                <i className={currencySymbol} />{" "}
                                {parseFloat((
                                  packageData.amount -
                                  packageData.discounted_amount
                                ).toFixed(0)).toLocaleString('en-IN')}{" "}
                                <span className="tag">
                                  {(
                                    ((packageData.amount -
                                      packageData.discounted_amount) /
                                      packageData.amount) *
                                    100
                                  ).toFixed(0)}{" "}
                                  %
                                </span>
                              </p>
                              <p className="strike-price">
                                <strike>
                                  {" "}
                                  <i className={currencySymbol} />{" "}
                                  {parseFloat(packageData.amount.toFixed(2)).toLocaleString('en-IN')}
                                </strike>
                              </p>
                            </span>
                          )}
                          <p className="main-price">
                            <span>From</span> <i className={currencySymbol} />{" "}
                            {parseFloat(packageData.discounted_amount.toFixed(2)).toLocaleString('en-IN')}
                          </p>
                          <p className="per-day-price">per packages</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------------package images listing -------------------------*/}
                <div className="row">
                  <div className="col s12 m5">
                    <ul className="room-image-list">
                      {packageData && packageData.package_image.map(function (package_img, i) {
                        if (i < 4) {
                          return (
                            <li key={i} onClick={() => changePackageImage(package_img.image_name, index)}>
                              <img className="room-images" src={config.public_image_url + package_img.image_name.replace(' ', '+')} alt="package image" />
                            </li>
                          )
                        }
                      })}
                      <div className="room-images-view-all viewAllRooms" style={{ backgroundImage: `url(${config.public_image_url + packageData.image_name})` }} onClick={() => { toggleModal(packageData.package_id); }}>
                        <span className="view-all-text">View more</span>
                      </div>
                    </ul>
                  </div>
                </div>
                {/*------------------------- end of package images listing------------------------ */}
                {(packageData.total_rooms > 0 ||
                  !packageData.block ||
                  packageData.min_inv > 0) && (
                    <div className="row">
                      <div className="room-select">
                        <hr />
                        <div className="row room-select-margin">
                          <div className="col s12 m3">
                            <ul>
                              <li>{packageData.package_name}</li>
                            </ul>
                          </div>
                          <div
                            className="col s12 m3"
                            style={{ position: "relative", cursor: "default" }}
                          >
                            <ul>
                              <li
                                className="waves-effect waves-light room-guest-pacakages"
                                style={{ marginLeft: "0" }}
                              >
                                Package# | Guests
                                {cart[packageData.package_id.toString()] && (
                                  <span className="room-guest-details">
                                    {/*Total Rooms Selected*/}
                                    {cart[packageData.package_id.toString()] &&
                                      cart[packageData.package_id.toString()]
                                        .packages.length > 0 &&
                                      cart[packageData.package_id.toString()]
                                        .packages.length + " Package(s)"}

                                    {/*Total Adults Selected*/}
                                    {cart[packageData.package_id.toString()] &&
                                      cart[
                                        packageData.package_id.toString()
                                      ].packages.reduce((totalAdults, pack) => {
                                        return totalAdults + pack.selected_adult;
                                      }, 0) + " Adults,"}

                                    {/*Total Child Selected*/}
                                    {cart[packageData.package_id.toString()] &&
                                      cart[
                                        packageData.package_id.toString()
                                      ].packages.reduce((totalChildren, pack) => {
                                        return (
                                          totalChildren + pack.selected_child
                                        );
                                      }, 0) + " Kids"}
                                  </span>
                                )}
                              </li>
                              {openDropdown[
                                packageData.package_id.toString()
                              ] && (
                                  <div
                                    className="room-guest-select" style={{ left: "6.5%" }}>
                                    {/* ----------validation for check in date------ */}
                                    {checkInErr &&
                                      <div className="col-md-12 row" style={{ marginTop: "10px", marginLeft: "145px" }}>
                                        <span style={{ color: "red" }}>*Package not available on selected date</span>
                                      </div>
                                    }
                                    {/* ----------validation for check in date------ */}

                                    {/*---------------------- select box to select multiple rooms----------------*/}
                                    <div className="col-md-12 row">
                                      <div className="multiple-room-wrap">
                                        <div
                                          className="col s6 m6 hide-on-small-only"
                                          style={{ fontSize: "98%", color: "#b7b0c2" }}
                                        >
                                          <span>No. of Package</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12 row">
                                      {/* ----------select no of packages--------------- */}
                                      <div className="multiple-room-wrap" style={{ marginTop: "1%" }}>
                                        <div className="col s5 m5">
                                          <select
                                            style={{ height: "44px", borderColor: themeColor && themeColor.theme_color, paddingLeft: "45px", fontSize: "medium", fontWeight: "300" }}
                                            // value={selectedNoOfPackage[index]}
                                            value={cart[packageData.package_id.toString()].packages.length}
                                            onChange={(e) => addTotalPackage(e.target.value, index, packageData.package_id, packageData.min_inv)}>
                                            {packageData.display_no_of_packages.map((items, idx) => (
                                              <option value={items} key={idx}> {items} </option>
                                            )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      {/* ----------end of select no of packages--------------- */}

                                      {/* ----------datepicker to choose check in date--------------- */}
                                      <div className="multiple-room-wrap" style={{ marginTop: "1%" }}>
                                        <div className="col s7 m7">
                                          <div className="single-date-picker-wrap" style={{ marginTop: "-21px" }}>
                                            <SingleDatePicker
                                              numberOfMonths={1}
                                              onDateChange={(date) => updatePackageCheckinDates(date, packageData.package_id)}
                                              onFocusChange={({ focused }) => setFocused(focused)}
                                              focused={focused}
                                              date={packageDate}
                                              displayFormat="DD MMM YYYY"
                                              hideKeyboardShortcutsPanel
                                            />
                                          </div>
                                        </div>

                                      </div>
                                      {/* ----------end of datepicker to choose check in date--------------- */}

                                    </div>
                                    {/*----------------- end of select box to select multiple rooms----------------*/}
                                    <div className="row">
                                      <div className="multiple-room-wrap">
                                        <div className="col s12 m2 hide-on-small-only" />
                                        <div className="col s12 m3 hide-on-small-only" style={{ textAlign: "center", fontSize: "80%" }}><span>Adults</span></div>
                                        <div className="col s12 m3 hide-on-small-only" style={{ textAlign: "center", fontSize: "80%" }}><span>(5 - 12 yrs)</span></div>
                                        <div className="col s12 m3 hide-on-small-only" style={{ textAlign: "center", fontSize: "80%" }}><span>(0 &lt; 5 yrs)</span></div>
                                      </div>
                                    </div>

                                    {cart[packageData.package_id.toString()] &&
                                      cart[packageData.package_id.toString()]
                                        .packages &&
                                      cart[
                                        packageData.package_id.toString()
                                      ].packages.map((pack, index) => (
                                        <div key={index} className="row">
                                          <div
                                            className="multiple-room-wrap"
                                            style={{ marginTop: "1%" }}
                                          >
                                            {/* -----------display of count for no of packages------------- */}
                                            <div className="col s12 m2">
                                              <span>{pack.packages}</span>
                                            </div>
                                            {/* -----------end of display of count for no of packages------------- */}

                                            <div className="col s12 m3">
                                              <select style={{ marginLeft: "6px", fontWeight: "300" }} //----- css for the styling of package count display-------//
                                                value={pack.selected_adult}
                                                onChange={(e) =>
                                                  handleAdultChange(
                                                    e,
                                                    packageData.package_id,
                                                    pack.packages
                                                  )
                                                }
                                              >
                                                <option value={0}>Adults</option>
                                                {pack.adults &&
                                                  pack.adults.map(
                                                    (adult, index) => (
                                                      <option
                                                        key={index}
                                                        value={adult.value}
                                                      >
                                                        {adult.option}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                            <div className="col s12 m3">
                                              <select
                                                name="kids"
                                                value={pack.selected_child}
                                                onChange={(e) =>
                                                  handleChildChange(
                                                    e,
                                                    packageData.package_id,
                                                    pack.packages
                                                  )
                                                }
                                              >
                                                <option value={0}>Child</option>
                                                {pack.childs &&
                                                  pack.childs.map(
                                                    (child, index) => (
                                                      <option
                                                        key={index}
                                                        value={child.value}
                                                      >
                                                        {child.option}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                            <div className="col s12 m3">
                                              <select
                                                value={pack.selected_infant}
                                                onChange={(e) =>
                                                  handleInfantChange(
                                                    e,
                                                    packageData.package_id,
                                                    pack.packages
                                                  )
                                                }
                                              >
                                                <option value={0}>Infants</option>
                                                {/* {pack.infants &&
                                                  pack.infants.map(
                                                    (infant, index) => (
                                                      <option
                                                        key={index}
                                                        value={infant.value}
                                                      >
                                                        {infant.option}
                                                      </option>
                                                    )
                                                  )} */}

                                                <option>1 </option>
                                                <option>2 </option>
                                                <option>3</option>
                                              </select>
                                            </div>
                                            {index !== 0 && (
                                              <div
                                                className="col s12 m1 hover"
                                                style={{
                                                  paddingLeft: "0px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <span
                                                  // className=""
                                                  onClick={() =>
                                                    removePackage(
                                                      packageData.package_id,
                                                      "",
                                                      packageData.min_inv,
                                                      index //------for multiple package selection-----------//
                                                    )
                                                  }
                                                  className="material-icons icon deleteIcon"
                                                >
                                                  delete_outline
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    <div className="multiple-room-wrap row">
                                      {/* <div className="col s12 m4">
                                          <ul>
                                            <li>
                                              <a
                                                className="waves-effect waves-light btn-small btn-guest"
                                                style={{ fontSize: 12 }}
                                                disabled={
                                                  addBtnStatus[
                                                    packageData.package_id
                                                  ]
                                                    ? "disabled"
                                                    : ""
                                                }
                                                onClick={() =>
                                                  addPackage(
                                                    packageData.package_id,
                                                    packageData.min_inv
                                                  )
                                                }
                                              >
                                                Add Package
                                          </a>
                                            </li>
                                          </ul>
                                        </div> */}
                                      {/* <div className="col s12 m8"> */}
                                      <div className="col s12 m6" style={{ paddingLeft: "55px" }}>
                                        <li>
                                          <a
                                            className="waves-effect waves-light btn-small btn-guest"
                                            onClick={() =>
                                              toggleDropdown(
                                                packageData.package_id
                                              )
                                            }
                                          >
                                            Cancel
                                          </a>
                                        </li>
                                      </div>
                                      <div className="col s12 m6" style={{ paddingLeft: "60px" }}>
                                        {cart[
                                          packageData.package_id.toString()
                                        ] && (
                                            <ul>
                                              <li>
                                                {/* added for blackout dates */}
                                                {packageAvailable ?
                                                  <a
                                                    className="waves-effect waves-light btn-small btn-guest-confirm"
                                                    onClick={() => {
                                                      callBackParent(
                                                        packageData.package_id,
                                                        "disable",
                                                        "add",
                                                        packageData.nights
                                                      );
                                                    }}
                                                  >
                                                    Confirm
                                                  </a>
                                                  :
                                                  <a className="waves-effect waves-light btn-small not-available-btn nt-available">
                                                    Not Available
                                                  </a>
                                                }
                                                {/* added for blackout dates */}
                                              </li>
                                            </ul>
                                          )}
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                )}
                            </ul>
                          </div>
                          <div className="col s12 m3">
                            <ul>
                              <li>
                                {" "}
                                <i className={currencySymbol} />{" "}
                                {(packageData.discounted_amount).toLocaleString('en-IN')}
                              </li>
                            </ul>
                          </div>
                          <div className="col s12 m3">
                            <ul>
                              {packageData.min_inv == 0 ||

                                // packageData.inv.find(
                                //   (i) =>
                                //     i.date ==
                                //     moment(packageDate).format("YYYY-MM-DD")
                                // ) &&



                                // packageData.inv.find(
                                //   (i) =>
                                //     i.date ==
                                //     moment(packageDate).format("YYYY-MM-DD")
                                // ).block_status == 1

                                !packageAvailable ? (
                                <li>
                                  <a className="waves-effect waves-light btn-small not-available-btn">
                                    Not Available
                                  </a>
                                </li>
                              ) : (
                                <li id="add-room-button">
                                  <a
                                    style={{ width: "auto" }}
                                    disabled={
                                      disableAddPackageBtn ||
                                      cart[packageData.package_id] &&
                                      !cart[packageData.package_id].add_room
                                    }
                                    onClick={() => {
                                      toggleDropdown(packageData.package_id, packageData.inv[0].los);
                                    }}
                                    className="waves-effect waves-light btn-small select-btn"
                                  >
                                    Add Packages
                                  </a>
                                </li>
                              )}
                              {/* {packageData.min_inv==0 && <li><a className="waves-effect waves-light btn-small not-available-btn">Not Available</a></li>} */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div>
                {packageData.total_rooms == 0 ||
                  (packageData.block && (
                    <div className="row">
                      <div className="col s12 m4" />
                      <div className="col s12 m3" />
                      <div className="col s12 m2" />
                      <div className="col s12 m3 not-available">
                        <ul>
                          <li>
                            <a className="waves-effect waves-light btn-small not-available-btn">
                              Not Available
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      {convertedPackageInventory.length === 0 && !loading && (
        <div className="box-shadow-wrapper" style={{ marginTop: "-4.5%" }}>
          <div className="row">
            <div className="no-booking-preview">
              <h3 style={{ paddingTop: 20 }}>Search Results</h3>
              <div className="circle">
                <i className="material-icons">search</i>
              </div>
              <p className="booking-summary-paragraph">
                No packages found in these dates !{" "}
              </p>
              <p>Please try in other dates and good luck! </p>
            </div>
          </div>
        </div>
      )}
      {modalTerms && (
        <div className="modal-wrapper">
          <div id="modal12" className="modal scroll">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <div className="row">
                  <h3 className="center-align">Extra Adult Price</h3>
                  <hr />
                  <div className="coupon-wrapper">
                    <p>
                      Please be aware that the number of adults entered in your
                      reservation has exceeded the number of adults included in
                      the Room price.The price for an additional adult(s) in
                      this room is <i className={currencySymbol} />
                      {extraAdultMessage}.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0" }}>
                <div className="col m5" />
                <div className="col m3">
                  <a
                    style={{ marginBottom: "13%" }}
                    className="waves-effect waves-light btn-small select-terms-btn"
                    onClick={() => setModalTerms(!modalTerms)}
                  >
                    Accept
                  </a>
                </div>
                <div className="col m4" />
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1002", display: "block", opacity: "0.5" }}
          />
        </div>
      )}
      {modalChildTerms && (
        <div className="modal-wrapper">
          <div id="modal12" className="modal scroll">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <div className="row">
                  <h3 className="center-align">Extra Child Price</h3>
                  <hr />
                  <div className="coupon-wrapper">
                    <p>
                      Please be aware that the number of children entered in
                      your reservation has exceeded the number of children
                      included in the Room price.The price for an additional
                      children in this room is <i className={currencySymbol} />
                      {extraChildMessage}.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0" }}>
                <div className="col m5" />
                <div className="col m3">
                  <a
                    style={{ marginBottom: "13%" }}
                    className="waves-effect waves-light btn-small select-terms-btn"
                    onClick={() => setModalChildTerms(!modalChildTerms)}
                  >
                    Accept
                  </a>
                </div>
                <div className="col m4" />
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1002", display: "block", opacity: "0.5" }}
          />
        </div>
      )}
    </div>
  );
};
//Export the Packages
export default Packages;
