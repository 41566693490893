import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import config from '../../../config';

const ImageSlider = props => {
    const imagesToSlider = [];
    props &&
        props.images &&
        props.images.length > 0 &&
        props.images.map(image => {
            if (props.customImages) {
                imagesToSlider.push({
                    url: image
                });
            } else {
                imagesToSlider.push(
                    config.public_image_url + image.image_name.replace(' ', '+')
                );
            }
        });

    return (
        <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay
            showIndicators={false}
            infiniteLoop={true}
            transitionTime={200}
            dynamicHeight={true}>
            {props.images &&
                props.images.length > 0 &&
                props.images.map(function (image, i) {
                    return (
                        <div key={i}>
                            <img
                                src={
                                    !props.customImages
                                        ? config.public_image_url +
                                        image.image_name.replace(' ', '+')
                                        : image
                                }
                            />
                        </div>
                    );
                })}
        </Carousel>
    );
};

export default ImageSlider;
