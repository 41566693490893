import config from "../../../config";
const getCity = (id, setAllCity) => {
    fetch(
        config.apiEndpoint +
        "get-all-city/" + id,
        {
            method: "GET",
            ACCEPT: "application/json",
            Content_type: "application/json",
        }
    )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {

                //changes for sorting alphabetically
                let all_cities = responseJson.city
                all_cities.sort(function (a, b) {
                    var nameA = a.city_name.toLowerCase(), nameB = b.city_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
                //changes for sorting alphabetically


                setAllCity(responseJson.city);
            }
            else {
                setAllCity([]);
            }

        })
        .catch((e) => console.log(e));
};

//Export getCity
export default getCity;