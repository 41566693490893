import config from "../../../config";
const getCurrencyValue = (currency, baseCurrency, setCurrencyValue) => {
  //let url =config.apiEndpoint+ 'package_booking/currency_details/' + currency+'/'+ baseCurrency
  fetch(
    config.apiEndpoint +
      "package_booking/currency_details/" +
      currency +
      "/" +
      baseCurrency,
    {
      method: "GET",
      ACCEPT: "application/json",
      Content_type: "application/json",
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1)
        setCurrencyValue(responseJson.currency_value);
    })
    .catch((e) => console.log(e));
};

//Export getCurrencyValue
export default getCurrencyValue;
