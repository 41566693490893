import React, { useState, useContext, useEffect } from "react";
import { CompanyContext, HotelIdContext, CurrencySymbolContext } from "../../settings/Store";
import cancelledBookingReportData from "./cancelledBookingReportData";
import moment from "moment";

import Lottie from "react-lottie";
import animationData from "./../../json/booking-report.json";
const CancelledBookingReport = () => {
  const [compState] = useContext(CompanyContext);

  const [hotelId] = useContext(HotelIdContext);
  const [cancelBookingReportData, setCancelBookingReportData] = useState([]);
  let current_year = new Date().getFullYear();
  let date_tday = new Date();
  let current_month = ("0" + (date_tday.getMonth() + 1)).slice(-2);
  // useEffect(() => {
  //     if (hotelId != 0) {
  //         cancelledBookingReportData(hotelId, setCancelBookingReportData)
  //     }
  // }, [hotelId, setCancelBookingReportData]);

  const [monthFilter, setMonthFilter] = useState(current_month);
  const [yearFilter, setYearFilter] = useState(current_year);
  const [filteredValue, setFilteredValue] = useState(
    "01-" + current_month + "-" + current_year
  );

  const [lastPage, setLastPage] = useState(0);

  const [page, setPage] = useState(1);
  const [buttonArray, setButtonArray] = useState([1]);
  const [displayLoader, setDisplayLoader] = useState(true);
  //fetch cancelled bookings details
  useEffect(() => {
    setDisplayLoader(true);
    setCancelBookingReportData([]);
    setButtonArray([]);
    if (hotelId != 0) {
      cancelledBookingReportData(
        hotelId,
        filteredValue,
        setCancelBookingReportData,
        setLastPage,
        page,
        setButtonArray,
        setDisplayLoader,
        compState
      );
    }
  }, [hotelId, setCancelBookingReportData, filteredValue, page, compState]);

  const [currencySymbol] = useContext(CurrencySymbolContext);

  const [
    openCancelBookingReportModal,
    setOpenCancelBookingReportModal,
  ] = useState(false);
  const [displayDataIndex, setDisplayDataIndex] = useState(false);

  const displayModal = (index) => {
    setDisplayDataIndex(index);
    setOpenCancelBookingReportModal(true);
  };

  const changeMonth = (e) => {
    setPage(1);
    setMonthFilter(e);
    setFilteredValue("01-" + e + "-" + yearFilter);
  };

  const changeYear = (e) => {
    setPage(1);
    setYearFilter(e);
    setFilteredValue("01-" + monthFilter + "-" + e);
  };

  const previousButton = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
    window.scrollTo(0, 0);
  };

  const nextButton = () => {
    if (page !== lastPage) {
      setPage(page + 1);
    }
    window.scrollTo(0, 0);
  };

  let year_array = [current_year];

  for (let i = 1; i < 15; i++) {
    year_array.unshift(current_year - i);
    year_array.push(current_year + i);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {displayLoader && (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}

      {!displayLoader && (
        <div
          className="row"
          style={{
            border: "1px solid #aaa",
            borderTop: "none",
            paddingTop: "1%",
            paddingBottom: "1%",
          }}
        >
          <div className="row filter-booking-months">
            <div className="col s1 m6" />
            <div className="col s5 m3">
              <select
                value={monthFilter}
                onChange={(e) => changeMonth(e.target.value)}
              >
                <option value={"01"}>January</option>
                <option value={"02"}>February</option>
                <option value={"03"}>March</option>
                <option value={"04"}>April</option>
                <option value={"05"}>May</option>
                <option value={"06"}>June</option>
                <option value={"07"}>July</option>
                <option value={"08"}>August</option>
                <option value={"09"}>September</option>
                <option value={"10"}>October</option>
                <option value={"11"}>November</option>
                <option value={"12"}>December</option>
              </select>
            </div>

            <div className="col s5 m3">
              <select
                value={yearFilter}
                onChange={(e) => changeYear(e.target.value)}
              >
                {year_array.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {cancelBookingReportData &&
            cancelBookingReportData.length > 0 &&
            cancelBookingReportData.map((cancelBookingReportData, index) => {
              return (
                <div className="col s12 m3" key={index}>
                  <div className="booking-summary-box mobile-margin-top manage-booking-card">
                    <span
                      className="booking-status-button cancel-booking-report-button"
                      onClick={() => displayModal(index)}
                      title="More Details"
                    >
                      <i className="fa fa-info" />
                    </span>
                    <div className="room-booking-summary">
                      <h5 className="user-name">
                        {cancelBookingReportData.first_name +
                          " " +
                          cancelBookingReportData.last_name}
                      </h5>
                      <div key={index} className="container booking-summary">
                        <div className="row">
                          <div className="room-summary-box cancel-summary-box">
                            <p>Room Details:</p>
                            <p>{cancelBookingReportData.room_type}</p>
                            <p>
                              Booking Date:{" "}
                              {cancelBookingReportData.booking_date_display}
                            </p>
                            <p>
                              Total Amount: <i className={currencySymbol} />{" "}
                              {(cancelBookingReportData.total_amount).toLocaleString('en-IN')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {cancelBookingReportData && cancelBookingReportData.length === 0 && (
            <div className="col s12 center-align">
              <Lottie options={defaultOptions} height={100} width={100} />
              <span className="no-booking-available">
                No Cancelled Bookings Available !
              </span>
            </div>
          )}
        </div>
      )}

      {/* {openCancelBookingReportModal &&
                <div className="modal-wrapper ">
                    <div className="modal scroll modal-lg">
                        <div className="modal-content coupon-box cancel-be-report">
                            <div className="cancel-booking-summary-box">

                            <fieldset>
                                <legend className="legend-header">CANCELLATION DETAILS</legend>
                                <div className="row">
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Name:</span> {cancelBookingReportData[displayDataIndex].first_name + ' ' + cancelBookingReportData[displayDataIndex].last_name}</p>
                                    </div>
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Address:</span> {cancelBookingReportData[displayDataIndex].address}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Check In:</span> namrata@bookingjini.com</p>
                                    </div>
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Check Out:</span> namrata@bookingjini.com</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Booking Date:</span> {cancelBookingReportData[displayDataIndex].booking_date}</p>
                                    </div>
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Booking Cancellation Date:</span> namrata@bookingjini.com</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Room Type:</span> {cancelBookingReportData[displayDataIndex].room_type}</p>
                                    </div>
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Booking Status:</span> Cancelled</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Total Amount:</span> {cancelBookingReportData[displayDataIndex].total_amount}</p>
                                    </div>
                                    <div className="col s12 m6">
                                        <p className="cancel-be-report-text"><span>Total Refund Amount:</span> namrata@bookingjini.com</p>
                                    </div>
                                </div>
                                <br />
                            </fieldset>

                            <div className="row">
                                    <div className="col s12 m12">
                                        <p className="cancel-be-report-text"><span>Note:</span> <span className="refund-amount-message">Refund Amount will be credited to the user account within 7 working days.</span></p>
                                    </div>

                                </div>

                                
                            </div>
                        </div>
                    </div>
                    <div className="modal-overlay" style={{ zIndex: '1002', display: 'block', opacity: '0.5' }}></div>
                </div>
            } */}

      {openCancelBookingReportModal && (
        <div className="modal-wrapper">
          <div className="modal scroll" style={{ background: "transparent" }}>
            <div className="modal-content">
              <div className="login-wrapper card z-depth-3">
                <span
                  className="modal-close waves-effect btnCloseRoom"
                  onClick={() => setOpenCancelBookingReportModal(false)}
                />

                <div className="row">
                  <div className="cancel-booking-data">
                    <fieldset>
                      <legend className="legend-header">
                        CANCELLATION DETAILS
                      </legend>
                      <div className="row">
                        <ul className="clearfix">
                          <li>Name</li>
                          <li>
                            {cancelBookingReportData[displayDataIndex]
                              .first_name +
                              " " +
                              cancelBookingReportData[displayDataIndex]
                                .last_name}
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <ul className="clearfix">
                          <li>Total Price</li>
                          <li>
                            <i className={currencySymbol} />{" "}
                            {
                             ( cancelBookingReportData[displayDataIndex]
                                .total_amount).toLocaleString('en-IN')
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <ul className="clearfix">
                          <li>Refund Price</li>
                          <li>
                            <i className={currencySymbol} />{" "}
                            {
                              (cancelBookingReportData[displayDataIndex]
                                .refund_amount).toLocaleString('en-IN')
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <ul className="clearfix">
                          <li>Cancellation Date</li>
                          <li>
                            {
                              cancelBookingReportData[displayDataIndex]
                                .cancelled_date
                            }
                          </li>
                        </ul>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1230", display: "block", opacity: "0.5" }}
          />
        </div>
      )}

      {cancelBookingReportData && cancelBookingReportData.length > 0 && (
        <div className="row pagination_button">
          <div className="col s12 m12">
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => {
                    setPage(1);
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa fa-angle-double-left" />
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => previousButton()}
                >
                  <i className="fa fa-angle-left" />
                </button>
              </div>

              <div className="col">
                {buttonArray.map((item, index) => {
                  return (
                    <button
                      className="btn btn-xs btn-success"
                      type="button"
                      key={index}
                      onClick={() => {
                        setPage(item);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>

              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => nextButton()}
                >
                  <i className="fa fa-angle-right" />
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-info btn-xs"
                  onClick={() => {
                    setPage(lastPage);
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa fa-angle-double-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CancelledBookingReport;
