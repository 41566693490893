import config from "../../config";

const cancelBooking = (
  booking_id,
  setDisplayDataOfCancelBooking,
  setOpenModalZeroRefund,
  setConfirmCancelMessage,
  setConfirmCancelIcon,
  changeCallUserDataAfterCancel,

  setCancelBookingLoader

) => {

  const [token, setToken] = sessionStorage.getItem("token")

  fetch(config.apiEndpoint + "cancell-booking", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      booking_id: booking_id,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        setOpenModalZeroRefund(false);
        setDisplayDataOfCancelBooking(false);
        changeCallUserDataAfterCancel(true);
        setConfirmCancelMessage("Booking Cancelled Successfully !");
        setConfirmCancelIcon("success-icon");
      } else {
        setOpenModalZeroRefund(false);
        setDisplayDataOfCancelBooking(false);
        changeCallUserDataAfterCancel(false);
        setConfirmCancelMessage("Booking Cancellation Failed !");
        setConfirmCancelIcon("cancel-icon");
      }
      setCancelBookingLoader(false);
    })
    .catch((error) => { });
};

export default cancelBooking;
