import config from "../../../config";
import moment from "moment";
const getCoupons = async (hotel_id, setCoupons, dateRange, room_type_array) => {
  let from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
  let to_date = moment(dateRange.endDate).format("YYYY-MM-DD");
  let url =
    config.apiEndpoint +
    "be_coupons/public/" +
    hotel_id +
    "/" +
    from_date +
    "/" +
    to_date;
  fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "text/json",
    },
  })
    .then((response) => response.json())
    .then((responsejson) => {
      if (responsejson.status == 1) {
        // console.log("ALL COUPONS: ", responsejson);
        setCoupons(responsejson.data);
      } else {
        setCoupons([]);
      }
    });
};
//Export the getTaxRules
export default getCoupons;
