import config from '../../config';
//Get review function to get reviews from booking.com
const getReviews = (propertyId, setReviews, setLoading) => {
  fetch(config.apiEndpoint + 'getReviews/' + propertyId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Basic Qm9va2luZ2ppbmktY2hhbm5lbG1hbmFnZXI6d1N6bldPPzJ3eS9eLWovaGZVS15NQ3E/OkEqRUspQkJYU01LLS4qKQ=='
    }
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log('response for reviews:', responseJson);
      if (responseJson.errors.length === 0) {
        setReviews(responseJson.data.reviews);
      } else {
        setReviews([]);
        setLoading(false);
      }
    });
};

//export the getReview
export default getReviews;
