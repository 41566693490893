import React, { useState } from 'react';
import HeaderLanding from './Header/HeaderLanding';
import Footer from './Footer/Footer';
import UserBookings from './User/UserBookings';
import HeaderNav from './Header/HeaderNav/HeaderNav';

const UserComponent = props => {
  return (
    <div>
      {/* <HeaderLanding history={props.history} /> */}
      <HeaderNav history={props.history} forMainPage={false} />
      <UserBookings />
      <Footer />
    </div>
  );
};
//Export the UserComponent
export default UserComponent;
