import React, { useState, useContext, useEffect } from "react";
import "react-dates/initialize";
import {
  DateRangePicker,
  SingleDatePicker,
  isInclusivelyBeforeDay,
} from "react-dates";
import "../../../../css/react_dates_overrides.css";
import {
  DateRangeContext,
  TempDateRangeContext,
  PackageDateContext,
  AdvanceBookingContext,
  HotelIdContext,
  ApiKeyContext,
} from "../../../../settings/Store";
import Responsive from "react-responsive";
import moment from "moment";
import Room from "../../../BookingPanel/Rooms/Rooms.js";
// Added React Scroll
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Snackbar from "../../../Snackbar";
import config from "../../../../config";
export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;

const DatePicker = (props) => {
  const [hotelId] = useContext(HotelIdContext);
  const [apiKey] = useContext(ApiKeyContext);

  const [dateRange, setDateRange] = useContext(DateRangeContext);
  const [tempDateRange, setTempDateRange] = useContext(TempDateRangeContext);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showMessage, setShowMessage] = useState("");
  const [datesUpdated, setDatesUpdated] = useState(false);
  const [packageDate, setPackageDate] = useContext(PackageDateContext);
  const [focused, setFocused] = useState(false);
  // const [advanceDays, setAdvanceDays] = useState(4);
  const [advanceDays, setAdvanceDays] = useContext(AdvanceBookingContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [date, setDate] = useState(moment());
  // restriction that confines the user's capability to access the date range picker to a year, commencing from the present month.
  const [maxDate, setMaxDate] = useState(moment().add(1, 'years'));
  const noOfNights = (dateRange) => {
    let date1 = new Date(dateRange.startDate);
    let date2 = new Date(dateRange.endDate);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };
  // setTimeout(() => {
  //   if (!datesUpdated) {
  //     updateDates();
  //   }
  //   setDatesUpdated(true);
  // }, 1000);
  // useEffect(() => {
  //   updateDates();
  // }, [dateRange]);

  useEffect(() => {
    fetch(
      config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotelId
    )
      .then((res) => res.json())
      .then((resJson) => setAdvanceDays(resJson.data.advance_booking_days));
  }, []);

  //New Date range set
  const newDateRange = (startDate, endDate) => {
    if (endDate === null) {
      endDate = moment(startDate).add(1, "days");
    }

    if (endDate == dateRange.endDate) {
      endDate = moment(startDate).add(1, "days");
    }

    const bookingEligibility = moment(new Date())
      .add(advanceDays - 1, "days")
      .toDate();
    const bookingEligibilityDate = moment(bookingEligibility);
    const checkin = new Date(startDate);
    const checkin_date = moment(checkin);

    if (checkin_date.isBefore(bookingEligibilityDate)) {
      // setShowMessage('Selected dates are not available!');
      setPopupOpen(true);
      return;
    }
    setDateRange({ startDate, endDate });
    setTempDateRange({ startDate, endDate });
    setShowMessage("Click on check availability to get best rates!");
    // console.log('Hello');
  };
  const updatePackageDates = (date) => {
    setPackageDate(date);
  };

  const updateDates = () => {
    let no_nights = noOfNights(tempDateRange);
    if (no_nights < 15 && no_nights > 0) {
      setDateRange(tempDateRange);
      setShowMessage("Click on check availability to get best rates!");
      props.callBackHeaderNav();
      setShowMessage("");
      // console.log('Temp Date Range:' + JSON.stringify(tempDateRange));
    } else {
      setShowMessage("Date range should be 15 nights!");
    }
  };

  //code for implement in package for single date picker
  const [singleDatepicker, setSinglDatePicker] = useState(false);
  useEffect(() => {
    let comp = window.location.href;
    let comp_array = comp.split("/");
    // console.log('comp_array', comp_array);
    if (
      comp_array.indexOf("packages") !== -1 ||
      comp_array.indexOf("packages#") !== -1 ||
      comp_array.indexOf("packages#tab-about") !== -1 ||
      comp_array.indexOf("packages#tab-policies") !== -1 ||
      comp_array.indexOf("packages#tab-map") !== -1 ||
      comp_array.indexOf("packages#tab-reviews") !== -1
    ) {
      setSinglDatePicker(true);
    } else {
      setSinglDatePicker(false);
    }
  });
  const updateSingleDates = () => {
    props.callBackHeaderNav();
  };

  return (
    <div className="container">
      {popupOpen && (
        <Snackbar message={`Not available!`} setPopupOpen={setPopupOpen} />
      )}
      <div className="row">
        {/*---------------- single datepicker for the package booking page------------------ */}
        {singleDatepicker == true ? (
          <div className="card-panel hero-box hero-box-singledatepicker">
            <div className="col s12 m7">
              <div className="single-date-picker-wrap">
                <Default>
                  <SingleDatePicker
                    numberOfMonths={1}
                    onDateChange={(date) => updatePackageDates(date)}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    focused={focused}
                    date={packageDate}
                    displayFormat="DD MMM YYYY"
                    hideKeyboardShortcutsPanel
                  />
                </Default>

                <Mobile>
                  <SingleDatePicker
                    numberOfMonths={1}
                    orientation="horizontal"
                    onDateChange={(date) => updatePackageDates(date)}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    focused={focused}
                    date={packageDate}
                    displayFormat="DD MMM YYYY"
                    hideKeyboardShortcutsPanel
                  />
                </Mobile>
              </div>
            </div>

            <div className="col s12 m5">
              <div className="input-field input-date-select">
                <Link to="scroll" spy={true} smooth={true} duration={1200}>
                  <button
                    href=""
                    onClick={() => updateSingleDates()}
                    className="waves-effect waves-light btn btn-search center-align"
                  >
                    Check Availability
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/*---------------- end of single datepicker for the package booking page------------------ */}

        {/* Start code section for default datepicker */}

        {singleDatepicker == true ? (
          ""
        ) : (
          <div className="card-panel hero-box">
            <div className="col s12 m8">
              <Default>
                <DateRangePicker
                  numberOfMonths={2}
                  // isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                  orientation="horizontal"
                  startDateId="start"
                  endDateId="end"
                  startDate={tempDateRange.startDate}
                  endDate={tempDateRange.endDate}
                  onDatesChange={({ startDate, endDate }) => {
                    newDateRange(startDate, endDate);
                    // console.log('Hello');
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  displayFormat="DD MMM YYYY"
                  isOutsideRange={(day) =>
                    day.isBefore(moment(), 'day') || day.isAfter(maxDate, 'day')
                  }
                />
              </Default>

              <Mobile>
                <DateRangePicker
                  numberOfMonths={2}
                  orientation="vertical"
                  startDateId="start"
                  startDate={tempDateRange.startDate}
                  endDate={tempDateRange.endDate}
                  endDateId="end"
                  onDatesChange={({ startDate, endDate }) =>
                    newDateRange(startDate, endDate)
                  }
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  displayFormat="DD MMM YYYY"
                  isOutsideRange={(day) =>
                    day.isBefore(moment(), 'day') || day.isAfter(maxDate, 'day')
                  }
                />
              </Mobile>
            </div>

            <div className="col s12 m4">
              <div className="input-field input-date-select">
                <Link to="scroll" spy={true} smooth={true} duration={1200}>
                  <button
                    href=""
                    onClick={() => updateDates()}
                    // disabled={checkForDisabled()}
                    className="waves-effect waves-light btn btn-search center-align"
                  >
                    Check Availability
                  </button>
                </Link>
              </div>
            </div>

            {showMessage && (
              <div className="showMessage">
                <p className="show--message">
                  {showMessage}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

//export the DatePicker
export default DatePicker;
