import React from 'react'
const ReviewLoader=()=>{
    return(
        <div className="row">
            <div className="reviewLoader">
            </div>
        </div>
    )
}

//Export ReviewLoader
export default ReviewLoader