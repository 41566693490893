import config from "../../../config";


const fetchOccupancySetupDetails = (hotel_id, setOccupancySetupDetails) => {

    fetch(`${config.apiEndpoint}get-child-setup/${hotel_id}`)
        .then((response) => response.json())
        .then((responseJson) => {

            if (responseJson.status === 1) {
                setOccupancySetupDetails(responseJson.data)
            }

        })
        .catch((e) => console.log(e));

}

export default fetchOccupancySetupDetails;
