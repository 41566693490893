import config from "../../config";

const cancelBookingRequest = (
  userData,
  setDisplayCancelData,
  setOpenCancelBookingModal,
  setLoading
) => {
  let invoiceId = userData.booking_id.substring(6);

  fetch(
    config.apiEndpoint +
    "be_cancellation_policy/fetch_cancel_refund_amount/" +
    invoiceId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      setOpenCancelBookingModal(true);
      setLoading(false);
      setDisplayCancelData({
        user_name: userData.first_name + " " + userData.last_name,
        total_amount: userData.total_amount,
        refund_amount: responseJson,
        booking_id: userData.booking_id,
      });
    })
    .catch((error) => { });
};

export default cancelBookingRequest;
