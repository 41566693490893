import React from 'react';
import HeaderNav from './HeaderNav/HeaderNav';
const Header = props => {
  const callBackHeader = () => {
    props.callBackCheckAvail(true);
  };
  const callBackMenuClick = menu => {
    props.callBackToBookingPanel(menu);
  };
  return (
    <HeaderNav
      hideHeaderNFooter={props.hideHeaderNFooter}
      history={props.history}
      callBackHeader={callBackHeader}
      callBackMenuClick={callBackMenuClick}
      forMainPage={true}
    />
  );
};
//export the Header
export default Header;
