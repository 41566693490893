import React, { useContext } from 'react';
import HeaderLanding from './Header/HeaderLanding';
import HotelListPanel from './HotelListPanel/HotelListPanel';
import Footer from './Footer/Footer';
const HotelListComponent= (props) =>{
    return (
      <div>
        <HeaderLanding history={props.history} />
        <HotelListPanel history={props.history} />
        <Footer />
      </div>
    );
}
export default HotelListComponent;
