import React, { useState, useContext, useEffect } from "react";
import noRoomSelect from "../../../img/bed.png";
import moment from "moment";
import {
  HotelIdContext,
  DateRangeContext,
  ApiKeyContext,
  CurrencyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  PackageDateContext,
} from "../../../settings/Store";
import getCurrencyValue from "../../BookingPanel/Cart/getCurrencyValue";
import fetchHotelInfo from "./fetchHotelInfo";
import newBookRequest from "./newBookRequest";
import userSignup from "../../BookingPanel/Cart/userSignUp";
import countryCodes from "../../BookingPanel/Cart/countryCodes.json";
import identity from "../../BookingPanel/Cart/identity.json";
import DatePicker from "react-date-picker";
import ImageSlider from "../Packages/PackageImageSlider";
import FetchNotificationSlider from '../../fetchNotificationSlider';
import covid1 from "../../../img/covid1.jpg";
import covid2 from "../../../img/covid2.jpg";
import covid3 from "../../../img/covid3.jpg";

import getCountry from './getCountry';
import getState from './getState';
import getCity from './getCity';

const loadData = () => JSON.parse(JSON.stringify(countryCodes));
const loadIdentityData = () => JSON.parse(JSON.stringify(identity));
const Cart = (props) => {
  const [hotelId] = useContext(HotelIdContext);
  const [dateRange] = useContext(DateRangeContext);
  const [otherTaxArray, setOtherTaxArray] = useState([]);
  const [totalGst, setTotalGst] = useState(0);
  const [otherTaxTotal, setOtherTaxTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);
  const [currency] = useContext(CurrencyContext);
  const [packageDate, setPackageDate] = useContext(PackageDateContext);
  let startDate = moment(dateRange.startDate).format("Do MMM");
  let endDate = moment(dateRange.endDate).format("Do MMM");
  let startPackageDate = moment(packageDate).format("DD-MM-YYYY");
  const noOfNights = () => {
    let date1 = new Date(dateRange.startDate);
    let date2 = new Date(dateRange.endDate);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };
  const [dateForExpiryDate, setdateForExpiryDate] = useState();// ----------date for minDate in expiry date datepicker---------//

  //Notification Slider
  const [sliderImages, setSliderImages] = useState([]);
  useEffect(() => {
    if (hotelId !== 0) {
      FetchNotificationSlider(hotelId, setSliderImages);
    }
  }, [hotelId]);

  //Notification Slider


  const [travelerLocation, SetTravelerLocation] = useState();
  const [countryID, setCountryID] = useState();
  const [stateID, setStateID] = useState();


  //Fetch all country
  const [allCountry, setAllCountry] = useState([]);
  useEffect(() => {
    if (hotelId !== 0 && countryID) {
      getCountry(setAllCountry, SetTravelerLocation, countryID, setUserState);
    }
  }, [hotelId, countryID]);

  //Fetch all state
  const [allState, setAllState] = useState([]);
  useEffect(() => {
    if (hotelId !== 0 && countryID) {
      getState(countryID, setAllState);
    }
  }, [hotelId, countryID]);


  //Fetch all city
  const [allCity, setAllCity] = useState([]);
  useEffect(() => {
    if (hotelId !== 0 && stateID) {
      getCity(stateID, setAllCity);
    }
  }, [hotelId, stateID]);









  // const noOfPackageNights = ()=>{
  //   let date1 = new Date(startPackageDate);
  //     let date2 = new Date(dateRange.endDate);
  //     let timeDiff = Math.abs(date2.getTime() - date1.getTime());
  //     let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  //     return diffDays;
  // }
  let no_nights = noOfNights();
  //To calculate the Other tax and set display other taxes

  useEffect(() => {
    setUserState((oldState) => {
      return {
        ...oldState,
        name: sessionStorage.getItem("name"),
        emailId: sessionStorage.getItem("email"),
        mobile: sessionStorage.getItem("phone"),
      };
    });
  }, [
    sessionStorage.getItem("name"),
    sessionStorage.getItem("email"),
    sessionStorage.getItem("phone"),
  ]);

  useEffect(() => {
    // -----------set date for minDate in expiry date datepicker------//
    props.cart && props.cart.map((cartItem) => {
      var checkOutDate = cartItem.checkout_date;
      var endDate = checkOutDate.split("-")
      var checkOut = new Date(endDate[2], endDate[1] - 1, endDate[0])
      setdateForExpiryDate(checkOut)
    });
    // -----------end of date for minDate in expiry date datepicker------//

    // if (props.taxRulesObject.hasOwnProperty("tax_rules")) {
    //   props.taxRulesObject.tax_rules.map((tax_object, index) => {
    //     if (otherTaxArray.length < props.taxRulesObject.tax_rules.length) {
    //       otherTaxArray.push({
    //         tax_name:
    //           typeof tax_object == "object" ? tax_object.tax_name : false,
    //         tax_amount: 0,
    //         display_tax_amount: 0,
    //       });
    //     }
    //   });
    // }
    // let newOtherTaxArray = [];
    // props.cart &&
    //   props.cart.map((cartItem) => {
    //     cartItem.tax &&
    //       cartItem.tax.map((taxItem) => {
    //         taxItem.other_tax &&
    //           taxItem.other_tax.map((tax_object, index) => {
    //             otherTaxArray[index].tax_amount +=
    //               otherTaxArray[index].tax_name == tax_object.tax_name
    //                 ? tax_object.tax_price
    //                 : 0;
    //             otherTaxArray[index].display_tax_amount =
    //               currency !== baseCurrency
    //                 ? otherTaxArray[index].tax_amount * currencyValue.toFixed(2)
    //                 : otherTaxArray[index].tax_amount.toFixed(2);
    //           });
    //       });
    //   });
    // newOtherTaxArray = [...otherTaxArray];
    // setOtherTaxArray(newOtherTaxArray);
  }, [props.cart]);
  //Get the currency value
  const [currencyValue, setCurrencyValue] = useState(1);
  useEffect(() => {
    if (baseCurrency !== null && baseCurrency !== "" && currency !== null) {
      if (currency != "INR") {
        setUserInfoStep("step0");
      }
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency, setCurrencyValue]);
  useEffect(() => {
    getTotal(props.cart);
    //setCheckedStatus(false)
  }, [hotelId, props.cart, currency]);
  //Get Total GST
  const [discountPriceDisplay, setDiscountPriceDisplay] = useState(0);
  const [gstTotalPriceDisplay, setGstTotalPriceDisplay] = useState(0);
  const [otherTaxTotalPriceDisplay, setOtherTaxTotalPriceDisplay] = useState(0);
  const [TotalPriceDisplay, setTotalPriceDisplay] = useState(0);
  const [allTotalPriceDisplay, setAllTotalPriceDisplay] = useState(0);
  // const [gstNumberFilter, setGstNumberFilter] = useState(); 
  const [OverSeaFilter, setOverSeaFilter] = useState(); //-----------gst and non gst calculation------------//

  const [isTaxable, setIsTaxable] = useState();


  // useEffect(() => {
  //   console.log("Cart: ", props.cart);
  // }, [props.cart]);

  const getTotal = (cart) => {
    let gst_total_price = 0;
    let totalPrice = 0;
    let totalDiscountPrice = 0;
    let extra_adult_price = 0;
    let extra_child_price = 0;
    cart &&
      cart.length > 0 &&
      cart.map((cartItem) => {
        cartItem.packages.map((pack) => {
          extra_adult_price += pack.extra_adult_price;
          extra_child_price += pack.extra_child_price;
        });
        totalDiscountPrice += cartItem.discounted_price;
        gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
        totalPrice += cartItem.price + extra_adult_price + extra_child_price;
      });
    setTotalDiscount(totalDiscountPrice);
    setTotalPrice(totalPrice);
    setTotalGst(gst_total_price);
    // setOtherTaxTotal(
    //   otherTaxArray.length > 0
    //     ? parseFloat(
    //       otherTaxArray
    //         .reduce((tax_amount, tax_obect) => {
    //           return (tax_amount += tax_obect.tax_amount);
    //         }, 0)
    //         .toFixed(2)
    //     )
    //     : 0
    // );
    setDiscountPriceDisplay(
      currency !== baseCurrency
        ? parseFloat((totalDiscountPrice * currencyValue).toFixed(2))
        : totalDiscountPrice
    );
    if (isTaxable == 1) { //--------gst and non gst calculation----------//
      setGstTotalPriceDisplay(
        currency !== baseCurrency
          ? parseFloat((gst_total_price * currencyValue).toFixed(2))
          : gst_total_price
      );
    }
    // if (isTaxable == 1) { //--------gst and non gst calculation----------//
    //   setOtherTaxTotalPriceDisplay(
    //     currency !== baseCurrency
    //       ? parseFloat((otherTaxTotal * currencyValue).toFixed(2))
    //       : otherTaxTotal
    //   );
    // }
    setTotalPriceDisplay(
      currency !== baseCurrency
        ? parseFloat((totalPrice * currencyValue).toFixed(2))
        : totalPrice
    );
  };
  //Remove the Room from cart
  const removeRoom = (cartItem) => {
    props.callbackCart(cartItem);
  };
  //API CALL to fetch Partial amount pay status and percent
  const [hotelData, setHotelData] = useState();
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      fetchHotelInfo(hotelId, apiKey, setHotelData, setOverSeaFilter, setCountryID, setIsTaxable);//-------gst and non gst calculation--------//
    }
  }, [hotelId, apiKey, setHotelData]);

  const [partialPayPercent, setPartialPayPercent] = useState(100);
  const [partialPayStatus, setPartialPayStatus] = useState(false);
  useEffect(() => {
    hotelData &&
      hotelData.hasOwnProperty("partial_pay_amt") &&
      setPartialPayPercent(hotelData.partial_pay_amt);
    if (
      hotelData &&
      hotelData.hasOwnProperty("partial_payment") &&
      hotelData.partial_payment === 1
    ) {
      setPartialPayStatus(true);
    }
  }, [hotelData]);

  const [partialPay, setPartialPay] = useState(
    totalPrice + totalGst - totalDiscount
  );
  const [partialPaydisplay, setPartialPayDisplay] = useState(
    currency != baseCurrency
      ? parseFloat(
        (
          (totalPrice + totalGst - totalDiscount) *
          currencyValue
        ).toFixed(2)
      )
      : (totalPrice + totalGst - totalDiscount).toFixed(2)
  );
  const [checkedStatus, setCheckedStatus] = useState(false);

  // ---------------------gst and non gst calculation------------------------//
  useEffect(() => {
    if (isTaxable == 1) {
      setAllTotalPriceDisplay(
        currency != baseCurrency
          ? parseFloat(
            ((totalPrice + totalGst - totalDiscount) * currencyValue).toFixed(2)
          )
          : (totalPrice + totalGst - totalDiscount).toFixed(2)
      );
      setPartialPay(totalPrice + totalGst - totalDiscount);
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat(
            (
              (totalPrice + totalGst - totalDiscount) *
              currencyValue
            ).toFixed(2)
          )
          : (totalPrice + totalGst - totalDiscount).toFixed(2)
      );
      props.cart.map((cartItem) => {
        cartItem.paid_amount =
          totalPrice + totalGst - totalDiscount;
        cartItem.paid_amount_per = 100;
      });
    }

    if (isTaxable == 0) {
      setAllTotalPriceDisplay(
        currency != baseCurrency
          ? parseFloat(
            ((totalPrice - totalDiscount) * currencyValue).toFixed(2)
          )
          : (totalPrice - totalDiscount).toFixed(2)
      );
      setPartialPay(totalPrice - totalDiscount);
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat(
            (
              (totalPrice - totalDiscount) *
              currencyValue
            ).toFixed(2)
          )
          : (totalPrice - totalDiscount).toFixed(2)
      );
      props.cart.map((cartItem) => {
        cartItem.paid_amount =
          totalPrice - totalDiscount;
        cartItem.paid_amount_per = 100;
      });
    }
  }, [totalPrice, totalGst, totalDiscount, props.cart]);

  // ---------------------end of gst and non gst calculation------------------------//
  //Handle the Partial payment
  const handlePartialPay = (e) => {
    let partial_amount = 0;
    let partialPayPercent = 100;
    if (e.target.checked) {
      setCheckedStatus(true);
      partial_amount =
        ((totalPrice + totalGst - totalDiscount) *
          e.target.value) /
        100;
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat((partial_amount * currencyValue).toFixed(2))
          : partial_amount.toFixed(2)
      );
      setPartialPay(partial_amount);
      partialPayPercent = e.target.value;
    } else {
      partial_amount = totalPrice + totalGst - totalDiscount;
      setCheckedStatus(false);
      setPartialPay(totalPrice + totalGst - totalDiscount);
      setPartialPayDisplay(
        currency != baseCurrency
          ? parseFloat(
            (
              (totalPrice + totalGst - totalDiscount) *
              currencyValue
            ).toFixed(2)
          )
          : (totalPrice + totalGst - totalDiscount).toFixed(2)
      );
    }
    props.cart.map((cartItem) => {
      cartItem.paid_amount = partial_amount;
      cartItem.paid_amount_per = parseInt(partialPayPercent);
    });
  };
  //Country code
  const [countryCodes, setCountryCodes] = useState([]);
  useEffect(() => {
    setCountryCodes(loadData());
  }, [setCountryCodes]);
  //user identity code
  const [identityData, setIdentityData] = useState([]);
  useEffect(() => {
    setIdentityData(loadIdentityData());
  }, [setIdentityData]);
  //Set the user details form Initially hidden
  const [userFormOpen, setUserFormOpen] = useState(false);
  //Show User form
  const showUserForm = () => {
    if (token && props.cart.length > 0) {
      setLoading(true);
      newBookRequest(hotelId, dateRange, props.cart, apiKey, token);
    } else {
      setUserFormOpen(true);
    }
  };
  //User Personal orBusiness
  const [personalTab, setPersonalTab] = useState(true);
  const [businessTab, setBusinessTab] = useState(false);

  //Activate User or Business
  const activateTab = (type) => {
    if (type == "personal") {
      setPersonalTab(true);
      setBusinessTab(false);
    } else {
      setPersonalTab(true);
      setBusinessTab(true);
    }
  };

  const [userState, setUserState] = useState({
    name: "",
    emailId: "",
    countryCode: "+91",
    mobile: "",
    companyName: "",
    GSTIN: "",
    identity: "",
    address: "",
    zipcode: "",
    city: "",
    state: "",
    countryName: "",
    identityno: "",
  });
  useEffect(() => {
    setUserState((oldState) => {
      return {
        ...oldState,
        name: sessionStorage.getItem("name"),
        emailId: sessionStorage.getItem("email"),
        mobile: sessionStorage.getItem("phone"),
      };
    });
  }, [
    sessionStorage.getItem("name"),
    sessionStorage.getItem("email"),
    sessionStorage.getItem("phone"),
  ]);
  const [
    {
      nameErr,
      emailIdErr,
      countryCodeErr,
      mobileErr,
      companyNameErr,
      GSTINErr,
      identityErr,
      addressErr,
      countryNameErr,
      zipCodeErr,
      cityErr,
      stateErr,
      identityNoErr,
      expiryDateErr,
      dobErr,
    },
    setStateErr,
  ] = useState("");
  const handleChange = (e, name) => {

    if (name === 'countryName') {
      setAllState([]);
      setAllCity([]);
      setUserState((prevState) => ({
        ...prevState,
        state: '',
        city: ''
      }));


      let country_id;
      allCountry.map((item) => {
        if (item.country_name === e) {
          country_id = item.country_id;
        }
      })
      setCountryID(country_id);
    }

    if (name === 'state') {
      setAllCity([]);
      setUserState((prevState) => ({
        ...prevState,
        city: ''
      }));

      let state_id;
      allState.map((item) => {
        if (item.state_name === e) {
          state_id = item.state_id;
        }
      })
      setStateID(state_id);
    }


    setUserState((prevState) => ({ ...prevState, [name]: e }));
  };

  const [userIdentityState, setUserIdentityState] = useState({
    identity: "select",
  });

  //Payment process
  const [token, setToken] = sessionStorage.getItem("token")
    ? useState(sessionStorage.getItem("token"))
    : useState("");
  const [loading, setLoading] = useState(false);
  const [firstUserInfo, setFirstUserInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [userInfoStep, setUserInfoStep] = useState("step0");
  const addUserInfo = () => {
    setUserInfo(true);
    setFirstUserInfo(false);
    setUserInfoStep("step2");
  };
  const continueAddInfo = () => {
    setStateErr({});
    let textRegex = /^[A-Za-z ]*$/; //--------- validation for guest name---------//
    let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!userState.name || userState.name === "null") {
      setStateErr({ nameErr: "Guest name is required!" });
      return false;
    }
    //-------------- validation for guest name---------------//
    if (!textRegex.test(userState.name)) {
      setStateErr({ nameErr: 'Name should contain only letters!' });
      return false;
    }
    //---------------end of validation for guest name-------//
    if (
      !userState.emailId ||
      userState.emailId === "null" ||
      !emailRegex.test(userState.emailId)
    ) {
      setStateErr({ emailIdErr: "Email id should be valid" });
      return false;
    }
    if (!userState.countryCode || userState.countryCode === "null") {
      setStateErr({ countryCodeErr: "Country code should be valid" });
      return false;
    }
    if (
      !userState.mobile ||
      userState.mobile === "null" ||
      userState.mobile.length !== 10
    ) {
      setStateErr({ mobileErr: "Mobile no should be valid" });
      return false;
    }
    if (personalTab && businessTab) {
      if (!userState.companyName || userState.companyName === "null") {
        setStateErr({ companyNameErr: "Company Name should be valid" });
        return false;
      }
      if (!userState.GSTIN || userState.GSTIN === "null") {
        setStateErr({ GSTINErr: "GSTIN should be valid" });
        return false;
      }
    }
    setUserInfoStep("step1");
  };
  const continueAddDetails = () => {
    setStateErr({});
    if (!userState.address || userState.address === "null") {
      setStateErr({ addressErr: "Address should not be empty" });
      return false;
    }
    if (!userState.countryName || userState.countryName === "null") {
      setStateErr({ countryNameErr: "Country name should not be empty" });
      return false;
    }
    // if (!userState.state || userState.state === "null") {
    //   setStateErr({ stateErr: "State should not be empty" });
    //   return false;
    // }
    // if (!userState.city || userState.city === "null") {
    //   setStateErr({ cityErr: "City should not be empty" });
    //   return false;
    // }
    let zipCodeRegx = /^[a-zA-Z0-9]/;
    if (!userState.zipcode || userState.zipcode === "null") {
      setStateErr({ zipCodeErr: "Zipcode should not be empty" });
      return false;
    }
    if (!zipCodeRegx.test(userState.zipcode)) {
      setStateErr({ zipCodeErr: "Zipcode should be a number" });
      return false;
    }

    if (personalTab && businessTab) {
      if (!userState.companyName || userState.companyName === "null") {
        setStateErr({ companyNameErr: "Company Name should be valid" });
        return false;
      }
      if (!userState.GSTIN || userState.GSTIN === "null") {
        setStateErr({ GSTINErr: "GSTIN should be valid" });
        return false;
      }
    }
    setUserInfoStep("step4");
  };
  //set expiry date and date of birth
  const [expiry_date, setExpiryData] = useState();
  const [dob, setDob] = useState();
  const payNow = () => {
    setStateErr({});
    // if (userInfoStep == "step1" && currency == "INR") {
    if (userInfoStep == "step1") {

      if (!userState.address || userState.address === "null") {
        setStateErr({ addressErr: "Address should not be empty" });
        return false;
      }
      if (!userState.countryName || userState.countryName === "null") {
        setStateErr({ countryNameErr: "Country name should not be empty" });
        return false;
      }
      // if (!userState.state || userState.state === "null") {
      //   setStateErr({ stateErr: "State should not be empty" });
      //   return false;
      // }
      // if (!userState.city || userState.city === "null") {
      //   setStateErr({ cityErr: "City should not be empty" });
      //   return false;
      // }
      let zipCodeRegx = /^[a-zA-Z0-9]/;
      if (!userState.zipcode || userState.zipcode === "null") {
        setStateErr({ zipCodeErr: "Zipcode should not be empty" });
        return false;
      }
      if (!zipCodeRegx.test(userState.zipcode)) {
        setStateErr({ zipCodeErr: "Zipcode should be a number" });
        return false;
      }

      if (personalTab && businessTab) {
        if (!userState.companyName || userState.companyName === "null") {
          setStateErr({ companyNameErr: "Company Name should be valid" });
          return false;
        }
        if (!userState.GSTIN || userState.GSTIN === "null") {
          setStateErr({ GSTINErr: "GSTIN should be valid" });
          return false;
        }
      }

      setLoading(true);
      userSignup(userState, expiry_date, dob, setToken, callBackFromSignup);
    }
    // if (userInfoStep == "step4" && currency != "INR") {
    if (userInfoStep == "step4") {

      // commented on 19-10-2021
      // if (!userState.identity || userState.identity === "null") {
      //   setStateErr({ identityErr: "Identity should be valid" });
      //   return false;
      // }
      // if (!userState.identityno || userState.identityno === "null") {
      //   setStateErr({ identityNoErr: "Identity No should be valid" });
      //   return false;
      // }
      // commented on 19-10-2021



      // if(expiry_date === undefined){
      //     setStateErr({expiryDateErr:'Expiry date should be valid'})
      //     return false
      // }



      // commented on 19-10-2021
      // if (dob === undefined) {
      //   setStateErr({ dobErr: "Date of birth should be valid" });
      //   return false;
      // }
      // commented on 19-10-2021



      // ---------------------validation for dob----------------------//
      // var a = moment();
      // var b = moment(dob, 'YYYY');
      // var age = moment.duration(a.diff(b));
      // var years = age.years();
      // if (years < 18) {
      //   setStateErr({ dobErr: "18+ yrs are allowed" });
      //   return false;
      // }
      // --------------------------end of validation for dob-----------------//


      setLoading(true);
      userSignup(userState, expiry_date, dob, setToken, callBackFromSignup);
    }
  };
  const [apiKey] = useContext(ApiKeyContext);
  //CallBackFrom user sign up
  const callBackFromSignup = (token) => {
    if (token && token != "" && props.cart.length > 0) {
      sessionStorage.setItem("token", token);
      newBookRequest(hotelId, dateRange, props.cart, apiKey, token);
    }
  };
  const guestTab = {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "4%",
  };

  const guestInput = {
    paddingLeft: "0px",
    paddingRight: "0px",
  };

  const paddingLeft = {
    paddingLeft: "0px",
  };

  const paddingRight = {
    paddingRight: "0px",
  };
  //-----set date for maxdate in dob datepicker------------//
  let dobDatePickerDate = moment().subtract(18, 'years');
  dobDatePickerDate = new Date(dobDatePickerDate);
  //-----end of set date for maxdate in dob datepicker------------//

  return (
    <div className={`room-box-right ${props.cart.length === 0 &&
      "covid-slider-zIndex"}`}>
      <div className="row">
        <div className="booking-summary-box mobile-margin-top"
          style={{ padding: props.cart.length === 0 && "0px" }}
        >

          {props.cart && props.cart.length === 0 && (
            <div className="card" style={{ margin: "0px" }}>
              {sliderImages && sliderImages.length > 0 ? <div className="imgSlider">
                <ImageSlider images={sliderImages} customImages />
              </div>
                :
                <div className="imgSlider">
                  <ImageSlider images={[covid1, covid2, covid3]} customImages />
                </div>
              }
            </div>
          )}

          {/* {props.cart && props.cart.length == 0 && (
            <div className="no-booking-preview">
              <h3>Booking Summary</h3>
              <div className="image-wrap-bed center-align">
                <img src={noRoomSelect} />
              </div>
              <p className="booking-summary-paragraph">No Package Selected</p>
              <p>Click add package button to add the package</p>
            </div>
          )} */}
          {/* <br /> */}
          {props.cart && props.cart.length > 0 && (
            <div className="room-booking-summary" style={{ marginTop: "14px" }}>
              {props.cart &&
                props.cart.map((cartItem, index) => (
                  <div key={index} className="container booking-summary">
                    <div id="room-summary-box1" className="row">
                      <div className="room-summary-box">
                        <span
                          className="roomBtnclose"
                          onClick={() => removeRoom(cartItem)}
                        />
                        <p style={{ fontWeight: "bolder" }}>{cartItem.package_type}</p>
                        <p>
                          <span style={{ fontWeight: "bolder" }}>Package:</span><span> {" "}
                            {cartItem.packages.length},{" "}
                          </span>
                          <span style={{ fontWeight: "bolder" }}>Adults:</span>
                          <span>{" "}
                            {cartItem.packages && cartItem.packages.reduce((init, pack) => {
                              return init + pack.selected_adult;
                            }, 0)}
                            ,{" "}
                          </span>
                          <span style={{ fontWeight: "bolder" }}>Child:</span>
                          <span>{" "}
                            {cartItem.packages && cartItem.packages.reduce((init, pack) => {
                              return init + pack.selected_child;
                            }, 0)}
                            ,{" "}
                          </span>
                          <span style={{ fontWeight: "bolder" }}>Kids:</span>
                          <span>{" "}
                            {cartItem.packages && cartItem.packages.reduce((init, pack) => {
                              return init + pack.selected_infant;
                            }, 0)}
                          </span>
                        </p>
                        <p>
                          {cartItem &&
                            cartItem.packages.map(function (value, index) {
                              return (
                                <React.Fragment key={index}>
                                  <span style={{ fontWeight: "bolder" }}> Checkin date:{" "}</span>{cartItem.checkin_date}
                                </React.Fragment>
                              );
                            })}
                        </p>
                        <p>
                          {cartItem &&
                            cartItem.packages.map(function (value, index) {
                              return (
                                <React.Fragment key={index}>
                                  <span style={{ fontWeight: "bolder" }}>Checkout date:{" "}</span>{cartItem.checkout_date}
                                </React.Fragment>
                              );
                            })}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

              {(discountPriceDisplay > 0 || isTaxable === 1) &&
                <div className="container booking-summary">
                  <div id="room-price-summary" className="row">
                    <div className="room-price-summary">
                      <ul>
                        <li>Total Price:</li>
                        <li>
                          <i className={currencySymbol} />{" "}
                          {parseFloat(TotalPriceDisplay.toFixed(2)).toLocaleString('en-IN')}
                        </li>
                      </ul>
                      {discountPriceDisplay > 0 && (
                        <ul className="discount">
                          <li>Discount:</li>
                          <li>
                            - <i className={currencySymbol} />{" "}
                            {parseFloat(discountPriceDisplay.toFixed(2)).toLocaleString('en-IN')}
                          </li>
                        </ul>
                      )}
                      {totalGst > 0 && isTaxable == 1 && (  //----------gst and non gst calculation--------//
                        <ul>
                          <li>Tax:</li>
                          <li>
                            <i className={currencySymbol} />{" "}
                            {parseFloat(gstTotalPriceDisplay.toFixed(2)).toLocaleString('en-IN')}
                          </li>
                          <li className="clearfix" />
                        </ul>
                      )}
                      {/* {otherTaxArray.length > 0 &&
                        otherTaxArray.map((otherTax, index) => (
                          <ul key={index}>
                            <li>{otherTax.tax_name}:</li>
                            <li>
                              <i className={currencySymbol} />{" "}
                              {(otherTax.display_tax_amount).toLocaleString('en-IN')}
                            </li>
                          </ul>
                        ))} */}
                    </div>
                  </div>
                </div>
              }

              {/* <div id="total-room-pay" className="row">
                <div className="total-room-pay">
                  <ul>
                    <li>Total</li>
                    <li>
                      <i className={currencySymbol} />
                      {allTotalPriceDisplay}
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="row">
                <div id="full-room-pay" className="full-room-pay">
                  <ul className="clearfix">
                    <li>Total Amount</li>
                    <li>
                      {" "}
                      <i className={currencySymbol} />
                      {(partialPaydisplay).toLocaleString('en-IN')}
                    </li>
                  </ul>
                </div>
              </div>

              {!userFormOpen && (
                <div className="row">
                  <div className="continue-btn-wrap center-align">
                    <a
                      onClick={() => showUserForm()}
                      className="waves-effect waves-light btn-small continue-btn"
                    >
                      {" "}
                      Continue <span className={loading ? "payLoader" : ""} />
                    </a>
                  </div>
                </div>
              )}
              {userFormOpen && (
                <div>
                  <div className="row">
                    <div className="guest-details">
                      <p>Guest Details</p>
                      <div className="row" style={{ marginBottom: "0" }}>
                        <div className="col s12" style={guestTab}>
                          <ul className="tabs">
                            <li className="tab col s6">
                              <a
                                href="#personal"
                                onClick={() => activateTab("personal")}
                                className={
                                  personalTab && !businessTab ? "active" : ""
                                }
                              >
                                Personal
                              </a>
                            </li>
                            <li className="tab col s6">
                              <a
                                href="#business"
                                onClick={() => activateTab("business")}
                                className={
                                  personalTab && businessTab ? "active" : ""
                                }
                              >
                                Business
                              </a>
                            </li>
                          </ul>
                        </div>

                        {personalTab && (
                          <div id="personal" className="col s12">
                            {userInfoStep == "step1" && (
                              <div
                                className="row"
                                style={{ marginBottom: "0" }}
                              >
                                <div className="col s12" style={guestInput}>
                                  <input
                                    value={userState.address}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "address")
                                    }
                                    type="text"
                                    placeholder="Address"
                                  />
                                  {addressErr && (
                                    <p className="red-text text-darken-1">
                                      {addressErr}
                                    </p>
                                  )}
                                </div>
                                <div className="col s12" style={guestInput}>
                                  {/* <select
                                    value={userState.countryName}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "countryName"
                                      )
                                    }
                                    className="browser-default"
                                    style={{ marginTop: "5%" }}
                                  >
                                    {countryCodes &&
                                      countryCodes.map((countryCode, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={countryCode.name}
                                          >
                                            {countryCode.name}
                                          </option>
                                        );
                                      })}
                                  </select> */}

                                  <select
                                    defaultValue={travelerLocation && travelerLocation.country_name}

                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "countryName"
                                      )
                                    }
                                    className="browser-default"
                                    style={{ marginTop: "5%" }}
                                  >
                                    {allCountry &&
                                      allCountry.map((countryCode, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={countryCode.country_name}
                                          >
                                            {countryCode.country_name}
                                          </option>
                                        );
                                      })}

                                  </select>


                                  {countryNameErr && (
                                    <p className="red-text text-darken-1">
                                      {countryNameErr}
                                    </p>
                                  )}
                                </div>

                                <div className="col s12" style={guestInput}>

                                  <select
                                    onChange={(e) =>
                                      handleChange(e.target.value, "state")
                                    }
                                    className="browser-default"
                                    style={{ marginTop: "5%" }}
                                  >
                                    <option>Select State</option>
                                    {allState &&
                                      allState.map((states, idx) => {
                                        return (
                                          <option
                                            key={idx}

                                            value={states.state_name}
                                          >
                                            {states.state_name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {stateErr && (
                                    <p className="red-text text-darken-1">
                                      {stateErr}
                                    </p>
                                  )}
                                </div>

                                <div className="col s12" style={guestInput}>
                                  <div className="col s7" style={paddingLeft}>


                                    <select
                                      onChange={(e) =>
                                        handleChange(e.target.value, "city")
                                      }
                                      className="browser-default"
                                      style={{ marginTop: "5%" }}
                                    >
                                      <option>Select City</option>
                                      {allCity &&
                                        allCity.map((city, idx) => {
                                          return (
                                            <option
                                              key={idx}
                                              value={city.city_name}
                                            >
                                              {city.city_name}
                                            </option>
                                          );
                                        })}
                                    </select>

                                    {cityErr && (
                                      <p className="red-text text-darken-1">
                                        {cityErr}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col s5" style={paddingRight}>
                                    <input
                                      value={userState.zipcode}
                                      onChange={(e) =>
                                        handleChange(e.target.value, "zipcode")
                                      }
                                      type="text"
                                      placeholder="Zip Code"
                                    />
                                    {zipCodeErr && (
                                      <p className="red-text text-darken-1">
                                        {zipCodeErr}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfoStep == "step0" && (
                              <div
                                className="row"
                                style={{ marginBottom: "0" }}
                              >
                                <div className="col s12" style={guestInput}>
                                  <input
                                    value={userState.name}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "name")
                                    }
                                    type="text"
                                    placeholder="Name"
                                  />
                                  {nameErr && (
                                    <p className="red-text text-darken-1">
                                      {nameErr}
                                    </p>
                                  )}
                                </div>
                                <div className="col s12" style={guestInput}>
                                  <input
                                    value={userState.emailId}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "emailId")
                                    }
                                    onClick={() => console.log(userState)}
                                    type="text"
                                    placeholder="Email Id"
                                  />
                                  {emailIdErr && (
                                    <p className="red-text text-darken-1">
                                      {emailIdErr}
                                    </p>
                                  )}
                                </div>
                                <div className="col s12" style={guestInput}>
                                  <div className="row">
                                    <div className="col s4">
                                      {/* <select
                                        defaultValue={
                                          currency == "BDT" ? "+880" : "+91"
                                        }
                                        // value={
                                        //   currency == 'BDT'
                                        //     ? '+880'
                                        //     : userState.countryCode
                                        // }
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "countryCode"
                                          )
                                        }
                                        className="browser-default"
                                      >
                                        {countryCodes &&
                                          countryCodes.map(
                                            (countryCode, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={countryCode.dial_code}
                                                >
                                                  {countryCode.dial_code}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select> */}

                                      <select
                                        defaultValue={travelerLocation && travelerLocation.country_dial_code}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "countryCode"
                                          )
                                        }
                                        className="browser-default"
                                      >
                                        {allCountry &&
                                          allCountry.map(
                                            (countryCode, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={countryCode.country_dial_code}
                                                >
                                                  {countryCode.country_dial_code}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>


                                      {countryCodeErr && (
                                        <p className="red-text text-darken-1">
                                          {countryCodeErr}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col s8">
                                      <input
                                        value={userState.mobile}
                                        onChange={(e) =>
                                          handleChange(e.target.value, "mobile")
                                        }
                                        type="text"
                                        placeholder="Mobile Number"
                                      />
                                      {mobileErr && (
                                        <p className="red-text text-darken-1">
                                          {mobileErr}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfoStep == "step4" && (
                              <div
                                className="row"
                                style={{ marginBottom: "0" }}
                              >
                                <div
                                  className="col s12 selectMarginTop"
                                  style={guestInput}
                                >
                                  <select
                                    style={{ marginTop: "0px" }}
                                    value={userState.identity}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "identity")
                                    }
                                    className="browser-default"
                                  >
                                    {identityData &&
                                      identityData.map(
                                        (identityData, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={identityData.name}
                                            >
                                              {identityData.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                  {identityErr && (
                                    <p className="red-text text-darken-1">
                                      {identityErr}
                                    </p>
                                  )}
                                </div>

                                <div
                                  className="col s12 selectMarginTop"
                                  style={guestInput}
                                >
                                  <input
                                    style={{ marginTop: "0px" }}
                                    value={userState.identityno}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "identityno")
                                    }
                                    type="text"
                                    placeholder="Identity No"
                                  />
                                  {identityNoErr && (
                                    <p className="red-text text-darken-1">
                                      {identityNoErr}
                                    </p>
                                  )}
                                </div>

                                {/* <div className="col s12" style={guestInput}>
                                  <div
                                    className="col s6 date_picker_border"
                                    style={paddingLeft}
                                  >
                                    <DatePicker
                                      placeholder="Expiry Date"
                                      value={expiry_date}
                                      onChange={(date) => setExpiryData(date)}
                                      dayPlaceholder="Expiry Date"
                                    />
                                    
                                  </div>

                                  <div
                                    className="col s6 date_picker_border"
                                    style={paddingRight}
                                  >
                                    <DatePicker
                                      value={dob}
                                      onChange={(date) => setDob(date)}
                                      dayPlaceholder="Date of Birth"
                                      selected={new Date()}
                                      maxDate={new Date()}
                                    />
                                    {dobErr && (
                                      <p className="red-text text-darken-1">
                                        {dobErr}
                                      </p>
                                    )}
                                  </div>
                                </div> */}
                                {/* ------------identity expiry date and date of birth------------- */}
                                <div className="row">
                                  <div className="col s12">
                                    <DatePicker
                                      placeholder="Expiry Date"
                                      value={expiry_date}
                                      onChange={(date) => setExpiryData(date)}
                                      dayPlaceholder="Expiry Date"
                                      minDate={dateForExpiryDate} //--------date-picker date starts from checkout date-----------//
                                    />
                                    {/* {expiryDateErr && <p className="red-text text-darken-1">{expiryDateErr}</p> } */}
                                  </div>
                                  <div className="col s12">
                                    <DatePicker
                                      value={dob}
                                      onChange={(date) => setDob(date)}
                                      dayPlaceholder="Date of Birth"
                                      // selected={new Date()}
                                      // maxDate={new Date()}
                                      activeStartDate={dobDatePickerDate} //----land the calendar from the enable date--------//
                                      maxDate={dobDatePickerDate} //----enable date from 18 years ago from currecnt date-----//
                                    />
                                    {dobErr && (
                                      <p className="red-text text-darken-1">
                                        {dobErr}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {/* ------------end of identity expiry date and date of birth------------- */}
                              </div>
                            )}
                          </div>
                        )}

                        {businessTab &&
                          userInfoStep != "step1" &&
                          userInfoStep != "step4" && (
                            <div id="business" className="col s12">
                              <div className="row">
                                <div className="col s12" style={guestInput}>
                                  <input
                                    style={{ marginTop: "0px" }}
                                    value={userState.companyName}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "companyName"
                                      )
                                    }
                                    type="text"
                                    placeholder="Company Name"
                                  />
                                  {companyNameErr && (
                                    <p className="red-text text-darken-1">
                                      {companyNameErr}
                                    </p>
                                  )}
                                </div>
                                <div className="col s12" style={guestInput}>
                                  <input
                                    value={userState.GSTIN}
                                    onChange={(e) =>
                                      handleChange(e.target.value, "GSTIN")
                                    }
                                    type="text"
                                    placeholder="GSTIN"
                                  />
                                  {GSTINErr && (
                                    <p className="red-text text-darken-1">
                                      {GSTINErr}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    {userInfoStep == "step0" && (
                      <div className="pay-btn-wrap center-align">
                        <a
                          onClick={() => continueAddInfo()}
                          className="waves-effect waves-light btn-small pay-btn"
                        >
                          continue
                        </a>
                      </div>
                    )}
                    {/* {userInfoStep == "step1" && currency != "INR" && ( */}
                    {userInfoStep == "step1" && hotelData && hotelData.country_id !== countryID && (
                      <div className="pay-btn-wrap center-align">
                        <a
                          onClick={() => continueAddDetails()}
                          className="waves-effect waves-light btn-small pay-btn"
                        >
                          continue
                        </a>
                      </div>
                    )}
                  </div>
                  {/* {userInfoStep == "step1" && currency == "INR" && ( */}
                  {userInfoStep == "step1" && hotelData && hotelData.country_id === countryID && (
                    <div className="row">
                      <div className="pay-btn-wrap center-align">
                        <a
                          onClick={() => payNow()}
                          className="waves-effect waves-light btn-small pay-btn"
                        >
                          Pay <i className={currencySymbol} />
                          {parseFloat(partialPaydisplay).toLocaleString('en-IN')}{" "}
                          <span className={loading ? "payLoader" : ""} />
                        </a>
                      </div>
                    </div>
                  )}
                  {/* {userInfoStep == "step4" && currency != "INR" && ( */}
                  {userInfoStep == "step4" && hotelData && hotelData.country_id !== countryID && (
                    <div className="row">
                      <div className="pay-btn-wrap center-align">
                        <a
                          onClick={() => payNow()}
                          className="waves-effect waves-light btn-small pay-btn"
                        >
                          Pay <i className={currencySymbol} />
                          {parseFloat(partialPaydisplay).toLocaleString('en-IN')}{" "}
                          <span className={loading ? "payLoader" : ""} />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/*loading &&
                        <div className="modal-wrapper">
                            <div id="model1" className="modal scroll">
                                
                            </div>
                            <div className="modal-overlay" style={{zIndex: '1002', display: 'block',position:'fixed',opacity: '0.1'}}></div>
                        </div>*/}
    </div>
  );
};
//Export the Cart
export default Cart;
