import config from "../../../config";
import moment, { invalid } from "moment";
const getInventorynRates = async (
  hotelId,
  dateRange,
  currency,
  apiKey,
  setInventoryNratesList,
  setDisplayInventoryNratesList,
  setroomImageEndPoint,
  setOpenDropdown,
  setAddRoomBtnStatus
) => {
  let startDate = moment(dateRange.startDate).format("DD-MM-YYYY");
  let endDate = moment(dateRange.endDate).format("DD-MM-YYYY");

  fetch(
    config.apiEndpoint +
    "bookingEngine/get-inventory/" +
    apiKey +
    "/" +
    hotelId +
    "/" +
    startDate +
    "/" +
    endDate +
    "/" +
    currency,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((responseJson) => {
      if (responseJson.status == 1) {
        let dropDowns = {};
        // console.log('LOS: ' + responseJson.data[0].inv[0].los);
        responseJson.data.map((inv, i) => {
          inv.rate_plans &&
            inv.rate_plans.map((rate, index) => {
              let key =
                inv.room_type_id.toString() + rate.rate_plan_id.toString();
              let ob = { [key]: false }; //Initially all set dropdown to false
              Object.assign(dropDowns, ob);
            });
        });
        responseJson.data.map((inv_data, i) => {
          let blockStatusCount = 0;
          inv_data.inv.map((inv_Details) => {
            if (parseInt(inv_Details.block_status) === 0) {
              blockStatusCount++;
            }
          });
          if (blockStatusCount === inv_data.inv.length) {
            inv_data.block = false;
          } else {
            inv_data.block = true;
          }
        });

        //Codes Added for Select No Of Rooms
        responseJson.data.map((inv_data, index) => {
          let display_no_of_rooms = [];
          for (let i = 1; i <= inv_data.min_inv; i++) {
            display_no_of_rooms.push(i);
          }
          inv_data.display_no_of_rooms = display_no_of_rooms;
        });
        //Codes Added for Select No Of Rooms


        //------------------room images listing------------------------//
        let first_images = []
        responseJson.data.map((room_image, i) => {
          first_images.push(room_image.image)
        })
        setroomImageEndPoint(first_images);
        //------------------end of room images listing-----------------//

        setInventoryNratesList(responseJson.data);
        setDisplayInventoryNratesList(responseJson.data);
        // console.log('Room data: ' + JSON.stringify(responseJson.room_data[0]));
        setOpenDropdown(dropDowns);
        setAddRoomBtnStatus(dropDowns);
      }
    })
    .catch((error) => { });
};
//Export getAccess
export default getInventorynRates;
