import React, {
  useContext,
  useState,
  useEffect,
  Link as ReactLink,
} from "react";
import Logo from "./Logo/Logo";
import DatePicker from "./DatePicker/DatePicker";
import fetchHotels from "../../HotelListPanel/fetchHotels";
import getBaseCurrency from "../../HotelListPanel/getBaseCurrency";
import PinInput from "react-pin-input";
import {
  CompanyContext,
  CompHashContext,
  HotelIdContext,
  CurrencyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  ApiKeyContext,
} from "../../../settings/Store";
import userLogin from "../userLogin";
import config from "../../../config";
import SideBar from "./Sidebar";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

import countryCodes from "../../BookingPanel/Cart/countryCodes.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import fetchMobileNumberChangeStatus from "./fetchMobileNumberChangeStatus";
import changeUserMobileNumber from "./changeUserMobileNumber";
import ReactPixel from "react-facebook-pixel";

const loadData = () => JSON.parse(JSON.stringify(countryCodes));
const HeaderNav = (props) => {
  //Use context to get the global Company id and Comp hash and hotel id context
  const [compState] = useContext(CompanyContext);
  const [compHash] = useContext(CompHashContext);
  const [hotelId, setHotelId] = useContext(HotelIdContext);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [currencySymbol, setCurrencySymbol] = useContext(CurrencySymbolContext);
  const [hotelList, setHotelList] = useState([]);
  const [baseCurrency, setBaseCurrency] = useContext(BaseCurrencyContext);
  const [openDropdown, setOpenDropdown] = useState(false);
  // const [token, setToken] = useState([]);
  const [header, setHeader] = useState(false);
  const [homeUrl, setHomeUrl] = useState("");
  // const [otpVerified, setOtpVerified] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [userState, setUserState] = useState("login");
  const [otpMsg, setOtpMsg] = useState("Enter OTP Sent to Your Mobile Number!");

  const [hotel_Id] = useContext(HotelIdContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [showPackageMenu, setShowPackageMenu] = useState("");

  const [otpValue, sendOtpValue] = useState();
  let pin_values;
  //API CALL to fetch hotels
  useEffect(() => {
    if (compHash !== "" && compState !== 0) {
      fetchHotels(compHash, compState, setHotelList);
    }
  }, [compHash, compState, setHotelList]);

  useEffect(() => {
    if (window.screen.width < 1200) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  });
  //Get the base Currency
  useEffect(() => {
    if (compHash !== "" && currency !== "") {
      getBaseCurrency(compState, setBaseCurrency);
    }
  }, [compState, setBaseCurrency, currency]);
  //Currency Array
  const currency_arr = [
    { name: "INR", symbol: "fa fa-inr" },
    { name: "USD", symbol: "fa fa-usd" },
    { name: "EUR", symbol: "fa fa-eur" },
    { name: "GBP", symbol: "fa fa-gbp" },
    { name: "BDT", symbol: "fa fa-bdt" },
  ];
  //Set currency symbol
  useEffect(() => {
    currency_arr &&
      currency_arr.map((loop_currency, index) => {
        if (loop_currency.name === currency) {
          setCurrencySymbol(loop_currency.symbol);
        }
      });
  }, [currency]);

  // useEffect(() => {
  //   if (otpVerified) {
  //     if (sessionStorage.getItem("token")) {
  //       // sendOtp(mobileNo);
  //       // setUserState('otp');
  //       redirectToUser(sessionStorage.getItem("token"));
  //     }

  //     // else {
  //     //   // sendOtp(mobileNo);
  //     //   // setUserState('otp');
  //     //   userLogin(setToken, mobileNo, redirectToUser);
  //     // }

  //     // console.log('Hello', otpVerified, sessionStorage.getItem('token'));
  //   }
  // }, [otpVerified]);

  const verifyOtp = (value) => {
    if (otpValue === btoa(value)) {
      const fd = new FormData();
      fd.append("mobileno", localStorage.getItem("mobile_number"));
      fd.append("otp_validity", value);

      //changes//
      fd.append("company_id", compState);
      //changes//

      fetch(config.apiEndpoint + "public_user/post", {
        method: "POST",
        body: fd,
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.status == 1) {
            sessionStorage.setItem("user_logged_in", true);
            redirectToUser(resJson.auth_token);
          } else if (resJson.status == 0) {
            setOtpErr("OTP invalid");
            setTimeout(() => {
              setOtpErr("");
            }, 7000);
          }
        })
        .catch((e) => console.log(e));
    } else {
      setOtpErr("Invalid OTP!");
      setTimeout(() => {
        setOtpErr("");
      }, 7000);
    }
  };

  const setCurrencyNsymbol = (currency_name, symbol) => {
    setCurrency(currency_name);
    setCurrencySymbol(symbol);
  };
  const callBackHeaderNav = () => {
    props.callBackHeader();
  };
  //Toggle Dropdown
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const [banner, setBanner] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  //Set the Home Url
  const getHomeUrl = (homeUrl, banner) => {
    homeUrl = homeUrl.indexOf("://") === -1 ? "http://" + homeUrl : homeUrl;
    setHomeUrl(homeUrl);
    setBanner(banner);
    setBannerImage(banner[0].image_name);
  };
  //Set the Hotel
  const setHotel = (hotel_id) => {
    setHotelId(hotel_id);
  };
  //Country code
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");

  useEffect(() => {
    setCountryCodes(loadData());
  }, [setCountryCodes]);
  const handleChange = (value) => {
    setCountryCode(value);
  };
  //Function to validate input data and call API
  let mobileNo;
  const [mobileErr, setMobileErr] = useState("");

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      validateData("send");
    }
  };

  const validateData = (type) => {
    setMobileErr("");
    let mobile;
    if (type === "send") {
      mobile = document.getElementById("mobileNumber").value;
      localStorage.setItem("mobile_number", mobile);
    } else mobile = localStorage.getItem("mobile_number");

    // var letters = /^[A-Za-z]+$/;
    // if (mobile.length !== 10) {
    //   setMobileErr("Please enter a valid phone number");
    // } else if (mobile.match(letters)) {
    //   setMobileErr("Please enter numbers only");
    // } else {
    //   mobileNo = mobile.replace(/[^\d]/g, "");
    //   const resend = type === "resend" ? true : false;
    //   //Already Logged in
    //   if (sessionStorage.getItem("token")) {
    //     sendOtp(mobileNo, resend);
    //     setUserState("otp");
    //     redirectToUser(sessionStorage.getItem("token"));
    //   } else {
    //     sendOtp(mobileNo, resend);
    //     setUserState("otp");
    //     userLogin(setToken, mobileNo, redirectToUser);
    //   }
    // }

    //My Code
    let numbers = /^[0-9+-]*$/;
    if (mobile.length !== 10) {
      setMobileErr("Please enter a valid phone number");
    } else {
      if (mobile.match(numbers)) {
        fetch(
          config.apiEndpoint +
          "public_user/fetch_user_login_details/" +
          mobile +
          "/" +
          compState,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.status == 1) {
              mobileNo = mobile.replace(/[^\d]/g, "");
              const resend = type === "resend" ? true : false;
              //Already Logged in
              // if (sessionStorage.getItem("token")) {
              //   sendOtp(mobileNo, resend);
              //   setUserState("otp");
              //   redirectToUser(sessionStorage.getItem("token"));
              // } else {
              sendOtp(mobileNo, resend);
              setUserState("otp");
              // }
            } else {
              setMobileErr("Please enter a registered mobile number !");
            }
          })
          .catch((e) => console.log(e));
      } else {
        setMobileErr("Please enter numbers only");
      }
    }
    // End My Code
  };
  //After Auth Redirect User
  const redirectToUser = (token) => {
    sessionStorage.setItem("token", token);
    props.history.push(config.baseUrl + "user");
    setUser(true);
  };

  //Sending OTP to the mobile
  const sendOtp = (mobileNo, resend) => {
    if (resend) {
      setOtpErr("");
      if (pin_values) {
        pin_values.clear();
      }
    }

    const fb = new FormData();
    fb.append("mobile", mobileNo);
    fb.append("code", countryCode);
    // fetch("http://api.bookingjini.com/be/public_user/otp", {
    fetch(config.apiEndpoint + "bookingEngine/send-otp", {
      method: "POST",
      body: fb,
    })
      .then((res) => res.json())
      .then((resJson) => {
        // sendOtpValue(resJson.otp_value);

        sendOtpValue(resJson.data);

        if (resend) setOtpMsg("OTP has been Resent!");
      })
      .catch((e) => console.log(e));
  };

  //Check whether user logged in or not
  const [user, setUser] = useState(false);
  useEffect(() => {
    // if (sessionStorage.getItem("token")) {
    //   setUser(true);
    // }

    if (sessionStorage.getItem("user_logged_in")) {
      setUser(true);
    }
  }, [user]);
  //Logout the user
  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_logged_in");
    sessionStorage.setItem("page-refreshed", JSON.stringify(false));
    setUser(false);
    props.history.push("/");
  };
  //MenU links on click get the which menu is clicked
  const clickedLink = (menu) => {
    props.callBackMenuClick(menu);
  };

  //Change MobileNumber Popup
  const [confirmMobileNumberChange, setconfirmMobileNumberChange] = useState(
    false
  );

  const [mobileNumberChangeStatus, setMobileNumberChangeStatus] = useState();
  const [
    mobileNumberChangeStatusMessage,
    setMobileNumberChangeStatusMessage,
  ] = useState("");
  const [
    displayMobileNumberChangeStatusPopup,
    setDisplayMobileNumberChangeStatusPopup,
  ] = useState(false);
  const [mobileNumberChangeMessage, setMobileNumberChangeMessage] = useState(
    ""
  );
  const [mobileNumberChangeIcon, setMobileNumberChangeIcon] = useState("");

  const cancelChangeMobileNumber = () => {
    setconfirmMobileNumberChange(false);
    setdisplayChangeMobilePopup(false);
    setDisplayMobileNumberChangeStatusPopup(false);
    setUserState("otp");
  };

  const [displayChangeMobilePopup, setdisplayChangeMobilePopup] = useState(
    false
  );
  const changeMobileNumber = () => {
    setdisplayChangeMobilePopup(true);
  };

  const [changemobileErr, setchangeMobileErr] = useState("");

  const validateChangeMobileNumberData = () => {
    setchangeMobileErr("");
    let mobile_no = localStorage.getItem("mobile_number");
    let change_mobile = document.getElementById("changeMobileNumber").value;

    let numbers = /^[0-9+-]*$/;
    if (change_mobile.length !== 10) {
      setchangeMobileErr("Please enter a valid phone number");
    } else {
      if (change_mobile.match(numbers)) {
        changeUserMobileNumber(
          mobile_no,
          change_mobile,
          compState,
          mobileNumberChangeStatus,
          setMobileNumberChangeMessage,
          setMobileNumberChangeIcon
        );
      } else {
        setchangeMobileErr("Please enter numbers only");
      }
    }
  };

  const checkMobilenumberChangeStatus = () => {
    let mobile_no = localStorage.getItem("mobile_number");
    fetchMobileNumberChangeStatus(
      mobile_no,
      compState,
      setMobileNumberChangeStatusMessage,
      setMobileNumberChangeStatus,
      setDisplayMobileNumberChangeStatusPopup,
      setconfirmMobileNumberChange
    );
  };

  const goToEnterMobilePopup = () => {
    setconfirmMobileNumberChange(false);
    setdisplayChangeMobilePopup(false);
    setMobileNumberChangeMessage("");
    setMobileNumberChangeIcon("");
    setUserState("login");
  };

  // Added for storing hotel_id of selected hotel in session

  useEffect(() => {
    sessionStorage.setItem("check_hotel_id", hotelId);
  }, [hotelId]);

  // Added for storing hotel_id of selected hotel in session

  const [themeColor, setThemeColor] = useState();

  useEffect(() => {
    setThemeColor(JSON.parse(sessionStorage.getItem("theme_color")));
  }, []);

  const options = {
    autoConfig: true,
    debug: false,
  };
  let facebook_pixel_data = JSON.parse(
    sessionStorage.getItem("facebook_pixel_data")
  );
  useEffect(() => {
    if (facebook_pixel_data && facebook_pixel_data.id) {
      ReactPixel.init(facebook_pixel_data.id, options);
      ReactPixel.pageView();
    }
  }, [facebook_pixel_data && facebook_pixel_data.id]);

  useEffect(() => {
    fetch(
      config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotel_Id
    )
      .then((res) => res.json())
      .then((resJson) => setShowPackageMenu(resJson.data.show_package_menu));
  }, []);

  return (
    <header>
      {!props.hideHeaderNFooter && (
        <nav className="nav-wrapper header-dark-strip">
          <SideBar
            showMenuItem={true}
            logout={logout}
            toggleDropdown={toggleDropdown}
            homeUrl={homeUrl}
            callBackMenuClick={clickedLink}
            pageWrapId={"page-wrap"}
            outerContainerId={"nav-wrapper"}
          />

          {/*Start Logo and Menu Wrapper */}
          <div className="container container-width header-menu-contents">
            <Logo callbackParent={getHomeUrl} />
            {/*Start Top Menu Bar */}
            <ul className="right hide-on-med-and-down main-nav ul-styles">
              <React.Fragment>
                {window.location.pathname !== "/user" && (
                  <li>
                    <a href={homeUrl}>Home</a>
                  </li>
                )}
                {props.forMainPage && (
                  <React.Fragment>
                    <li>
                      <Link
                        activeClass="active"
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={() => clickedLink("0")}
                      >
                        About
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        activeClass="active"
                        to="policies"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={() => clickedLink("1")}
                      >
                        Policies
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        activeClass="active"
                        to="hotel-map"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={() => clickedLink("2")}
                      >
                        Map
                      </Link>
                    </li>

                    <li>
                      <Link
                        activeClass="active"
                        to="addon-services"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={() => clickedLink("3")}
                      >
                        Add-on Services
                      </Link>
                    </li>

                    {/* {window.location.pathname === "/property" && ( */}
                    {window.location.pathname.includes(config.baseUrl + "property") && (
                      showPackageMenu === 1 && (
                        <li>
                          {/* <RouterLink to="/packages">Packages</RouterLink> */}
                          <RouterLink to={config.baseUrl + "packages"}>Packages</RouterLink>

                        </li>
                      )
                    )}
                    {/* {window.location.pathname === "/packages" && ( */}
                    {window.location.pathname.includes(
                      config.baseUrl + "packages"
                    ) && (
                        <li>
                          {/* <RouterLink to="/property">Rooms</RouterLink> */}
                          <RouterLink to={config.baseUrl + "property"}>
                            Rooms
                          </RouterLink>
                        </li>
                      )}

                    <li className="drop hotel-name-width">
                      <a href="#/">
                        {hotelList &&
                          hotelList.map((hotel) => {
                            if (hotel.hotel_id === hotelId)
                              return hotel.hotel_name
                                // .toLowerCase()
                                .split(" ")
                                .map(
                                  (name) =>
                                    name.charAt(0) +
                                    name.slice(1)
                                )
                                .join(" ");
                          })}
                      </a>
                      {hotelList.length > 1 && (
                        <div className="dropdownContain">
                          <div className="dropOut">
                            <div className="triangle" />
                            <ul className="scroll-bar-style">
                              {hotelList &&
                                hotelList.map((hotel, index) => (
                                  <li
                                    style={{ textTransform: "capitalize" }}
                                    onClick={() => setHotel(hotel.hotel_id)}
                                    className={
                                      hotel.hotel_id === hotelId ? "active" : ""
                                    }
                                    key={index}
                                  >
                                    {hotel.hotel_name}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      )}

                    </li>
                    <li className="drop">
                      <a href="#/">
                        <i className={currencySymbol} /> {currency}
                      </a>
                      <div className="dropdownContain">
                        <div className="dropOut">
                          <div className="triangle" />
                          <ul>
                            {currency_arr &&
                              currency_arr.map((loop_currency, index) => (
                                <li
                                  onClick={() =>
                                    setCurrencyNsymbol(
                                      loop_currency.name,
                                      loop_currency.symbol
                                    )
                                  }
                                  className={
                                    loop_currency.name === currency
                                      ? "active"
                                      : ""
                                  }
                                  key={index}
                                >
                                  <i className={loop_currency.symbol} />{" "}
                                  {" " + loop_currency.name}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                )}

                {!user && (
                  <li>
                    <a onClick={() => toggleDropdown()} href="#/">
                      Log In
                    </a>
                  </li>
                )}
                {user && (
                  <li className="drop">
                    <a href="#/">
                      <i className="fa fa-user-o" />
                    </a>
                    <div className="dropdownContain">
                      <div className="dropOut">
                        <div className="triangle" />
                        <ul>
                          {window.location.pathname !== "/user" && (
                            <a href={config.baseUrl + "user"}>
                              <i className="fa fa-user" /> My Account
                            </a>
                          )}

                          {window.location.pathname === "/user" && (
                            <a href={config.baseUrl + "property"}>
                              <i className="fa fa-home" />
                              Home
                            </a>
                          )}

                          <li onClick={() => logout()}>
                            <i className="fa fa-sign-out" />
                            Logout
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            </ul>
            {/*End Top Menu Bar */}
          </div>
          {/*End Logo and Menu Wrapper */}
        </nav>
      )}
      {/*End Navigation Bar Wrapper */}
      {/* <SimpleImageSlider
                width={window.screen.width}
                height={504}
                images={imagesToSlider}
            /> */}
      {props.forMainPage && (
        <React.Fragment>
          {header == false ? (
            <>
              {banner.length > 0 ? (
                <>
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    transitionTime={150}
                    dynamicHeight={true}
                  >
                    {banner.map(function (image, key) {
                      return (
                        <div key={key}>
                          <img
                            src={
                              config.public_image_url +
                              image.image_name.replace(" ", "+")
                            }
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </>
              ) : (
                <>
                  <div style={{ height: "300px" }}> </div>
                </>
              )}
            </>
          ) : (
            <>
              {banner.length > 0 ? (
                <div
                  className="headerBackground"
                  style={{
                    backgroundImage:
                      "url(" +
                      config.public_image_url +
                      bannerImage.replace(" ", "+"),
                  }}
                />
              ) : (
                <div style={{ height: "400px" }}> </div>
              )}
            </>
          )}

          {hotelList &&
            hotelList.map((h_item, h_id) => {
              if (h_item.hotel_id == hotelId) {
                return (
                  <div className="banner-section-hotel-name" key={h_id}>
                    <div className="hotel-name">{h_item.hotel_name}</div>
                  </div>
                );
              }
            })}

          <DatePicker callBackHeaderNav={callBackHeaderNav} />
        </React.Fragment>
      )}
      {/*Login Modal Structure */}
      {openDropdown && (
        <div className="modal-wrapper">
          <div
            id="login"
            className="modal scroll"
            style={{ background: "transparent" }}
          >
            <div className="modal-content">
              <div className="login-wrapper card z-depth-3">
                <span
                  className="modal-close waves-effect btnCloseRoom"
                  onClick={() => toggleDropdown()}
                />

                {/* <div className='row'> */}
                {!confirmMobileNumberChange &&
                  !displayMobileNumberChangeStatusPopup && (
                    <h3 className="center-align">
                      Please, login into your account
                    </h3>
                  )}
                {userState === "login" && !confirmMobileNumberChange && (
                  <React.Fragment>
                    <div className="row">
                      <div className="input-field col s12">
                        <div className="row">
                          <h4>Enter Mobile Number</h4>
                          <div className="col s5 m4">
                            <select
                              value={countryCode}
                              onChange={(e) => handleChange(e.target.value)}
                              className="browser-default"
                            >
                              {countryCodes &&
                                countryCodes.map((countryCode, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={countryCode.dial_code}
                                    >
                                      {countryCode.dial_code}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="col s7 m8">
                            <input
                              id="mobileNumber"
                              type="text"
                              className="validate"
                              onKeyDown={(e) => onEnterPress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <p
                      style={{ color: "red", fontWeight: "500" }}
                      className="center-align"
                    >
                      {mobileErr}
                    </p>

                    <div className="row">
                      <button
                        type="submit"
                        name="btn_login"
                        className="col s12 btn waves-effect apply-close"
                        onClick={() => validateData("send")}
                      >
                        Login
                      </button>
                    </div>
                  </React.Fragment>
                )}

                {userState === "otp" &&
                  !confirmMobileNumberChange &&
                  !displayMobileNumberChangeStatusPopup && (
                    <div className="row  mx-auto">
                      <div className="col center-align m12 ">
                        <p>{otpMsg}</p>
                        <PinInput
                          name="otp"
                          focus={true}
                          length={4}
                          initialValue=""
                          type="numeric"
                          inputMode="number"
                          style={{ padding: "10px 0", margin: "0 auto" }}
                          inputStyle={{
                            borderColor: themeColor && themeColor.theme_color,
                            margin: "2px",
                          }}
                          inputFocusStyle={{ borderColor: "green" }}
                          ref={(p) => (pin_values = p)}
                          onComplete={(value, index) => {
                            // setOtpValue(parseInt(value));
                            // this.initialValue = '';
                            const intValue = parseInt(value);
                            verifyOtp(intValue);
                          }}
                        />
                        {otpErr && (
                          <p style={{ color: "red", fontWeight: "500" }}>
                            {otpErr}
                          </p>
                        )}
                        <div className="row" />
                        <div className="row" />

                        <div className="row">
                          <div className="col s6 m6">
                            <button
                              type="submit"
                              name="btn_login"
                              className="col s12 btn waves-effect apply-close"
                              onClick={() => validateData("resend")}
                            >
                              Resend OTP
                            </button>
                          </div>
                          <div className="col s6 m6">
                            <button
                              type="submit"
                              name="btn_login"
                              className="col s12 btn waves-effect apply-close"
                              // onClick={() => setconfirmMobileNumberChange(true)}
                              onClick={() => checkMobilenumberChangeStatus()}
                            >
                              Change Number
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {/* MyCode */}

                {confirmMobileNumberChange && !displayChangeMobilePopup && (
                  <div className="row  mx-auto">
                    <div className="col center-align m12 ">
                      <p>{mobileNumberChangeStatusMessage}</p>
                      <p>
                        Are you sure you want to change your Mobile Number ?
                      </p>

                      <div className="row" />
                      <div className="row" />

                      <div className="row">
                        <div className="col s6 m6">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() => changeMobileNumber()}
                          >
                            Confirm
                          </button>
                        </div>

                        <div className="col s6 m6">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() => cancelChangeMobileNumber()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {displayMobileNumberChangeStatusPopup &&
                  !confirmMobileNumberChange && (
                    <div className="row  mx-auto">
                      <div className="col center-align m12 ">
                        <p>
                          You have exceeded maximum number of change attempts!
                        </p>
                        <div className="row" />
                        <div className="row" />

                        <div className="row">
                          <div className="col s12 m12">
                            <button
                              type="submit"
                              className="col s12 btn waves-effect apply-close"
                              onClick={() => cancelChangeMobileNumber()}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {displayChangeMobilePopup && !mobileNumberChangeMessage && (
                  <React.Fragment>
                    <div className="row">
                      <div className="input-field col s12">
                        <div className="row">
                          <h4>Enter Mobile Number</h4>
                          <div className="col s4 m3">
                            <select
                              value={countryCode}
                              onChange={(e) => handleChange(e.target.value)}
                              className="browser-default"
                            >
                              {countryCodes &&
                                countryCodes.map((countryCode, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={countryCode.dial_code}
                                    >
                                      {countryCode.dial_code}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="col s8 m9">
                            <input
                              id="changeMobileNumber"
                              type="text"
                              className="validate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{ color: "red", fontWeight: "500" }}
                      className="center-align"
                    >
                      {changemobileErr}
                    </p>

                    <div className="row">
                      <div className="col s6 m6">
                        <button
                          type="submit"
                          className="col s12 btn waves-effect apply-close"
                          onClick={() => validateChangeMobileNumberData()}
                        >
                          Save
                        </button>
                      </div>

                      <div className="col s6 m6">
                        <button
                          type="submit"
                          className="col s12 btn waves-effect apply-close"
                          onClick={() => cancelChangeMobileNumber()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {mobileNumberChangeMessage && (
                  <div className="row  mx-auto">
                    <div className="col center-align m12 ">
                      <div>
                        <i className={mobileNumberChangeIcon} />
                      </div>
                      <br />
                      <p>{mobileNumberChangeMessage}</p>

                      <div className="row" />
                      <div className="row" />

                      <div className="row">
                        <div className="col s12 m12">
                          <button
                            type="submit"
                            className="col s12 btn waves-effect apply-close"
                            onClick={() => goToEnterMobilePopup()}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* MyCode */}
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1230", display: "block", opacity: "0.5" }}
          />
        </div>
      )}
    </header>
  );
};
//export the HeaderNav
export default HeaderNav;
