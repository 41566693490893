import config from '../../../config';
import moment from 'moment';
const newBookRequest = (hotelId, dateRange, cart, apiKey, token) => {
  let url = config.apiEndpoint + 'package_booking/bookings/' + apiKey;
  let from_date = moment(dateRange.startDate).format('DD-MM-YYYY');
  let to_date = moment(dateRange.endDate).format('DD-MM-YYYY');
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'content-type': 'text/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify({
      hotel_id: hotelId,
      // from_date: from_date,
      // to_date: to_date,
      from_date: cart[0].checkin_date,
      to_date: cart[0].checkout_date,
      cart: cart,
      visitors_ip: '1.1.1.1',
    })
  })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.status == 1) {
        var invoice_id = btoa(responseJson.invoice_id);
        let str = window.location.origin;
        if (str.search('bookingjini.com') >= 0) {
          var url =
            'https://be.bookingjini.com/payment/' +
            invoice_id +
            '/' +
            responseJson.invoice_secureHash;
        } else {
          var url =
            window.location.origin +
            '/v3/api/payment/' +
            invoice_id +
            '/' +
            responseJson.invoice_secureHash;
        }
        window.location.assign(url);
      }
    });
};

export default newBookRequest;
