import config from "../../config";

const fetchNotificationPopup = (hotelId, setPopupOpen, setPopupContent) => {
  fetch(config.apiEndpoint + "be-notifications/" + hotelId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1 && responseJson.be_notifications) {
        if (responseJson.be_notifications.content_html) {
          setPopupOpen(true);
          setPopupContent(responseJson.be_notifications.content_html);
        } else {
          setPopupOpen(false);
        }
      } else {
        setPopupOpen(false);
      }
    })
    .catch((error) => {});
};

export default fetchNotificationPopup;
