import config from '../../config';
import moment from 'moment';
const fetchCalendar = async (
  hotelId,
  dateRange,
  currency,
  apiKey,
  setCalendarList,
  setCalendarLoaded
) => {
  let startDate = moment(dateRange.startDate).format('DD-MM-YYYY');
  fetch(
    config.apiEndpoint +
    'bookingEngine/be-calendar/' +
    apiKey +
    '/' +
    hotelId +
    '/' +
    startDate +
    '/' +
    currency,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.status === 1) {
        setCalendarList(responseJson.data);
        setCalendarLoaded(true);
      }
    })
    .catch(error => { });
};
//Export getAccess
export default fetchCalendar;
