import config from '../../../config';

const fetchHotelInfo = async (
    hotelId,
    apiKey,
    setHotelInfo,
    // setAdvanceDays,
    setOverSeaFilter,
    setCountryID,
    setIsTaxable
    // setGstNumberFilter, //-------gst and non gst calculation------------//
) => {
    fetch(
        config.apiEndpoint +
        'bookingEngine/get-hotel-info/' +
        apiKey +
        '/' +
        hotelId,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => response.json())
        .then(responseJson => {
            if (responseJson.status === 1) {
                setHotelInfo(responseJson.data);
                // setAdvanceDays(responseJson.data.advance_booking_days);
                setOverSeaFilter(responseJson.data.is_overseas)
                // setGstNumberFilter(responseJson.data.tax); //-------gst and non gst calculation------------//
                setCountryID(responseJson.data.country_id);
                setIsTaxable(responseJson.data.is_taxable)

                sessionStorage.setItem('facebook_pixel_data', JSON.stringify(responseJson.data.facebook_pixel));
                sessionStorage.setItem('google_tag_manager', JSON.stringify(responseJson.data.google_tag_manager));
            }
        })
        .catch(error => { });
};
//Export getAccess
export default fetchHotelInfo;
