import React, { useState } from 'react';
import Header from './Header/Header';
import Calendar from './Calendar/Calendar';
import PackagePanel from './Package/PackagePanel';
import Footer from './Footer/Footer';
import FairRatesComponent from './FareRates/FairRatesComponent';
import HotelInfo from './HotelInfo/HotelInfo';
const PackageComponent = props => {
  const [checkAvail, setCheckAvail] = useState(false);
  //Get CheckAvailability
  const getCheckAvail = checkAvail => {
    setCheckAvail(checkAvail);
  };
  const setNewCheckAvail = checkAvail => {
    setCheckAvail(checkAvail);
  };
  const [menuName, setMenuName] = useState(0); //Initial tab active
  //Get Menu Name Which clicked by user
  const getMenuName = menu => {
    setMenuName(menu);
  };
  return (
    <div>
      <Header
        history={props.history}
        callBackCheckAvail={getCheckAvail}
        callBackToBookingPanel={getMenuName}
      />
      {/* <FairRatesComponent /> */}
      <PackagePanel
        checkAvail={checkAvail}
        callBackToSetCheckAvail={setNewCheckAvail}
      />
      <HotelInfo menuIndex={menuName} />
      <Footer />
    </div>
  );
};
//Export the package Component
export default PackageComponent;
