import React, { useState, useEffect, useContext } from 'react';
import getPaidServices from "./getPaidServices";
import {
    HotelIdContext,
    AdvanceBookingContext,
    CurrencyContext,
    CurrencySymbolContext,
    BaseCurrencyContext,
} from "../../settings/Store";
import getCurrencyValue from "./getCurrencyValue";

const AddonServices = (props) => {
    const [hotelId] = useContext(HotelIdContext);
    const [currencySymbol] = useContext(CurrencySymbolContext);
    const [baseCurrency] = useContext(BaseCurrencyContext);
    const [currency] = useContext(CurrencyContext);
    const [advanceDays] = useContext(AdvanceBookingContext);

    let overSeaFilter = props.hotelInfo.is_overseas

    let isTaxable = props.hotelInfo.is_taxable


    const [currencyValue, setCurrencyValue] = useState(1);
    useEffect(() => {
        if (baseCurrency !== null && baseCurrency !== "" && currency !== null) {
            getCurrencyValue(currency, baseCurrency, setCurrencyValue);
        }
    }, [currency, baseCurrency, setCurrencyValue]);


    const [paidServices, setPaidServices] = useState([]);
    const [noPaidServices, setNoPaidServices] = useState();
    const [paidServicesLoader, setPaidServicesLoader] = useState()

    useEffect(() => {
        if (hotelId !== 0) {
            setPaidServicesLoader(true);
            getPaidServices(hotelId, setPaidServices, setNoPaidServices, setPaidServicesLoader);
        }
    }, [hotelId]);

    useEffect(() => {
        let display_amount = 0;
        for (var i = 0; i < paidServices.length; i++) {
            display_amount =
                currency !== baseCurrency
                    ? parseFloat(
                        (paidServices[i].service_amount * currencyValue).toFixed(2)
                    )
                    : paidServices[i].service_amount;
            paidServices[i]["display_amt"] = display_amount;
        }
    }, [paidServices, currency, currencyValue, baseCurrency, advanceDays]);

    return (
        <div className="addon-services-wrapper">

            {paidServicesLoader && <div className="row">
                <div className="addonservicesLoader">
                </div>
            </div>}

            {paidServicesLoader === false && <div>

                {paidServices &&
                    paidServices.length > 0 && (
                        <div
                            className="row center-align"
                        >
                            <div className={(isTaxable == 1) ? "col s12 m4" : "col s12 m6"}>
                                <p className="paid-service-headings-tab">Service Name</p>
                            </div>
                            <div className={(isTaxable == 1) ? "col s12 m4" : "col s12 m6"}>
                                <p className="paid-service-headings-tab">Service Amount</p>
                            </div>


                            {isTaxable == 1 && <div className="col s12 m4">
                                <p className="paid-service-headings-tab">Gst </p>
                            </div>}
                        </div>
                    )}

                {paidServices &&
                    paidServices.length > 0 &&
                    paidServices.map((paidService, index) => {
                        return (
                            <div
                                key={index}
                                className="row center-align"
                            >

                                <div className={(isTaxable == 1) ? "col s12 m4 paid-service-data-tab" : "col s12 m6 paid-service-data-tab"}>
                                    {paidService.service_name}
                                </div>
                                <div className={(isTaxable == 1) ? "col s12 m4 paid-service-data-tab" : "col s12 m6 paid-service-data-tab"}>
                                    <i className={currencySymbol} />

                                    {currency == baseCurrency ? (paidService.service_amount).toLocaleString('en-IN') : (paidService.service_amount * currencyValue).toFixed(2)}
                                </div>


                                {isTaxable == 1 && <div className="col s12 m4 paid-service-data-tab">
                                    {paidService.service_tax_price !== "-" ?
                                        <>
                                            <i className={currencySymbol} />
                                            {currency == baseCurrency ? (paidService.service_tax_price).toLocaleString('en-IN') : (paidService.service_tax_price * currencyValue).toFixed(2)} ({currency == baseCurrency ? (paidService.service_tax).toLocaleString('en-IN') : (paidService.service_tax * currencyValue).toFixed(2)} % )
                                        </> :
                                        <p>-</p>
                                    }
                                </div>}
                            </div>
                        );
                    })}

                {noPaidServices && (
                    <div className="row center-align">
                        <p> No Add-on Services available!</p>
                    </div>
                )}

            </div>}
        </div>
    )
}

export default AddonServices;