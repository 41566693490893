import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import config from '../../config';
const ExternalSlide = props => {
  let imagesToSlider = [];
  props.images &&
    props.images.length > 0 &&
    props.images.map(image => {
      imagesToSlider.push({
        url: config.public_image_url + image.image_name.replace(' ', '+')
      });
    });
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      // autoPlay
      // infiniteLoop={true}
      // transitionTime={150}
      dynamicHeight={true}>
      {props.images &&
        props.images.length > 0 &&
        props.images.map(function (image, i) {
          return (
            <div key={i}>
              <img
                src={
                  config.public_image_url + image.image_name.replace(' ', '+')
                }
              />
            </div>
          );
        })}
    </Carousel>

    // <div className="about-slider">
    //     <SimpleImageSlider
    //         width={'96%'}
    //         height={'94%'}
    //         images={imagesToSlider}
    //     />
    // </div>
  );
};

//Export the ImageSlider
export default ExternalSlide;
