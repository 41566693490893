import React, { useContext, useState, useEffect } from 'react';
import Cart from './Cart/Cart';
import Rooms from './Rooms/Rooms';
import { Snackbar } from '@material-ui/core';
const BookingPanel = props => {
  const [cart, setCart] = useState([]);
  // const [taxRules, setTaxRules] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState([]);
  const [roomsForCoupon, setRoomsForCoupon] = useState([]);
  const [appliedPublicCoupon, setAppliedPublicCoupon] = useState({});
  const [appliedPrivateCoupon, setAppliedPrivateCoupon] = useState({});
  const [roomDataUpdated, setRoomDataUpdated] = useState(false);
  const [allPublicApplied, setAllPublicApplied] = useState([]);

  const [popupOpen, setPopupOpen] = useState(true);
  //Coupon To add manullay
  const [cupon, setCupon] = useState([]);

  // const [appliedCoupons, setAppliedCoupons] = useState([]);

  //Set the values required to cart component
  // const getCart = (cart, taxRules, coupons, appliedCoupon) => {
  const getCart = (cart, coupons, appliedCoupon) => {
    setCart(cart);
    // console.log('Cart: ' + JSON.stringify(cart));
    // setTaxRules(taxRules);
    setCoupons(coupons);
    // console.log(coupons);
    setAppliedCoupon([appliedCoupon]);
  };
  // useEffect(() => {
  //   console.log('Private Coupon:' + JSON.stringify(appliedPrivateCoupon));
  // }, [appliedPrivateCoupon]);

  //Remove the cart Item From cart component
  const [removedCartItem, setRemovedCartItem] = useState({});
  const updateCartInRooms = cartItem => {
    let removeObject = {};
    Object.assign(removeObject, cartItem);
    setRemovedCartItem(removeObject);
    setRoomDataUpdated(Math.random());
  };
  // const updatePrivateCupon = cupon => {
  //   setPrivateCoupon(prevCupons => [...prevCupons, cupon]);
  // };
  const updateCupon = cupon => {
    // setCupon(cupon);

    setCupon(prevCupons => {
      return [prevCupons, cupon];
    });
    // setCoupons(cupon);
    // applyCouponToCart();
    // console.log(cupon);
  };

  const updatePublicCupon = cupon => {
    // console.log('Coupons' + JSON.stringify(coupons));
    // console.log('Coupon' + JSON.stringify(cupon));
    // setAppliedPublicCoupon(cupon);
    // setAppliedCoupon(cupon);
    // setCoupons([cupon]);
  };

  const applyCouponToCart = () => {
    const roomForCouponApply = cart.filter(
      cartItem => cartItem.room_type_id === appliedCoupon.room_type_id
    );
    // setRoomForCoupon(roomForCouponApply);
  };

  const setNewCheckAvail = checkAvail => {
    props.callBackToSetCheckAvail(checkAvail);
  };

  return (
    <section>
      <div className='container container-width top-margin'>
        <div className='row'>
          <Cart
            cart={cart}
            callbackCart={updateCartInRooms}
            callbackCupon={updateCupon}
            // taxRulesObject={taxRules}
            appliedCoupon={appliedCoupon}
            coupons={coupons}
            setAppliedCoupon={setAppliedCoupon}
            callBackToSetCheckAvail={setNewCheckAvail}
            checkAvail={props.checkAvail}
            appliedPublicCoupon={appliedPublicCoupon}
            updatePublicCupon={updatePublicCupon}
          // roomDataUpdated={roomDataUpdated}
          />

          <Rooms
            callBackGetBePrice={price => props.callBackGetBePrice(price)}
            addToCartCallBack={getCart}
            cuponFromCart={cupon}
            removedCartItem={removedCartItem}
            callBackToSetCheckAvail={setNewCheckAvail}
            checkAvail={props.checkAvail}
            // appliedCoupon={appliedCoupon}
            // setAppliedCoupon={setAppliedCoupon}
            setAppliedPublicCoupon={setAppliedPublicCoupon}
            setRoomDataUpdated={setRoomDataUpdated}
            roomDataUpdated={roomDataUpdated}
            calendarLoaded={props.calendarLoaded}
            setAllPublicApplied={setAllPublicApplied}
          // setAppliedCoupons={setAppliedCoupons}
          // couponApplyRoom={roomForCouponApply}
          />
        </div>
      </div>
    </section>
  );
};
//export BookingPanel class
export default BookingPanel;
