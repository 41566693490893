import config from '../config';

const getAccess = async (
  company_url,
  setCompState,
  setCompHash,
  setApiKey,
  setChatOption,
  setHotelId,
  setCurrency,
  hotelId,
  comp,
  setNotFound,

  setPriceWidget,

  // Refund Protect
  setOptedForRefund,
  // Refund Protect

  setGuestPermission,
  setThemeColor
) => {
  const queryString = window.location.search.replace("?q=", "");
  let data;
  if (queryString) {
    try {
      // Try decoding the query string, if fails catch the error and skip to avoid breaking app
      data = atob(decodeURI(queryString));
    } catch (e) {
      console.error(`Failed to decode the URI: ${e}`);
    }
  }

  let url_hotel_id = null;

  if (data) {
    let queryStringData = data.split("|");
    if (queryStringData.length >= 3) {
      url_hotel_id = parseInt(queryStringData[2].trim())
      // fetchHotelInfo(url_hotel_id, apiKey, setHotelInfo);
    }
  }


  fetch(config.apiEndpoint + 'bookingEngine/auth/' + company_url, {
    method: 'GET'

  })
    .then(response => response.json())
    .then(responseJson => {

      setCompState(responseJson.data.company_id);
      setCompHash(responseJson.data.comp_hash);
      setApiKey(responseJson.data.api_key);
      setChatOption(responseJson.data.chat_option);

      if (hotelId === 0) {
        if (url_hotel_id) setHotelId(url_hotel_id)
        else
          setHotelId(responseJson.data.hotel_id)
      }


      setCurrency(responseJson.data.currency);

      setPriceWidget(responseJson.data.is_widget_active);

      // Refund Protect
      setOptedForRefund({ status: responseJson.data.refundable_cancelation_status, mode: responseJson.data.refundable_cancelation_mode })
      // Refund Protect

      setGuestPermission({ modify: 0, cancel: 0 });

      // if (responseJson.data.be_header_color) {
      //   setThemeColor({ ...themeColor, header_bgcolor: responseJson.data.be_header_color })
      // }

      // if (responseJson.data.be_theme_color) {
      //   setThemeColor({ ...themeColor, theme_color: responseJson.data.be_theme_color })
      // }


      // if (responseJson.data.be_menu_color) {
      //   setThemeColor({ ...themeColor, web_menu_color: responseJson.data.be_menu_color, mobile_menu_color: responseJson.data.be_menu_color })
      // }

      setThemeColor({ header_bgcolor: responseJson.data.be_header_color, theme_color: responseJson.data.be_theme_color, web_menu_color: responseJson.data.be_menu_color, mobile_menu_color: responseJson.data.be_menu_color, mobile_header_bgcolor: responseJson.data.be_header_color })

    })
    .catch(error => {
      setNotFound(true);
    });
};
//Export getAccess
export default getAccess;
