import React, { useState } from "react";
const NotFoundComponent = (props) => {
  let company_url = window.location.origin;
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
        </div>
        <h2>404 - Page not found</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <a href={company_url}>Go To Homepage</a>
      </div>
    </div>
  );
};

export default NotFoundComponent;
