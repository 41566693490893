import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import fetchCalendar from './fetchCalendar';
import moment from 'moment';
import getCurrencyValue from './../BookingPanel/Cart/getCurrencyValue';
import {
  HotelIdContext,
  DateRangeContext,
  ApiKeyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  CurrencyContext
} from '../../settings/Store';
import CalenderLoader from './CalenderLoader';
import fetchPublicCoupons from './fetchPublicCoupons'
const Calendar = props => {
  const [hotelId] = useContext(HotelIdContext);
  const [dateRange] = useContext(DateRangeContext);
  const [currency] = useContext(CurrencyContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [calendarList, setCalendarList] = useState([]); //Initialization
  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);

  const [publicCoupons, setPublicCoupons] = useState([]);
  const [displayCalendarList, setDisplayCalendarList] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  //API CALL to fetch hotels
  useEffect(() => {
    if (hotelId !== 0 && currency !== '' && apiKey !== '') {
      props.setCalendarLoaded(false)
      fetchCalendar(
        hotelId,
        dateRange,
        currency,
        apiKey,
        setCalendarList,
        props.setCalendarLoaded
      );
      // setCalendarLoading(false)
    }
  }, [hotelId, dateRange, currency, apiKey, setCalendarList]);

  //After Check availability click API CALL
  useEffect(() => {
    if (props.checkAvail) {
      setCalendarList([]);
      // console.log('Hello');
    }
    props.callBackToSetCheckAvail(false);
  }, [props.checkAvail, props.callBackToSetCheckAvail, dateRange]);

  useEffect(() => {

    if (hotelId !== 0 && currency !== '' && apiKey !== '' && props.checkAvail) {
      fetchCalendar(hotelId, dateRange, currency, apiKey, setCalendarList);
    }
  }, [hotelId, dateRange, currency, apiKey, setCalendarList, props.checkAvail]);
  //Get the currency value
  const [currencyValue, setCurrencyValue] = useState(1);

  useEffect(() => {

    if (baseCurrency !== null && baseCurrency !== '' && currency !== null) {
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency, setCurrencyValue]);

  useEffect(() => {

    if (calendarList && calendarList.length > 0) {
      let start_date = calendarList[0].date;
      let end_date = calendarList[calendarList.length - 1].date

      fetchPublicCoupons(hotelId, setPublicCoupons, start_date, end_date, dateRange);

    }
  }, [hotelId, dateRange, currency, apiKey, calendarList, setPublicCoupons]);


  const [themeColor, setThemeColor] = useState();

  useEffect(() => {
    setThemeColor(JSON.parse(sessionStorage.getItem('theme_color')));
  }, [sessionStorage.getItem('theme_color')])


  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 7,
    speed: 500
  };

  const activeHead = {
    color: '#ffffff',
    background: themeColor && themeColor.theme_color,
    fontWeight: '400',
    margin: '0',
    padding: '0',
    paddingTop: '2%'
  };
  const zeroPrice = {
    color: '#ffffff',
    background: 'red',
    fontWeight: '400',
    margin: '0',
    padding: '0',
    paddingTop: '2%'
  };

  const activePara1 = {
    color: themeColor && themeColor.theme_color,
    fontWeight: '400'
  };

  const activePara2 = {
    color: themeColor && themeColor.theme_color
  };

  useEffect(() => {

    // if (calendarList && calendarList.length > 0) {
    //   if (publicCoupons && publicCoupons.length > 0) {
    //     calendarList.map((calendar, index) => {
    //       publicCoupons.map((coupons, idx) => {
    //         if (calendar.date == coupons.date && calendar.room_type_id == coupons.room_type_id) {
    //           let price = calendar.price;
    //           let dis_price = (calendar.price * coupons.discount) / 100;
    //           let display_price = price - dis_price;
    //           calendar.price = display_price.toFixed(0);
    //         }

    //         if (calendar.date == coupons.date && coupons.room_type_id == 0) {
    //           let price = calendar.price;
    //           let dis_price = (calendar.price * coupons.discount) / 100;
    //           let display_price = price - dis_price;
    //           if (display_price !== 0) {
    //             calendar.price = display_price.toFixed(0);
    //           }
    //           else {
    //             calendar.price = display_price;
    //           }
    //         }

    //       })
    //     })
    //     setDisplayCalendarList(calendarList);
    //   }
    //   else {
    //     setDisplayCalendarList(calendarList);
    //   }
    // }
    setDisplayCalendarList(calendarList);


  }, [publicCoupons])

  return (
    <section className='hide-on-med-and-down'>
      <div className='container container-width top-margin'>
        {displayCalendarList.length === 0 || !props.calendarLoaded ? (
          <div className='fare-calendar clearfix'>
            <CalenderLoader />
          </div>
        ) : null}
        {displayCalendarList.length > 0 && props.calendarLoaded ? (
          <div className='row'>
            <div className='fare-calendar clearfix'>
              <Slider {...settings}>
                {displayCalendarList &&
                  displayCalendarList.map((calendar, index) => (
                    <div key={index} className='calendar-day'>
                      {moment(dateRange.startDate).isSame(
                        moment(calendar.date),
                        'days'
                      ) && (
                          <h3
                            style={calendar.price === 0 ? zeroPrice : activeHead}>
                            {moment(calendar.date).format('DD MMM YYYY')}
                          </h3>
                        )}
                      {!moment(dateRange.startDate).isSame(
                        moment(calendar.date),
                        'days'
                      ) &&
                        calendar.price === 0 && (
                          <h3 style={zeroPrice}>
                            {moment(calendar.date).format('DD MMM YYYY')}
                          </h3>
                        )}
                      {!moment(dateRange.startDate).isSame(
                        moment(calendar.date),
                        'days'
                      ) &&
                        calendar.price !== 0 && (
                          <h3>{moment(calendar.date).format('DD MMM YYYY')}</h3>
                        )}
                      {calendar.price !== 0 && (
                        <p
                          style={
                            moment(dateRange.startDate).isSame(
                              moment(calendar.date),
                              'days'
                            )
                              ? activePara1
                              : {}
                          }>
                          From
                        </p>
                      )}
                      {calendar.price === 0 && (
                        <p
                          style={
                            moment(dateRange.startDate).isSame(
                              moment(calendar.date),
                              'days'
                            )
                              ? activePara1
                              : {}
                          }>
                          &nbsp;
                        </p>
                      )}
                      <p
                        style={
                          moment(dateRange.startDate).isSame(
                            moment(calendar.date),
                            'days'
                          )
                            ? activePara2
                            : {}
                        }>
                        {calendar.price !== 0 && (
                          <i className={currencySymbol} />
                        )}{' '}
                        {calendar.price === 0 && (
                          <span style={{ color: 'red', fontWeight: '400' }}>
                            Not Available
                          </span>
                        )}
                        {calendar.price !== 0 &&
                          (currency == baseCurrency
                            ? parseInt((calendar.price)).toLocaleString('en-IN')
                            : parseFloat(
                              (calendar.price * currencyValue).toFixed(2)
                            ))}
                        <span />
                      </p>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

//Export the Calendar
export default Calendar;
