import config from '../../config';
const fetchInvoice= async (invoice_id,setInvocie)=>{
  fetch(config.apiEndpoint +'bookingEngine/invoice-details/' +invoice_id,{
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      }
      }).then((response) => response.json())
      .then((responseJson) => {
            if(responseJson.status===1)
            {
                let invoice=responseJson.data.replace(/\*/g,' ');
                setInvocie(invoice);
            }
      })
  .catch((error) => {
  });
}

//Export getAccess 
export default fetchInvoice;