import config from "../../../config";
import { BeUrlContext } from "../../../settings/Store";
import { useContext } from "react";

const userSignup = (
  userState,
  expiry_date,
  dob,
  setToken,
  callBackFromSignup
) => {
  let comp = window.location.origin;

  // These are some url of hotels for working in local dev
  // let comp = "https://silversandhotels.bookingjini.com";

  let company_url =
    comp.search("https") > -1
      ? comp.replace("https://", "")
      : comp.replace("http://", "");
  let nameArr = userState.name.split(" ");
  let firstName = nameArr[0];
  let lastName = "";
  if (nameArr.length > 1) {
    for (let i = 1; i < nameArr.length; i++) {
      lastName += nameArr[i] + " ";
      // lastName = " " + nameArr[i];
    }
  } else {
    lastName = "NA";
  }

  var expiry_date = expiry_date;
  var dob = dob;
  let url = config.apiEndpoint + "user/register";
  fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      company_url: company_url,
      email_id: userState.emailId,
      mobile: userState.mobile,
      first_name: firstName,
      last_name: lastName,
      company_name: userState.companyName ? userState.companyName : "",
      GST_IN: userState.GSTIN ? userState.GSTIN : "",
      address: userState.address,
      zip_code: userState.zipcode,
      country: userState.countryName,
      state: userState.state,
      city: userState.city,
      identity: userState.identity,
      identity_no: userState.identityno,
      expiry_date: expiry_date,
      date_of_birth: dob,
      GSTIN: "",
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status == 1) {
        sessionStorage.setItem(
          "debug",
          JSON.stringify({
            userState: userState,
            firstName: firstName,
            lastName: lastName,
          })
        );
        if (setToken) {
          setToken(responseJson.auth_token);
          callBackFromSignup(responseJson.auth_token);
        }
      }
    });
};

//Export Default userSignup
export default userSignup;
