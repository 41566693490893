import config from "../../config";
const fetchUserInfo = async (
  hotelId,
  filteredValue,
  setUserData,
  setLastPage,
  page,
  setButtonArray,
  setDisplayLoader,
  compState
) => {

  let token = sessionStorage.getItem("token");
  let buttonArray = [];
  fetch(config.apiEndpoint + "public_user/fetch_booking_details?page=" + page, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },

    body: JSON.stringify({
      token: token,
      hotel_id: hotelId,
      date: filteredValue,
      company_id: compState
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        setUserData(responseJson.details.data);
        setLastPage(responseJson.details.last_page);
        for (let i = 1; i <= responseJson.details.last_page; i++) {
          buttonArray.push(i);
        }
        setButtonArray(buttonArray);
        setDisplayLoader(false);
      } else {
        setDisplayLoader(false);
      }
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
};
//Export getAccess
export default fetchUserInfo;
