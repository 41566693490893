import React, { useContext, useState, useEffect } from "react";
import "../../css/landing.page.css";
import fetchHotels from "./fetchHotels";
import config from "../../config";
import {
  CompanyContext,
  CompHashContext,
  HotelIdContext,
} from "../../settings/Store";
import HotelListLoader from "./HotelListLoader";
import NotFoundComponent from "../NotFoundComponent";
// import ReactTooltip from 'react-tooltip';

const HotelListPanel = (props) => {
  //Use context to get the global Company id and Comp hash
  const [compState] = useContext(CompanyContext);
  const [compHash] = useContext(CompHashContext);
  const [hotelId, setHotelId] = useContext(HotelIdContext);
  const [hotelList, setHotelList] = useState(null);

  const [allCities, setAllCities] = useState();
  const [cityFilter, setCityFilter] = useState();
  //API CALL to fetch hotels
  useEffect(() => {
    if (compHash !== "" && compState !== 0) {
      fetchHotels(compHash, compState, setHotelList, setAllCities);
    }
  }, [compHash, compState, setHotelList]);
  //Update About section close
  const [aboutStatus, setAboutStatus] = useState([]);
  useEffect(() => {
    let status = {};
    if (hotelList) {
      hotelList.map((hotel) => {
        aboutStatus[hotel.hotel_id] = false;
        Object.assign(aboutStatus, { [hotel.hotel_id]: false });
      });
      Object.assign(status, aboutStatus);
      setAboutStatus(aboutStatus);
    }
  }, [hotelList]);
  //Redirect to property route if company have one hotel
  useEffect(() => {
    if (hotelList && hotelList.length == 1) {
      props.history.push(config.baseUrl + "property");
    }
  }, [hotelList, props.history]);
  //About Block display
  const toggleAbout = (hotel_id) => {
    let localStatus = {};
    if (aboutStatus[hotel_id] !== undefined) {
      aboutStatus[hotel_id] = !aboutStatus[hotel_id];
    }
    Object.assign(localStatus, aboutStatus);
    setAboutStatus(localStatus);
  };
  //Redirect
  const redirectToProperty = (selected_hotelId) => {
    if (selected_hotelId !== hotelId) {
      setHotelId(selected_hotelId);
    }
    props.history.push(config.baseUrl + "property");
  };

  const changeCity = (value) => {
    if (value) {
      setCityFilter(parseInt(value))
    }
    else {
      setCityFilter('');
    }
  }


  return (
    <section>
      <div className="hotel-list-wrapper">
        {/*Start Hotel Listing Page */}
        <div className="container container-width top-margin p-4">
          {/*First row listing */}
          <div className="row ">
            {!hotelList && <HotelListLoader />}
            {hotelList && hotelList.length === 0 && <NotFoundComponent />}


            {hotelList && hotelList.length && allCities && allCities.length > 0 &&
              <div className="row filter-booking-months">
                <div className="col m8" />
                <div className="col s12 m4">
                  <select
                    onChange={(e) => changeCity(e.target.value)}

                  >
                    <option value=''>All Cities ({hotelList.length})</option>
                    {allCities.map((item, i) => (
                      <option value={item.city_id} key={i}>{item.city_name} ({item.no_of_hotel})</option>
                    ))}
                  </select>
                </div>
              </div>}


            {/*Hotel list wrapper */}
            {hotelList &&
              hotelList.length > 0 && !cityFilter &&
              hotelList.map((hotel, index) => (
                <div key={index}>
                  <div className="col s12 m4">
                    <div
                      className="card z-depth-3 hotel-listing-min-height">
                      <div className="card-image waves-effect waves-block waves-light">
                        <img
                          className="activator"
                          src={hotel.exterior_image.replace(" ", "+")}
                        />
                      </div>

                      <div className="card-content hotel-listing-card">
                        {hotel.total_star_property && hotel.total_star_property.length > 0 && hotel.total_star_property.map((item, idx) => (
                          <i className="fa fa-star property-star" aria-hidden="true" key={idx}></i>
                        ))}
                        <span className="card-title activator grey-text text-darken-4">
                          {hotel.hotel_name}
                          <i
                            className="material-icons right"
                            onClick={() => toggleAbout(hotel.hotel_id)}
                          >
                            more_vert
                          </i>
                        </span>

                        <p className="tooltip-wrapper tooltip t-top" data-tooltip-text={hotel.hotel_address}><i className="fa fa-map-marker" aria-hidden="true" /> {hotel.hotel_address.substr(0, 25)}...</p>

                      </div>

                      <div className="card-action center-align">
                        <button
                          onClick={() => redirectToProperty(hotel.hotel_id)}
                          className="waves-effect waves-light btn btn-book"
                        >
                          Book Now
                        </button>
                      </div>
                      {aboutStatus[hotel.hotel_id] && (
                        <div
                          className="card-reveal"
                          style={{
                            display: "block",
                            transform: "translateY(-100%)",
                          }}
                        >
                          <span className="card-title grey-text text-darken-4">
                            About Hotel
                            <i
                              className="material-icons right"
                              onClick={() => toggleAbout(hotel.hotel_id)}
                            >
                              close
                            </i>
                          </span>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: hotel.hotel_description,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {(index + 1) % 3 === 0 && <div className="clearfix" />}
                </div>
              ))}


            {hotelList &&
              hotelList.length > 0 && cityFilter &&
              hotelList.map((hotel, index) => {
                if (hotel.city_id === cityFilter) {
                  return (
                    <div key={index}>
                      <div className="col s12 m4">
                        <div
                          className="card z-depth-3 hotel-listing-min-height">
                          <div className="card-image waves-effect waves-block waves-light">
                            <img
                              className="activator"
                              src={hotel.exterior_image.replace(" ", "+")}
                            />
                          </div>

                          <div className="card-content hotel-listing-card">
                            {hotel.total_star_property && hotel.total_star_property.length > 0 && hotel.total_star_property.map((item, idx) => (
                              <i className="fa fa-star property-star" aria-hidden="true" key={idx}></i>
                            ))}
                            <span className="card-title activator grey-text text-darken-4">
                              {hotel.hotel_name}
                              <i
                                className="material-icons right"
                                onClick={() => toggleAbout(hotel.hotel_id)}
                              >
                                more_vert
                              </i>
                            </span>

                            <p className="tooltip-wrapper tooltip t-top" data-tooltip-text={hotel.hotel_address}><i className="fa fa-map-marker" aria-hidden="true" /> {hotel.hotel_address.substr(0, 25)}...</p>

                          </div>

                          <div className="card-action center-align">
                            <button
                              onClick={() => redirectToProperty(hotel.hotel_id)}
                              className="waves-effect waves-light btn btn-book"
                            >
                              Book Now
                            </button>
                          </div>
                          {aboutStatus[hotel.hotel_id] && (
                            <div
                              className="card-reveal"
                              style={{
                                display: "block",
                                transform: "translateY(-100%)",
                              }}
                            >
                              <span className="card-title grey-text text-darken-4">
                                About Hotel
                                <i
                                  className="material-icons right"
                                  onClick={() => toggleAbout(hotel.hotel_id)}
                                >
                                  close
                                </i>
                              </span>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: hotel.hotel_description,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}


          </div>
        </div>
        {/*End Hotel Listing Page  */}
      </div>
      {/*End Hotel List Wrapper */}
    </section>
  );
};

//Export the HotelListPanel
export default HotelListPanel;
