import React, { useState, useEffect, useContext } from "react";
import fetchInvoice from "./fetchInvoice";
import fetchInvoiceData from "./fetchInvoiceData";
// import Script from "react-inline-script";
import { ApiKeyContext, BeUrlContext, HotelIdContext } from "../../settings/Store";
import svgImage from '../../svg/thankyou_page_image.svg'
import config from "../../config";
import ReactPixel from 'react-facebook-pixel';
import fetchHotelInformation from "./fetchHotelInformation";
import ReactGaTagManager from 'react-gtm-module';

const thankYouComponent = (props) => {
    const [invoice, setInvoice] = useState("");
    const [invoiceData, setInvoiceData] = useState("");
    const [googletagManagerData, setGoogletagManagerData] = useState("");

    const [facebookPixelData, setFacebookPixelData] = useState({});

    const [beUrl] = useContext(BeUrlContext);
    const [hotelId] = useContext(HotelIdContext);
    const [apiKey] = useContext(ApiKeyContext);

    useEffect(() => {
        if (props.match.params.invoice_id != 0) {
            fetchInvoice(props.match.params.invoice_id, setInvoice);
            fetchInvoiceData(props.match.params.invoice_id, setInvoiceData);
        }
    }, [props.match.params.invoice_id]);
    useEffect(() => {
        if (localStorage.getItem("google-ads") == "google") {
            localStorage.removeItem("google-ads");
            window.location.assign("https://bookingjini.com/thankyou-page/");
        }
    }, []);

    const downloadDetails = () => {
        const invoiceBase64 = btoa(props.match.params.invoice_id);
        window.open(`${config.gemsApiEndPoint}getBookingVoucherPDF/${invoiceBase64}`);
    };

    useEffect(() => {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage("success")
    }, [])

    useEffect(() => {
        if (hotelId != "" && apiKey != 0) {
            fetchHotelInformation(hotelId, apiKey, setFacebookPixelData);
        }
    }, [hotelId, apiKey]);


    useEffect(() => {
        if (invoiceData && invoiceData.length > 0 && facebookPixelData) {

            if (facebookPixelData && facebookPixelData.purchase) {
                let paid_amount = parseFloat(invoiceData[0].paid_amount);
                ReactPixel.fbq('track', 'Purchase', { value: paid_amount, currency: invoiceData[0].currency_code });

            }

        }
    }, [invoiceData, facebookPixelData])

    useEffect(() => {
        if (invoiceData && invoiceData.length > 0) {
            fetchBookingDetilsforGoogleTagManager(invoiceData[0].booking_id);
        }
    }, [invoiceData])

    const fetchBookingDetilsforGoogleTagManager = async (booking_id) => {
        fetch(
            config.apiEndpoint + 'extranetv4/bookings/get-booking-details/' + booking_id,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'text/json',
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 1) {
                    setGoogletagManagerData(responseJson.data);

                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    // Access value of google tag id stored in session
    let google_tag_id = sessionStorage.getItem("google_tag_id");

    useEffect(() => {
        if (googletagManagerData.room_data) {
            iniTiateGoogleTagManager();
        }
    }, [googletagManagerData.room_data])


    const iniTiateGoogleTagManager = () => {
        // custom cart details for google tag 
        // const arrayGaItem = googletagManagerData.room_data.map((item, index) => {
        //     return (
        //         {
        //             item_id: item.room_type_id,
        //             item_name: item.room_type,
        //             affiliation: googletagManagerData.hotel_name,
        //             coupon: '',
        //             discount: googletagManagerData.discount,
        //             index: index,
        //             item_brand: '',
        //             item_category: '',
        //             item_category2: '',
        //             item_category3: '',
        //             item_category4: '',
        //             item_category5: '',
        //             item_list_id: '',
        //             item_list_name: '',
        //             item_variant: '',
        //             location_id: '',
        //             price: item.price,
        //             quantity: item.no_of_rooms,
        //         }
        //     )
        // })

        // const tagManagerArgs = {
        //     gtmId: google_tag_id,
        //     dataLayer: {
        //         category: 'Ecommerce',
        //         action: 'Received',
        //         transaction_id: googletagManagerData.invoice_id,
        //         value: googletagManagerData.price,
        //         tax: googletagManagerData.tax_amount,
        //         shipping: 0,
        //         currency: googletagManagerData.currency_name,
        //         coupon: '',
        //         items: arrayGaItem,
        //     }
        // };

        const arrayGaItem = googletagManagerData.room_data.map((item, index) => {
            return (
                {
                    item_id: item.room_type_id,
                    item_name: item.room_type,
                    affiliation: googletagManagerData.hotel_name,
                    coupon: '',
                    discount: googletagManagerData.discount,
                    index: index,
                    item_brand: 'The Byke',
                    item_category: 'All Rooms',
                    price: item.price,
                    quantity: item.no_of_rooms,
                }
            )
        })

        const tagManagerArgs = {
            gtmId: google_tag_id,
            dataLayer: { ecommerce: null },  // Clear the previous ecommerce object.
            dataLayer: {
                event: "purchase",
                ecommerce: {
                    transaction_id: googletagManagerData.invoice_id,
                    value: googletagManagerData.price,
                    tax: googletagManagerData.tax_amount,
                    currency: googletagManagerData.currency_name,
                    coupon: '',
                    items: arrayGaItem,
                },
            }
        };

        ReactGaTagManager.initialize(tagManagerArgs);
    }

    return (
        <>
            <div className="row">
                {invoiceData && invoiceData[0].ref_from == "google" ? (
                    <img
                        height="1"
                        width="1"
                        border="0"
                        alt=""
                        src={`https://www.googletraveladservices.com/travel/clk/pagead/conversion/831703831/?label=HPA&guid=ON&script=0&ord=$$random$$&data=hct_partner_hotel_id%3D${invoiceData[0].hotel_id
                            }%3Bhct_base_price%3D${invoiceData[0].paid_amount
                            }%3Bhct_total_price%3D${invoiceData[0].total_amount
                            }%3Bhct_currency_code%3D${invoiceData[0].currency_code
                            }%3Bhct_checkin_date%3D${invoiceData[0].checkin_date
                            }%3Bhct_checkout_date%3D${invoiceData[0].checkout_date
                            }%3Bhct_length_of_stay%3D${invoiceData[0].length_of_stay
                            }%3Bhct_date_format%3D%Y-%m-%d%3Bhct_booking_xref%3D${invoiceData[0].ref_no
                            }%3Bhct_ver%3D1.0.i`}
                    />
                ) : (
                    ""
                )}
            </div>


            {invoiceData && invoiceData.length > 0 && <div className="booking__successful__wrapper">
                <div className="booking__successful__padding">
                    <div className="header__wrapper">
                        <div className="transaction__id">Txn ID - {invoiceData[0].booking_id}</div>
                        <div>
                            <div className="amount">Total Amount : <i className={invoiceData[0].currency_symbol} /> {invoiceData[0].total_price}</div>
                            <div className="amount">Paid Amount : <i className={invoiceData[0].currency_symbol} /> {invoiceData[0].total_amount}</div>
                        </div>
                    </div>
                </div>

                <div className="align__center__div">
                    <div className="logo__wrapper">
                        <img src={config.public_image_url + invoiceData[0].image_name.replace(' ', '+')} alt="Hotel Logo" />
                    </div>
                </div>

                <div className="align__center__div booking__successful__padding">
                    <div className="booking__successful">
                        <div className="success__circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-check" viewBox="0 0 16 16">
                                <path
                                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                            </svg>
                        </div>
                        <div className="success__text">Booking successful</div>
                    </div>

                    <div className="thank__you__text">Thank you for choosing {invoiceData[0].hotel_name}.</div>

                    <div className="button__wrapper">
                        <div className="btn__print__pdf" onClick={() => downloadDetails()}>Print PDF</div>
                        <div className="btn__booking__engine" onClick={() => { window.location.assign(beUrl) }}>Go to Booking Engine</div>
                        <div className="btn__go__home" onClick={() => { window.location.assign(localStorage.getItem("homeUrl")) }}>Go to Home</div>
                    </div>

                    <div className="bottom__image__placeholder">
                        <img src={svgImage} alt="Hotel reception image" />
                    </div>
                </div>
            </div>}
        </>
    );
};

//Export the component
export default thankYouComponent;
