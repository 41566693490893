import React, { useState, useEffect, useContext } from 'react';
import parse from 'html-react-parser';
// importing image for About tab
import ExternalImageSlide from './externalImageSlide';

import config from '../../config';
import AboutLoader from './aboutLoader';
import tripAdvisor from '../../img/tripadvisor-logotype.png';
import whatsapp from '../../img/whatsapp.png';
import holidayIq from '../../img/holidayIq.png';
import Amenities from './Amenities';
import { HotelIdContext } from '../../settings/Store';
const hotelAbout = props => {
  // Fetching data from API
  let regex1 = /(&nbsp;|<strong>|<\/strong>|↵↵|↵|)/gm;
  let hotel_name = props.hotelInfo.hotel_name;
  let airport_name = props.hotelInfo.airport_name;
  let rail_station_name = props.hotelInfo.rail_station_name;
  let hotel_description = props.hotelInfo.hotel_description;
  let land_line = props.hotelInfo.land_line;
  let star_rating = props.hotelInfo.star_of_property;
  const [hotelId] = useContext(HotelIdContext);
  const [amenitiesWithCategory, setAmenitiesWithCategory] = useState([]);
  const [amenitiesFromBackEnd, setAmenitiesFromBackEnd] = useState([]);
  const [isLoaded, setIsloaded] = useState(false);

  useEffect(() => {
    const url = config.apiEndpoint + 'get-amenities?hotel_id=' + hotelId;
    fetch(url, { method: 'POST' })
      .then(res => res.json())
      .then(resJson => {
        // console.log('Amenities:' + resJson.amenities);
        setAmenitiesFromBackEnd(resJson.amenities);
        createAmenitiesArray(resJson.amenities);
      });
  }, [amenitiesWithCategory]);

  const createAmenitiesArray = amenities => {
    if (!isLoaded) {
      const formattedAmenities = [];
      for (const key in amenities) {
        if (amenities.hasOwnProperty(key)) {
          // const element = object[key];
          const amenity = {};
          amenity.category = key;
          switch (key) {
            case 'F&B':
              amenity.icon = 'fa fa-cutlery';

              break;
            case 'Facility':
              amenity.icon = 'fa fa-futbol-o';
              break;
            case 'Front office':
              amenity.icon = 'fa fa-info-circle';
              break;
            case 'House Keeping':
              amenity.icon = 'fa fa-shower';
              break;
            case 'Recreation':
              amenity.icon = 'fa fa-plus-circle';
              break;
            case 'Room Facility':
              amenity.icon = 'fa fa-life-ring';
              break;
            case 'Room services':
              amenity.icon = 'fa fa-check-circle-o';
              break;
            case 'Services':
              amenity.icon = 'fa fa-star';
              break;

            default:
              amenity.icon = 'fa fa-cutlery';
              break;
          }
          amenity.amenities = amenities[key];
          formattedAmenities.push(amenity);
        }
      }

      setAmenitiesWithCategory(formattedAmenities);
      setIsloaded(true);
    }
    // console.log('HEllo');
  };
  // Striping html tags using regex expression
  var regex = /(<([^>]+)>)/gi;
  let about_hotel = hotel_description + '';
  const removeAllHtmlTags = str => {
    // console.log('test string:' + str);
    if (str === null || str === '') return false;
    if (str.trim() == '.') return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, '').trim();
  };
  about_hotel = about_hotel.replace(/\&nbsp;/g, ''); //Removes the &nbsp
  const hotelImage =
    props.hotelInfo.exterior_image &&
    config.public_image_url +
      props.hotelInfo.exterior_image[0].image_name.replace(' ', '+');
  // Uppercase text to Capitalize
  hotel_name = hotel_name + ''.toLowerCase();

  function htmlToReact(hotel_policy_value) {
    return parse(hotel_policy_value);
  }
  let checkIn = props.hotelInfo.check_in + '';
  let checkOut = props.hotelInfo.check_out + '';
  // console.log('Here');
  // let hotel_policy = props.hotelInfo.hotel_policy + '';
  // console.log(hotel_policy);

  const email_id_arr =
    props.hotelInfo.email_id && props.hotelInfo.email_id.split(',');
  const mobile_arr =
    props.hotelInfo.mobile && props.hotelInfo.mobile.split(',');
  // Function convert first character to uppercase
  function titleCase(string) {
    return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
  }

  //Set Image Slider is open Or Not
  //Intially Image Slider is Open false
  const [isSliderOpen, setSliderOpen] = useState(false);

  const removeHtmlAndTrim = input => {};

  const hotel_amenities = props.hotelInfo.facility;
  let amenities;
  if (hotel_amenities) {
    amenities = hotel_amenities.map(a => a.hotel_amenities_name);
  }

  let tourist_places = (props.hotelInfo.nearest_tourist_places + '').replace(
    regex1,
    ''
  );

  let imp_tourist_places = (props.hotelInfo.tour_info + '').replace(regex1, '');

  let tourist_places1 = (props.hotelInfo.nearest_tourist_places + '')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  let imp_tourist_places1 = (props.hotelInfo.tour_info + '')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  let tripadvisor_link = (props.hotelInfo.tripadvisor_link + '').trim();
  let holiday_iq_link = (props.hotelInfo.holiday_iq_link + '').trim();
  let whatsapp_no = props.hotelInfo.whatsapp_no + '';
  const removeSpaces = input => {
    const output = input.replace(/&nbsp;/g, '').trim();
    return output;
  };
  //Show Image slider
  const toggleSlider = () => {
    setSliderOpen(!isSliderOpen);
  };
  return (
    // Start about us section
    <div className='col s12'>
      <div className='tab-width'>
        <div className='row'>
          <div className='about-us-wrapper'>
            {typeof props.hotelInfo.hotel_name === 'undefined' && (
              <AboutLoader />
            )}
            {typeof props.hotelInfo.hotel_name === 'string' && (
              <div>
                <div className='row'>
                  {hotel_name && <h2>About {hotel_name && hotel_name}</h2>}
                  <div className='col s12 m4'>
                    <div className='card'>
                      <div
                        className='card-image'
                        onClick={() => toggleSlider()}>
                        {hotelImage && (
                          <img
                            className='responsive-img'
                            src={hotelImage}
                            alt='Image about room'
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* External Image Slide */}
                  {isSliderOpen && (
                    <div className='modal-wrapper'>
                      <div id='model1' className='modal scroll z-value'>
                        <div
                          className='card'
                          style={{
                            marginTop: '0rem',
                            marginBottom: '0rem',
                            border: 'none'
                          }}>
                          <div className='imgSlider'>
                            <span
                              onClick={() => toggleSlider()}
                              style={{ zIndex: '3400' }}
                              className='modalBtnClose'
                            />
                            <ExternalImageSlide
                              images={props.hotelInfo.exterior_image}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className='modal-overlay'
                        style={{
                          zIndex: '1201',
                          display: 'block',
                          opacity: '0.3'
                        }}
                      />
                    </div>
                  )}
                  {/* End of External Image Slide */}

                  <div className='col s12 m8'>
                    <div className='card'>
                      {about_hotel && (
                        <span
                          dangerouslySetInnerHTML={{ __html: about_hotel }}
                        />
                      )}
                    </div>
                    <br />

                    <div className='row'>
                      <div className='col s12 m6'>
                        <p className='flow-text'>
                          <i className='material-icons icon'>email</i>{' '}
                          {email_id_arr && email_id_arr[0]}
                        </p>
                        {email_id_arr &&
                          email_id_arr.length > 2 &&
                          email_id_arr[1] && (
                            <p className='flow-text'>
                              <i className='material-icons icon'>email</i>{' '}
                              {email_id_arr &&
                                email_id_arr.length > 2 &&
                                email_id_arr[1]}
                            </p>
                          )}
                      </div>

                      {mobile_arr && (
                        <div className='col s12 m6'>
                          <p className='flow-text'>
                            <i className='material-icons icon'>contact_phone</i>{' '}
                            {mobile_arr && mobile_arr[0]}
                            {mobile_arr && mobile_arr[1] && ','}{' '}
                            {mobile_arr && mobile_arr[1]}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='row'>
                      <div className='col s12 m6'>
                        {airport_name && (
                          <p className='flow-text'>
                            <i className='material-icons icon'>local_airport</i>{' '}
                            {titleCase(airport_name)}
                            <span className='distance-span'>
                              ({props.hotelInfo.distance_from_air} K.M)
                            </span>
                          </p>
                        )}
                      </div>

                      {removeAllHtmlTags(rail_station_name) && (
                        <div className='col s12 m6'>
                          <p className='flow-text'>
                            <i className='material-icons icon'>train</i>
                            {titleCase(rail_station_name)}
                            {props.hotelInfo.distance_from_rail && (
                              <span className='distance-span'>
                                (
                                {removeAllHtmlTags(
                                  props.hotelInfo.distance_from_rail
                                )}{' '}
                                K.M)
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className='row'>
                      {checkIn && (
                        <div className='col s12 m6'>
                          <p>
                            <i className='material-icons icon'>alarm_on</i>{' '}
                            Check In :{'  '}
                            <React.Fragment>
                              {checkIn.length === 8
                                ? checkIn.slice(0, 5)
                                : checkIn}
                            </React.Fragment>
                          </p>
                        </div>
                      )}
                      {checkOut && (
                        <div className='col s12 m6'>
                          <p>
                            <i className='material-icons icon'>alarm_off</i>{' '}
                            Check Out :{'  '}
                            <React.Fragment>
                              {checkOut.length === 8
                                ? checkOut.slice(0, 5)
                                : checkOut}
                            </React.Fragment>
                          </p>
                        </div>
                      )}
                    </div>
                    {(star_rating || land_line) && (
                      <div className='row'>
                        {!!star_rating && (
                          <div className='col s12 m6'>
                            <p>
                              <i className='material-icons icon'>star</i> Rating
                              :{'  '}
                              <React.Fragment>{star_rating}</React.Fragment>
                            </p>
                          </div>
                        )}

                        {land_line && (
                          <div className='col s12 m6'>
                            <p className='flow-text'>
                              <i className='material-icons icon'>
                                phone_in_talk
                              </i>
                              {'  '} {land_line}
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    <div className='row'>
                      <div className='col s12 m6'>
                        {props.hotelInfo.nearest_tourist_places &&
                          tourist_places1 &&
                          props.hotelInfo.nearest_tourist_places !==
                            '<p><br></p>' &&
                          removeSpaces(tourist_places) && (
                            <p className='flow-text'>
                              <i className='material-icons icon'>
                                location_city
                              </i>{' '}
                              Nearest Tourist Places : <br />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tourist_places
                                }}
                              />
                            </p>
                          )}
                      </div>
                      <div className='col s12 m6'>
                        {props.hotelInfo.tour_info &&
                          imp_tourist_places1 &&
                          props.hotelInfo.tour_info !== '<p><br></p>' &&
                          removeSpaces(imp_tourist_places) && (
                            <p className='flow-text'>
                              <i className='material-icons icon'>camera_roll</i>{' '}
                              Important Tourist Places : <br />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: imp_tourist_places
                                }}
                              />
                            </p>
                          )}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col'>
                        {amenitiesFromBackEnd && amenitiesFromBackEnd != [] && (
                          <div>
                            <p style={{ fontWeight: '500' }}>
                              <i className='material-icons icon'>
                                room_service
                              </i>{' '}
                              Hotel Amenities :
                              <br />
                              {/* {amenities.map((a, i) => (
                                <React.Fragment key={i}>
                                  {a}
                                  {i < amenities.length - 1 && ','}{' '}
                                </React.Fragment>
                              ))} */}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className='col col-sm-12'>
                        <Amenities amenitiesList={amenitiesWithCategory} />
                      </div> */}

                    {amenitiesFromBackEnd &&
                      amenitiesFromBackEnd != [] &&
                      amenitiesWithCategory != [] &&
                      amenitiesWithCategory.map((amenity, index) => {
                        return (
                          <div className='row' key={index}>
                            {amenitiesFromBackEnd && (
                              <div
                                className='col'
                                style={{ marginLeft: '25px' }}>
                                <p
                                  style={{
                                    fontSize: '0.91rem',
                                    fontWeight: '400'
                                  }}>
                                  <span>
                                    <i className={amenity.icon} />
                                    &nbsp; {amenity.category}{' '}
                                  </span>{' '}
                                  :
                                </p>
                                <span style={{ fontSize: '0.9rem' }}>
                                  {amenity.amenities.map((a, i, am) => {
                                    return i < am.length - 1
                                      ? a.trim() + ', '
                                      : a.trim();
                                  })}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    <div className='row'>
                      <div className='col m6 s12'>
                        {((tripadvisor_link && tripadvisor_link !== '') ||
                          (holiday_iq_link && holiday_iq_link !== '')) && (
                          <div>
                            <p style={{ display: 'flex' }}>
                              <i
                                className='material-icons icon'
                                style={{ marginRight: '5px' }}>
                                feedback
                              </i>{' '}
                              Feedback Links:
                              {tripadvisor_link &&
                                tripadvisor_link !== '' && (
                                  <a href={tripadvisor_link}>
                                    <img
                                      src={tripAdvisor}
                                      width='35'
                                      style={{ marginLeft: '10px' }}
                                      alt='trip advisor'
                                    />
                                  </a>
                                )}{' '}
                              {holiday_iq_link && holiday_iq_link !== '' && (
                                <a href={holiday_iq_link}>
                                  <img
                                    width='35'
                                    src={holidayIq}
                                    alt='holiday iq'
                                    style={{ marginLeft: '10px' }}
                                  />
                                </a>
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div
                        className='col m6 s12'
                        style={{
                          display: 'flex'
                          // justifyContent: 'space-between'
                          // alignItems: 'center'
                        }}>
                        {whatsapp_no && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                            <img width='35' src={whatsapp} alt='whatsapp' />
                            &nbsp;
                            <span style={{ fontWeight: '400' }}>
                              {whatsapp_no}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    // End about us section
  );
};
//Export About Section
export default hotelAbout;
