import config from '../../../config';

const fetchPackageDetails = (package_id, setPackageDetails) => {
    if (package_id) {
        let url = config.apiEndpoint + 'package_booking/package_details/' + package_id
        fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then(responseJson => {
                if (responseJson.status === 1) {
                    setPackageDetails(responseJson.data)
                }
            })
    }
}
//Export Default
export default fetchPackageDetails
