import React, { useContext, useState, useEffect } from 'react';
import config from '../../../../config';
import { CompanyContext, ApiKeyContext } from '../../../../settings/Store';
const Logo = props => {
  const [compState] = useContext(CompanyContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [logo, setLogo] = useState('');
  const [homeUrl, companyHomeurl] = useState('');
  //Get Logo and Home URL
  const getLogo = async (apiKey, compState, callbackParent) => {
    fetch(
      config.apiEndpoint +
      'bookingEngine/get-hotel-logo/' +
      apiKey +
      '/' +
      compState,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(responseJson => {
        companyHomeurl(responseJson.data.home_url);
        // console.log('Response Data ' + Object.entries(responseJson.data));
        localStorage.setItem('homeUrl', responseJson.data.home_url);
        setLogo(responseJson.data.logo);
        callbackParent(responseJson.data.home_url, responseJson.data.banner);
      })
      .catch(error => {
        console.log('Error :' + error);
      });
  };
  //API CALL
  useEffect(() => {
    if (compState != 0 && apiKey != '') {
      getLogo(apiKey, compState, props.callbackParent);
    }
  }, [apiKey, compState]);
  //Return the JSX
  return (
    <div className="header-nav-wrapper">
      <a href={homeUrl} className="tab-screen">
        <img src={config.public_image_url + logo.replace(' ', '+')} alt='Bookingjini Logo' />
      </a>

      {/* {logo && (
        <a href={homeUrl} className='brand-logo logo tab-screen'>
          <img
            src={config.public_image_url + logo.replace(' ', '+')}
            alt='Bookingjini Logo'
          />
        </a>
      )} */}
    </div>
  );
};
//export the logo
export default Logo;
