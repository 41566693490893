//App configuration goes here
const config = {

  //*******Production URL******//
  apiEndpoint: "https://be.bookingjini.com/",
  public_image_url: "https://d3ki85qs1zca4t.cloudfront.net/bookingEngine/",
  kEndpoint: "https://kernel.bookingjini.com/",
  gemsApiEndPoint: "https://gems.bookingjini.com/api/",
  baseUrl: "/",
};
export default config;
