import config from '../../../config';
const fetchRoomDetails = async (
  hotel_id,
  api_key,
  room_type_id,
  setRoomDetails
) => {
  let url =
    config.apiEndpoint +
    'bookingEngine/get-room-info/' +
    api_key +
    '/' +
    hotel_id +
    '/' +
    room_type_id;
  // console.log('Room Type Id:' + room_type_id);
  fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'text/json'
    }
  })
    .then(response => response.json())
    .then(responsejson => {
      //   console.log('Room Info: ' + responsejson.data.description);
      for (let key in responsejson.data) {
        const value = responsejson.data[key];
        // console.log(key + '  :' + value);
      }
      if (responsejson.status === 1) {


        let selected_amenities = [];
        let display_amenities = [];
        if (responsejson.data && responsejson.data.room_amenities.length > 0) {
          responsejson.data.room_amenities.map((item) => {
            selected_amenities.push(item.hotel_amenities_id)
          })
        }


        let url = config.kEndpoint + '/hotel_amenities/all';
        fetch(url, { method: 'GET' })
          .then(res => res.json())
          .then(resJson => {

            for (let i = 0; i < resJson.amenities.length; i++) {
              for (let j = 0; j < resJson.amenities[i].amenity_name.length; j++) {
                if (selected_amenities.includes(resJson.amenities[i].amenity_name[j].hotel_amenities_id)) {
                  display_amenities.push({ hotel_amenities_name: resJson.amenities[i].amenity_name[j].hotel_amenities_name });
                }
              }
            }

            responsejson.data.display_room_amenities = display_amenities

            setRoomDetails(responsejson.data);
          });
      }
    });
};
//Export the getTaxRules
export default fetchRoomDetails;
