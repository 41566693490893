import React, { useContext, useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import Collapsible from "react-collapsible";
import fetchHotels from "../../HotelListPanel/fetchHotels";
import getBaseCurrency from "../../HotelListPanel/getBaseCurrency";
import {
  CompanyContext,
  CompHashContext,
  HotelIdContext,
  CurrencyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
} from "../../../settings/Store";
import userLogin from "../userLogin";
import config from "../../../config";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

export default (props) => {
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      right: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#ffffff",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#ffffff",
      padding: "10% 8%",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };
  //Use context to get the global Company id and Comp hash and hotel id context
  const [compState] = useContext(CompanyContext);
  const [compHash] = useContext(CompHashContext);
  const [hotelId, setHotelId] = useContext(HotelIdContext);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [currencySymbol, setCurrencySymbol] = useContext(CurrencySymbolContext);
  const [hotelList, setHotelList] = useState([]);
  const [baseCurrency, setBaseCurrency] = useContext(BaseCurrencyContext);
  const [token, setToken] = useState([]);
  const [homeUrl, setHomeUrl] = useState("");
  //API CALL to fetch hotels
  useEffect(() => {
    if (compHash !== "" && compState !== 0) {
      fetchHotels(compHash, compState, setHotelList);
    }
  }, [compHash, compState, setHotelList]);

  //Get the base Currency
  useEffect(() => {
    if (compHash !== "" && currency !== "") {
      getBaseCurrency(compState, setBaseCurrency);
    }
  }, [compState, setBaseCurrency, currency]);

  //Currency Array
  const currency_arr = [
    { name: "INR", symbol: "fa fa-inr" },
    { name: "USD", symbol: "fa fa-usd" },
    { name: "EUR", symbol: "fa fa-eur" },
    { name: "GBP", symbol: "fa fa-gbp" },
  ];
  const setCurrencyNsymbol = (currency_name, symbol) => {
    setCurrency(currency_name);
    setCurrencySymbol(symbol);
  };
  //Set the Hotel
  const setHotel = (hotel_id) => {
    setHotelId(hotel_id);
  };
  //Check whether user logged in or not
  const [user, setUser] = useState(false);
  useEffect(() => {

    // if (sessionStorage.getItem("token")) {
    //   setUser(true);
    // }

    if (sessionStorage.getItem("user_logged_in")) {
      setUser(true);
    }
  }, [user]);
  //MenU links on click get the which menu is clicked
  const clickedLink = (menu) => {
    props.callBackMenuClick(menu);
  };
  //Set Side bar open or not
  const [isMenuOpen, setIsMenuOpen] = useState();
  //Callback to parent to open the login popup
  const callBackParent = () => {
    setIsMenuOpen(!isMenuOpen);
    props.toggleDropdown();
  };
  const isMenuOpenStae = function (state) {
    setIsMenuOpen(state.isOpen);
  };
  return (
    // Pass on our props
    <Menu
      onStateChange={isMenuOpenStae}
      isOpen={isMenuOpen}
      styles={styles}
      width={"280px"}
      right
    >
      <a className="menu-item mobile-menu-name" href={props.homeUrl}>
        Home
      </a>
      {props.showMenuItem && (
        <div>
          <div className="menu-item" href="#">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => clickedLink("0")}
            >
              <span className="mobile-menu-name">About</span>
            </Link>
          </div>
          <div className="menu-item" href="#">
            <Link
              activeClass="active"
              to="policies"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => clickedLink("1")}
            >
              <span className="mobile-menu-name">Policies</span>
            </Link>
          </div>
          <div className="menu-item" href="#">
            <Link
              activeClass="active"
              to="hotel-map"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => clickedLink("2")}
            >
              <span className="mobile-menu-name">Map</span>
            </Link>
          </div>
          {window.location.pathname === "/property" && (
            <div>
              <RouterLink to="/packages" className="mobile-menu-name">
                Packages
              </RouterLink>
            </div>
          )}
          {window.location.pathname === "/packages" && (
            <div>
              <RouterLink to="/property" className="mobile-menu-name">
                Rooms
              </RouterLink>
            </div>
          )}

          <Collapsible
            trigger={
              hotelList &&
              hotelList.map((hotel) => {
                if (hotel.hotel_id === hotelId)
                  return hotel.hotel_name
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (name) =>
                        name.charAt(0).toUpperCase() +
                        name.slice(1).toLowerCase()
                    )
                    .join(" ");
              }) + "➕"
            }
          >
            {hotelList &&
              hotelList.map((hotel, index) => (
                <p
                  style={{ textTransform: "capitalize" }}
                  onClick={() => setHotel(hotel.hotel_id)}
                  className={hotel.hotel_id === hotelId ? "active" : ""}
                  key={index}
                >
                  {hotel.hotel_name.toLowerCase()}
                </p>
              ))}
          </Collapsible>

          <Collapsible trigger={currency + "➕"}>
            {currency_arr &&
              currency_arr.map((loop_currency, index) => (
                <p
                  onClick={() =>
                    setCurrencyNsymbol(loop_currency.name, loop_currency.symbol)
                  }
                  className={loop_currency.name === currency ? "active" : ""}
                  key={index}
                >
                  <i className={loop_currency.symbol} />{" "}
                  {" " + loop_currency.name}
                </p>
              ))}
          </Collapsible>
        </div>
      )}
      {!user && (
        <a className="menu-item mobile-menu-name" onClick={() => callBackParent()} href="#">
          Login
        </a>
      )}
      {user && (
        <Collapsible trigger="Account ➕">
          <a className="mobile-menu-name" href={config.baseUrl + "user"}>
            <i className="fa fa-user" /> My Account
          </a>
          <br />
          <a
            className="mobile-menu-name"
            onClick={() => props.logout && props.logout()}
          >
            <i className="fa fa-sign-out" />
            Logout
          </a>
        </Collapsible>
      )}
    </Menu>
  );
};
