import React from 'react';
import parse from 'html-react-parser';
const hotePolicies = props => {
  //Fetching data from API and string conversion
  // let hotel_policy =
  //   props.hotelInfo.hotel_policy + ' ' + props.hotelInfo.cancel_policy;
  let hotel_policy = props.hotelInfo.hotel_policy + '';
  let cancel_policy = props.hotelInfo.cancel_policy + '';
  let terms_and_cond = props.hotelInfo.terms_and_cond + '';
  let hotel_child_policy = props.hotelInfo.child_policy + '';

  let regex = /(&nbsp;|<strong>|<\/strong>|↵↵|↵|)/gm;

  //stripping html tags
  const hotel_policy_stripped = hotel_policy
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  const cancel_policy_stripped = cancel_policy
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  const terms_and_cond_stripped = terms_and_cond
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  const hotel_child_policy_stripped = hotel_child_policy
    .replace(/(<([^>]+)>)/gi, '')
    .replace(regex, '')
    .trim();
  // let policy = hotel_policy.replace(regex, '');
  // let hotel_terms = terms_and_cond.replace(regex, '');

  //Parsing html tags and data
  function htmlToReact(hotel_policy_value) {
    return parse(hotel_policy_value);
  }
  // Start

  return (
    // Start policies section
    <div id='tab-policies' className='col s12'>
      <div className='tab-width'>
        <div className='row'>
          <div className='policies-wrapper'>
            <div className='row'>
              <div className='col s12 m12'>
                <div className='row'>
                  <h2>Policies</h2>
                </div>
                {cancel_policy_stripped && cancel_policy_stripped != 'null' && (
                  <div className='row'>
                    <div className='col s12 m4'>
                      <p
                      // onClick={() =>
                      //   console.log(cancel_policy, typeof cancel_policy)
                      // }
                      >
                        <b>Cancellation Policy</b>{' '}
                      </p>
                    </div>

                    <div className='col s12 m8'>
                      {/* Parsing api string data */}
                      <React.Fragment>
                        {htmlToReact(cancel_policy)}
                      </React.Fragment>
                    </div>
                  </div>
                )}
                {hotel_child_policy_stripped &&
                  hotel_child_policy_stripped != '' && (
                    <div className='row'>
                      <div className='col s12 m4'>
                        <p>
                          <b>Child Policy</b>{' '}
                        </p>
                      </div>

                      <div className='col s12 m8'>
                        {/* Parsing api string data */}
                        <React.Fragment>
                          {htmlToReact(hotel_child_policy)}
                        </React.Fragment>
                      </div>
                    </div>
                  )}

                {hotel_policy_stripped &&
                  hotel_policy_stripped != '<p><br></p>\n' && (
                    <div className='row'>
                      <div className='col s12 m4'>
                        <p>
                          <b>Privacy Policy</b>{' '}
                        </p>
                      </div>

                      <div className='col s12 m8'>
                        {/* Parsing api string data */}
                        <React.Fragment>
                          {htmlToReact(hotel_policy)}
                        </React.Fragment>
                      </div>
                    </div>
                  )}
                {terms_and_cond_stripped && (
                  <div className='row'>
                    <div className='col s12 m4'>
                      <p>
                        <b>Terms and conditions</b>{' '}
                      </p>
                    </div>

                    <div className='col s12 m8'>
                      {/* Parsing api string data */}
                      <React.Fragment>
                        {htmlToReact(terms_and_cond)}
                      </React.Fragment>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // End policies section
  );
};

export default hotePolicies;
