import React from 'react'

const HotelListLoader=()=>{
    return(
        <div>
            <div className="row">
                <div className="col s12 m4">
                    <div className="hotelListLoader">
                    </div>
                </div>

                <div className="col s12 m4">
                    <div className="hotelListLoader">
                    </div>
                </div>

                <div className="col s12 m4">
                    <div className="hotelListLoader">
                    </div>
                </div>
            </div>
        </div>
    )
}

//Export hoterListLoader
export default HotelListLoader