import React, { useState, useEffect } from 'react';
import Logo from './HeaderNav/Logo/Logo';
import userLogin from './userLogin.js';
import config from '../../config';
import SideBar from './HeaderNav/Sidebar';
import countryCodes from '../BookingPanel/Cart/countryCodes.json';
const loadData = () => JSON.parse(JSON.stringify(countryCodes));
const HeaderLanding = props => {
  const [homeUrl, setHomeUrl] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [token, setToken] = useState([]);
  const [banner, setBanner] = useState('');
  //Set the Home Url
  const getHomeUrl = (homeUrl, banner) => {
    setHomeUrl(homeUrl);
    setBanner(banner);
  };
  //Toggle Dropdown
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  //Country code
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState('+91');
  useEffect(() => {
    setCountryCodes(loadData());
  }, [setCountryCodes]);
  const handleChange = value => {
    setCountryCode(value);
  };
  //Function to validate input data and call API
  let mobileNo;
  const [mobileErr, setMobileErr] = useState('');
  const validateData = () => {
    setMobileErr('');
    let mobile = document.getElementById('mobileNumber').value;
    var letters = /^[A-Za-z]+$/;
    if (mobile.length != 10) {
      setMobileErr('Please enter a valid phone number');
    } else if (mobile.match(letters)) {
      setMobileErr('Please enter numbers only');
    } else {
      mobileNo = mobile.replace(/[^\d]/g, '');
      //Already Logged in
      if (sessionStorage.getItem('token')) {
        redirectToUser(sessionStorage.getItem('token'));
      } else {
        userLogin(setToken, mobileNo, redirectToUser);
      }
    }
  };
  //After Auth Redirect User
  const redirectToUser = token => {
    sessionStorage.setItem('token', token);
    setUser(true);
    props.history.push(config.baseUrl + 'user');
  };
  //Check whether user logged in or not
  const [user, setUser] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setUser(true);
    }
  }, [user]);
  //Logout the user
  const logout = () => {
    sessionStorage.removeItem('token');
    setUser(false);
    props.history.push('/');
  };
  return (
    <div>
      {/* <div style={{backgroundImage:"linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url("+config.apiEndpoint+'public/'+banner+")"}} className="landing-page-style"> */}
      <nav className='nav-wrapper header-dark-strip'>


        {/* <SideBar
          showMenuItem={false}
          logout={logout}
          toggleDropdown={toggleDropdown}
          homeUrl={homeUrl}
          pageWrapId={'page-wrap'}
          outerContainerId={'nav-wrapper'}
        /> */}



        {/*Start Logo and Menu Wrapper */}
        <div className='container container-width'>
          {/* <Logo callbackParent={homeUrl} /> */}
          <Logo callbackParent={getHomeUrl} />
          {/*Start Top Menu Bar */}



          {/* <ul className='right hide-on-med-and-down main-nav'>
            <li>
              <a href={homeUrl}>Home</a>
            </li>
            {!user && (
              <li>
                <a onClick={() => toggleDropdown()} href='#/'>
                  Log In
                </a>
              </li>
            )}
            {user && (
              <li className='drop'>
                <a href='#/'>
                  <i className='fa fa-user-o' />
                </a>
                <div className='dropdownContain'>
                  <div className='dropOut userLogin'>
                    <div className='triangle' />
                    <ul>
                      <a href={config.baseUrl + 'user'}>
                        <i className='fa fa-user' /> My Account
                      </a>
                      <li onClick={() => logout()}>
                        <i className='fa fa-sign-out'>Logout</i>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            )}
          </ul> */}





          {/*End Top Menu Bar */}
        </div>
        {/*End Logo and Menu Wrapper */}
      </nav>
      {/*Login Modal Structure */}
      {openDropdown && (
        <div className='modal-wrapper'>
          <div
            id='login'
            className='modal scroll'
            style={{ background: 'transparent' }}>
            <div className='modal-content'>
              <div className='login-wrapper card z-depth-3'>
                <span
                  className='modal-close waves-effect btnCloseRoom'
                  onClick={() => toggleDropdown()}
                />
                <div className='row'>
                  <h3 className='center-align'>
                    Please, login into your account
                  </h3>
                  <div className='input-field col s12'>
                    <div className='row'>
                      <h4>Mobile Number</h4>
                      <div className='col s4 m3'>
                        <select
                          value={countryCode}
                          onChange={e => handleChange(e.target.value)}
                          className='browser-default'>
                          {countryCodes &&
                            countryCodes.map((countryCode, index) => {
                              return (
                                <option
                                  key={index}
                                  value={countryCode.dial_code}>
                                  {countryCode.dial_code}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className='col s8 m9'>
                        <input
                          id='mobileNumber'
                          type='text'
                          className='validate'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p style={{ color: 'red' }}>{mobileErr}</p>
                <div className='row'>
                  <button
                    type='submit'
                    name='btn_login'
                    className='col s12 btn waves-effect apply-close'
                    onClick={() => validateData()}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className='modal-overlay'
            style={{ zIndex: '1002', display: 'block', opacity: '0.5' }}
          />
        </div>
      )}
    </div>
  );
};
//export the HeaderLanding
export default HeaderLanding;
