import config from "../../../config";

const fetchMobileNumberChangeStatus = (
  mobile_no,
  company_id,
  setMobileNumberChangeStatusMessage,
  setMobileNumberChangeStatus,
  setDisplayMobileNumberChangeStatusPopup,
  setconfirmMobileNumberChange
) => {
  fetch(
    config.apiEndpoint +
      "public_user/fetch_mobile_number_change_status/" +
      mobile_no +
      "/" +
      company_id,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (
        responseJson.status === 1 &&
        responseJson.user_details.change_number_status < 5
      ) {
        setMobileNumberChangeStatus(
          responseJson.user_details.change_number_status
        );
        setMobileNumberChangeStatusMessage(
          "You have " +
            (5 - responseJson.user_details.change_number_status) +
            " attempts left!"
        );
        setDisplayMobileNumberChangeStatusPopup(false);
        setconfirmMobileNumberChange(true);
      } else {
        setMobileNumberChangeStatusMessage(
          "Sorry you have exceeded the maximum change requests."
        );
        setDisplayMobileNumberChangeStatusPopup(true);
        setconfirmMobileNumberChange(false);
      }
    })
    .catch((error) => {});
};

export default fetchMobileNumberChangeStatus;
