import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFmaXpiaiIsImEiOiJja2s4NDBxMXAwaXB3MzNudjNkZDUxenlwIn0.GU3mEuuyxqczV8tqZWFSAA";

const styles = {
  width: "100%",
  height: "54vh",
};

const HotelMapBox = (props) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    if (props && props.hotelInfo) {
      let lat = parseFloat(props.hotelInfo.latitude);
      let lng = parseFloat(props.hotelInfo.longitude);

      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [lng, lat],
          zoom: 13,
        });

        map.on("load", () => {
          setMap(map);
          map.resize();
        });

        var marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
      };

      if (!map) initializeMap({ setMap, mapContainer });
    }
  }, [props, map]);

  return (
    <div
      style={{
        height: "60vh",
        width: "100%",
        padding: "2%",
        border: "1px solid #aaa",
        borderTop: "none",
      }}
    >
      <div ref={(el) => (mapContainer.current = el)} style={styles} />
    </div>
  );
};

export default HotelMapBox;
