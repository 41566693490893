import config from '../../config';
const fetchHotels = async (comp_hash, company_id, setHotelList, setAllCities) => {
    fetch(config.apiEndpoint + 'hotel_admin/hotels_by_company/' + comp_hash + '/' + company_id, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }).then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {
                let city_filter_data = [];
                let city_name = [];
                responseJson.data.map((item) => {
                    let star_property = [];
                    if (item.star_of_property && item.star_of_property !== '' && item.star_of_property !== null && item.star_of_property !== 0) {
                        for (let i = 1; i <= item.star_of_property; i++) {
                            star_property.push(1);
                        }
                    }
                    item.total_star_property = star_property;

                    if (city_name.includes(item.city_name)) {
                        for (let i = 0; i < city_name.length; i++) {
                            if (city_name[i] === item.city_name) {
                                city_filter_data[i].no_of_hotel = city_filter_data[i].no_of_hotel + 1
                            }
                        }
                    }
                    else {
                        city_name.push(item.city_name);
                        city_filter_data.push({ city_id: item.city_id, city_name: item.city_name, no_of_hotel: 1 });
                    }

                })
                setHotelList(responseJson.data);
                setAllCities(city_filter_data);

            }
        })
        .catch((error) => {
        });
}
//Export getAccess 
export default fetchHotels;