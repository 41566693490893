import React, { useContext, useState, useEffect, useRef } from "react";
import config from "../../../config";
import parse from "html-react-parser";
import "../../../css/toolTip.css";
import getInventorynRates from "./getInventorynRates";
// import getTaxRules from "./getTaxRules";
import { Offline, Online } from "react-detect-offline";
import {
  HotelIdContext,
  DateRangeContext,
  CurrencyContext,
  ApiKeyContext,
  CompanyContext,
  CurrencySymbolContext,
  BaseCurrencyContext,
  TempDateRangeContext,
} from "../../../settings/Store";
import getCoupons from "./getCoupons";
import ImageSlider from "./RoomImageSlider";
import RoomPreviewLoader from "./RoomPreviewLoader";
import fetchRoomDetails from "./fetchRoomDetails";
import getCurrencyValue from "../../BookingPanel/Cart/getCurrencyValue";
import moment from "moment";
// Added React Scroll
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import Snackbar from "../../Snackbar";
import RoomMultipleImagesSlider from "./RoomMultipleImagesSlider";
import getLocaleDetails from "../Cart/getLocaleDetails";
import fetchOccupancySetupDetails from "./fetchOccupancySetupDetails";
import RoomDataLoader from "./RoomDataLoader";

const Rooms = (props) => {
  const roomDiv = useRef(null);
  const [hotelId] = useContext(HotelIdContext);
  const [dateRange] = useContext(DateRangeContext);
  const [temphotelId, setTempHotelId] = useState(0);
  const [tempDateRange] = useContext(TempDateRangeContext);
  const [currency] = useContext(CurrencyContext);
  const [baseCurrency] = useContext(BaseCurrencyContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [companyId] = useContext(CompanyContext);
  const [initLoad, setInitLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [inventoryNratesList, setInventoryNratesList] = useState([]); //Initialisation
  const [displayInventoryNratesList, setDisplayInventoryNratesList] = useState(
    []
  ); //Initialisation
  const [couponArr, setCouponArr] = useState([]);
  const [allApplied, setAllApplied] = useState([]);
  const [openDropdown, setOpenDropdown] = useState({});
  const [cart, setCart] = useState({});
  const [addRoomDisable, setAddRoomDisable] = useState(false);
  // const [taxRules, setTaxRules] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState({});
  const [roomTypeArray, setRoomTypeArray] = useState([]);
  const [currencySymbol] = useContext(CurrencySymbolContext);
  const [occupencyStatus, setSingleOccupency] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [los, setLos] = useState("");

  const [selectedAdults, setselectedAdults] = useState("");

  const [publicCoupon, setPublicCoupon] = useState({});
  //added for ribbon value change
  const [ribbonValue, setRibbonValue] = useState("");
  const [selectedNoOfRooms, setSelectedNoOfRooms] = useState([]);

  const [roomImageEndPoint, setroomImageEndPoint] = useState(); //room images url

  // isTaxable state
  const [isTaxable, setIsTaxable] = useState([]);

  // state to hold bed type
  const [displayBedType, setDisplayBedType] = useState("");

  // const [blockCount, setBlockCount] = useState(0);
  let roomRef = useRef();
  var checkin_date = moment(dateRange.startDate).format("YYYY-MM-DD");
  const couponDiff = (startDate, endDate) => {
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };
  const noOfNights = () => {
    let dt1 = new Date(dateRange.startDate);
    let dt2 = new Date(dateRange.endDate);
    // let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    // let diffDays = Math.ceil(302400000 / (1000 * 3600 * 24));

    return diffDays;
  };
  let no_nights = noOfNights();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  //Check availability and set loder while making api call
  useEffect(() => {
    if (props.checkAvail) {
      setSingleOccupency([]);
      setroomImageEndPoint([]);
      setDisplayInventoryNratesList([]);
      setInventoryNratesList([]);
    }
    props.callBackToSetCheckAvail(false);
  }, [
    props.checkAvail,
    setDisplayInventoryNratesList,
    setroomImageEndPoint,
    setInventoryNratesList,
    props.callBackToSetCheckAvail,
  ]);

  // reset the cart to initial state when hotel id changes
  useEffect(() => {
    if (temphotelId != hotelId) {
      setCart({});
    }

    setTempHotelId(hotelId);
  }, [hotelId]);

  useEffect(() => {
    if (coupons) {
      const filteredCoupons = coupons.filter(
        (coupon) => coupon.room_type_id === 0 && coupon.coupon_for === 1
      );
      // console.log('Loaded Coupons:' + coupons);
      setRibbonValue(
        Math.min.apply(Math, filteredCoupons.map((coup) => coup.discount))
      );
    }
  }, [coupons]);

  const [localeDetails, setLocaleDetails] = useState();
  const [occupancySetupDetails, setOccupancySetupDetails] = useState({});

  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      getLocaleDetails(hotelId, setLocaleDetails);
      fetchOccupancySetupDetails(hotelId, setOccupancySetupDetails);
    }
  }, [hotelId, apiKey]);

  const [addRoomBtnStatus, setAddRoomBtnStatus] = useState([]);
  //API CALL to fetch hotels
  useEffect(() => {
    setDisplayInventoryNratesList([]);
    setInventoryNratesList([]);
    if (hotelId != 0 && currency != "" && apiKey != "") {
      getInventorynRates(
        hotelId,
        dateRange,
        currency,
        apiKey,
        setInventoryNratesList,
        setDisplayInventoryNratesList,
        setroomImageEndPoint,
        setOpenDropdown,
        setAddRoomBtnStatus
      );
    }
    // if (hotelId != 0 && companyId != 0) {
    //   getTaxRules(companyId, hotelId, setTaxRules);
    // }
  }, [
    hotelId,
    currency,
    apiKey,
    setInventoryNratesList,
    setOpenDropdown,
    companyId,
    // setTaxRules,
    roomTypeArray,
    setDisplayInventoryNratesList,
    setroomImageEndPoint,
    setAddRoomBtnStatus,
    dateRange,
  ]);

  //API Call to get the room details and room imgaes
  const [roomDetails, setRoomDetails] = useState([]);
  const getRoomDetails = (room_type_id) => {
    if (isModalOpen) {
      return;
    }
    fetchRoomDetails(hotelId, apiKey, room_type_id, setRoomDetails);
  };

  useEffect(() => {
    if (roomDetails.description) {
      setIsloading(false);
    }
  }, [roomDetails]);

  //When Item is removed from Cart
  useEffect(() => {
    setSingleOccupency([]);
    if (
      props.removedCartItem &&
      Object.keys(props.removedCartItem).length > 0
    ) {
      let rate_plan_id = props.removedCartItem.rooms.map((room) => {
        return room.rate_plan_id;
      })[0];
      removeRoom(
        props.removedCartItem.room_type_id,
        rate_plan_id,
        "removeCartItem",
        0
      );
      //Scroll to id
      let id = props.removedCartItem.room_type_id.toString() + rate_plan_id;
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [props.removedCartItem]);

  //Initial cart
  useEffect(() => {
    initialCart();
    callBackParent(0, 0, "", "", "");
    setLoading(false);
    if (
      displayInventoryNratesList.length > 0 &&
      displayInventoryNratesList[0]
    ) {
      props.callBackGetBePrice(displayInventoryNratesList[0].min_room_price);
    }
    //props.callBackGetBePrice(displayInventoryNratesList[0].min_room_price)
  }, [inventoryNratesList, displayInventoryNratesList]);

  //After CheckAvaibality click API CALL
  const checkAvail = props.checkAvail;
  useEffect(() => {
    setDisplayInventoryNratesList([]);
    setInventoryNratesList([]);
    if (hotelId != 0 && currency != "" && apiKey != "" && checkAvail) {
      getInventorynRates(
        hotelId,
        dateRange,
        currency,
        apiKey,
        setInventoryNratesList,
        setDisplayInventoryNratesList,
        setroomImageEndPoint,
        setOpenDropdown
      );
      // console.log('Date Range:' + JSON.stringify(dateRange));
    }
  }, [
    hotelId,
    dateRange,
    currency,
    apiKey,
    setInventoryNratesList,
    setDisplayInventoryNratesList,
    setroomImageEndPoint,
    setOpenDropdown,
    checkAvail,
  ]);

  //When Coupon updates
  useEffect(() => {
    if (coupons && coupons.length > 0) {
      coupons.map((coupon, index) => {
        applyCoupon(
          coupon,
          index === coupons.length - 1 ? true : false,
          coupons
        );
      });
    }
  }, [coupons]);
  //Coupon from cart private coupon
  useEffect(() => {
    if (props.cuponFromCart) {
      props.cuponFromCart.map((coupon, index) => {
        applyCoupon(coupon, true, coupons); //As only one coupon applied from cart at a time
      });
    }
  }, [props.cuponFromCart]);

  //On Date Range Change Cart Reset
  useEffect(() => {
    initialCart();
    callBackParent(0, 0, "", "", "");
  }, [dateRange, coupons]);
  //Initial Cart Items
  const initialCart = () => {
    let roomCartItem = {};
    setCart(roomCartItem);
    inventoryNratesList &&
      inventoryNratesList.length > 0 &&
      inventoryNratesList.map((inventorynRate) => {
        inventorynRate.rate_plans &&
          inventorynRate.rate_plans.map((ratePlan, index) => {
            let cartItem = prepareCart(
              inventorynRate.room_type_id,
              ratePlan.rate_plan_id
            );
            cartItem.rooms[0]["bar_price"] = getRoomPrice(
              inventorynRate.room_type_id,
              ratePlan.rate_plan_id
            );
            cartItem.rooms[0]["bar_price"] = parseFloat(
              cartItem.rooms[0]["bar_price"]
            );
            cartItem.rate_plan_id = ratePlan.rate_plan_id;
            cartItem.rooms[0].room = cartItem.rooms[0].room + 1; //Concatinating number 1 to room
            Object.assign(cart, {
              [inventorynRate.room_type_id +
                ratePlan.rate_plan_id.toString()]: cartItem,
            }); //Set new room type into cart
          });
      });
    Object.assign(roomCartItem, cart); //Assign it new object to re render
    setCart(roomCartItem);
    updateCart();
    if (coupons.length > 0) {
      applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
    } else {
      applyCoupon({}, 0);
    }
  };
  //Get coupons from API
  useEffect(() => {
    // console.log('Coupon Temp Date' + JSON.stringify(tempDateRange));
    getCoupons(hotelId, setCoupons, dateRange);
  }, [hotelId, setCoupons, tempDateRange, dateRange]);

  //coupons array
  // const appliedCoupons = [];

  const getDateArray = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  };

  const applyCoupon = (coupon, last, coupons) => {
    let genericCoupon;
    let specificCoupon;
    let genericCouponArray;
    let specificCouponArray;
    if (coupons) {
      const genericCoupons = coupons.filter(
        (coupon) => coupon.room_type_id === 0
      );
      if (genericCoupons.length > 0) {
        genericCoupon = genericCoupons.reduce((prev, cur) => {
          return prev.coupon_id > cur.coupon_id ? prev : cur;
        });
      }
    }

    let newCart = {};
    let cartItems = Object.values(cart);
    cartItems &&
      cartItems.map((cartItem) => {
        let price = 0;
        cartItem.rooms.length > 0 &&
          cartItem.rooms.map((room) => {
            price +=
              room.bar_price + room.extra_adult_price + room.extra_child_price;
          });
        cart[
          cartItem.room_type_id + cartItem.rate_plan_id.toString()
        ].price = price;
        let from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
        let to_date = moment(dateRange.endDate).format("YYYY-MM-DD");
        let end_date = moment(to_date)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        let dateArray = getDateArray(from_date, end_date);

        let coupon_start_date = moment(coupon.valid_from).format("YYYY-MM-DD");
        let coupon_end_date = moment(coupon.valid_to)
          //Minhaj changes for coupon end date
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        let couponArray = getDateArray(coupon_start_date, coupon_end_date);

        if (genericCoupon) {
          genericCouponArray = getDateArray(
            moment(genericCoupon.valid_from).format("YYYY-MM-DD"),
            moment(genericCoupon.valid_to)
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          );
        }

        let start = moment(dateRange.startDate);
        let end = moment(dateRange.endDate);
        let no_of_days = Math.round(moment.duration(end.diff(start)).asDays());

        let discountPrice = 0;
        const appliedDates = [];
        const applied_coupons = [];
        let public_coupon_discount_price_array = [];

        dateArray &&
          dateArray.map((value, index) => {
            coupons &&
              coupons.length > 0 &&
              coupons.map((coupon, index) => {
                if (
                  value == coupon.date &&
                  cartItem.room_type_id === coupon.room_type_id
                ) {
                  cartItem.rooms.map((rates_for_discount) => {
                    // let total_bar_price = 1;
                    // total_bar_price = rates_for_discount.bar_price / no_of_days

                    // discountPrice +=
                    //   (total_bar_price / 100) * coupon.discount;

                    let total_bar_price = 1;
                    rates_for_discount.day_wise_rates &&
                      rates_for_discount.day_wise_rates.map((day_rate) => {
                        if (value === day_rate.date) {
                          // changes for fixing price calculation for single,multiple occupancy
                          if (
                            rates_for_discount.selected_adult <
                            cartItem.max_people
                          ) {
                            if (
                              day_rate.multiple_occupancy[
                              rates_for_discount.selected_adult - 1
                              ]
                            ) {
                              total_bar_price = parseFloat(
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              );
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }
                          } else {
                            total_bar_price = day_rate.bar_price;
                          }
                          // changes for fixing price calculation for single,multiple occupancy

                          // total_bar_price = day_rate.bar_price
                          discountPrice +=
                            (total_bar_price / 100) * coupon.discount;

                          // day_rate.public_coupon_discount_price = (total_bar_price / 100) * coupon.discount
                          public_coupon_discount_price_array.push({
                            room: rates_for_discount.room,
                            date: value,
                            public_coupon_discount_price:
                              (total_bar_price / 100) * coupon.discount,
                          });
                        }
                      });

                    rates_for_discount.public_coupon_discount_price_array = public_coupon_discount_price_array;
                  });

                  applied_coupons.push(coupon);
                  cart[
                    cartItem.room_type_id + cartItem.rate_plan_id.toString()
                  ].discounted_price = discountPrice;

                  cart[
                    cartItem.room_type_id + cartItem.rate_plan_id.toString()
                  ].appliedCoupons = applied_coupons;
                  appliedDates.push(value);
                }
              });
          });

        dateArray &&
          dateArray.map((value, index) => {
            coupons &&
              coupons.length > 0 &&
              coupons.map((coupon, index) => {
                if (
                  value == coupon.date &&
                  coupon.room_type_id === 0 &&
                  !appliedDates.includes(coupon.date)
                ) {
                  cartItem.rooms.map((rates_for_discount) => {
                    // let total_bar_price = 1;
                    // total_bar_price = rates_for_discount.bar_price / no_of_days
                    // discountPrice +=(total_bar_price / 100) * coupon.discount;

                    let total_bar_price = 1;
                    rates_for_discount.day_wise_rates &&
                      rates_for_discount.day_wise_rates.map((day_rate) => {
                        if (value === day_rate.date) {
                          // changes for fixing price calculation for single,multiple occupancy

                          if (
                            rates_for_discount.selected_adult <
                            cartItem.max_people
                          ) {
                            if (
                              day_rate.multiple_occupancy[
                              rates_for_discount.selected_adult - 1
                              ]
                            ) {
                              total_bar_price = parseFloat(
                                day_rate.multiple_occupancy[
                                rates_for_discount.selected_adult - 1
                                ]
                              );
                            } else {
                              total_bar_price = day_rate.bar_price;
                            }
                          } else {
                            total_bar_price = day_rate.bar_price;
                          }
                          // changes for fixing price calculation for single,multiple occupancy

                          // total_bar_price = day_rate.bar_price
                          discountPrice +=
                            (total_bar_price / 100) * coupon.discount;

                          // day_rate.public_coupon_discount_price = (total_bar_price / 100) * coupon.discount
                          public_coupon_discount_price_array.push({
                            room: rates_for_discount.room,
                            date: value,
                            public_coupon_discount_price:
                              (total_bar_price / 100) * coupon.discount,
                          });
                        }
                      });

                    rates_for_discount.public_coupon_discount_price_array = public_coupon_discount_price_array;
                  });

                  applied_coupons.push(coupon);
                  cart[
                    cartItem.room_type_id + cartItem.rate_plan_id.toString()
                  ].discounted_price = discountPrice;

                  cart[
                    cartItem.room_type_id + cartItem.rate_plan_id.toString()
                  ].appliedCoupons = applied_coupons;
                  appliedDates.push(value);
                }
              });
          });
      });

    //only last coupon will be applied
    if (props.cuponFromCart) {
      setAppliedCoupon(coupon);
    }
    Object.assign(newCart, cart);
    setCart(newCart);
    updateCart();
  };

  // console.log(JSON.stringify(inventoryNratesList[0]));
  useEffect(() => {
    if (appliedCoupon.hasOwnProperty("coupon_id")) {
      callBackParent(1, 1, "", "", "no-toggle");
    }
  }, [appliedCoupon]);
  //calculating discounted price
  const calculateDiscountPrice = (
    date,
    room_type_id,
    rate_plan_id,
    coupon,
    rooms
  ) => {
    var dsprice;
    var totaldsprice;
    inventoryNratesList.map((value, key) => {
      if (value.room_type_id == room_type_id) {
        value.rate_plans &&
          value.rate_plans.map((ratesplans, key) => {
            if (ratesplans.rate_plan_id == rate_plan_id) {
              if (ratesplans.rates) {
                ratesplans.rates.map((rates, key) => {
                  if (rates.date == date) {
                    if (rates.date == date) {
                      if (occupencyStatus.room_type_id == room_type_id) {
                        var calculated_dsprice =
                          (rates.multiple_occupancy[
                            occupencyStatus.selected_adults - 1
                          ] *
                            coupon.discount) /
                          100;
                        dsprice = calculated_dsprice * rooms.length;
                      } else {
                        var calculated_dsprice =
                          (rates.bar_price * coupon.discount) / 100;
                        dsprice = calculated_dsprice * rooms.length;
                      }
                    }
                  }
                });
              }
            }
          });
      }
    });
    // console.log('Discount price: ', dsprice, room_type_id);
    return dsprice;
  };
  //Toggle Dropdown
  const toggleDropdown = (room_type_id, rate_plan_id, los) => {
    // console.log('LOS ' + los);
    // console.log('No of nights: ' + noOfNights());
    // los = 3;
    //Code for select no of rooms

    if (los) {
      let default_selected_rooms = [];
      if (displayInventoryNratesList && displayInventoryNratesList.length > 0) {
        displayInventoryNratesList.map((items, index) => {
          default_selected_rooms.push(1);
        });
      }
      setSelectedNoOfRooms(default_selected_rooms);

      let length_room =
        cart[room_type_id + rate_plan_id.toString()] &&
        cart[room_type_id + rate_plan_id.toString()].rooms.length;

      for (let i = length_room; i > 1; i--) {
        let no_of_rooms =
          cart[room_type_id + rate_plan_id.toString()] &&
          cart[room_type_id + rate_plan_id.toString()].rooms.length;
        if (no_of_rooms > 1) {
          cart[room_type_id + rate_plan_id.toString()] &&
            cart[room_type_id + rate_plan_id.toString()].rooms.map((room) => {
              if (room.rate_plan_id == rate_plan_id) {
                cart[room_type_id + rate_plan_id.toString()].rooms.splice(
                  no_of_rooms - 1,
                  1
                );
                return;
              }
            });
        }
        if (coupons.length > 0) {
          applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
        } else {
          applyCoupon({}, 0);
        }
        updateCart();
        setCart(cart);
      }
    }

    //Code for select no of rooms

    setLos(los);
    let dropDowns = {};
    let addRoomBtns = {};
    let current_key = room_type_id.toString() + rate_plan_id.toString();
    inventoryNratesList.map((inv) => {
      inv.rate_plans &&
        inv.rate_plans.map((rate) => {
          let key = inv.room_type_id.toString() + rate.rate_plan_id.toString();
          let ob = { [key]: current_key == key ? !openDropdown[key] : false };

          Object.assign(dropDowns, ob);
        });
    });
    if (noOfNights() < los) {
      setPopupOpen(true);
      return;
    } else setOpenDropdown(dropDowns);
    // setDropDownOpen();
  };
  const toggleAddRoomStatus = (room_type_id, rate_plan_id) => {
    let addRoomBtns = {};
    let current_key = room_type_id.toString() + rate_plan_id.toString();
    inventoryNratesList.map((inv) => {
      inv.rate_plans &&
        inv.rate_plans.map((rate) => {
          let key = inv.room_type_id.toString() + rate.rate_plan_id.toString();
          let ob2 = {
            [key]: current_key == key ? !addRoomBtnStatus[key] : false,
          };
          Object.assign(addRoomBtns, ob2);
        });
    });
    setAddRoomBtnStatus(addRoomBtns);
  };
  //Update Disable status
  const disabledStatus = (room_type_id, rate_plan_id, type) => {
    let newCart = {};
    let keys = Object.keys(cart);
    let cartItemsTodisable = keys.filter((k) =>
      k.includes(room_type_id.toString())
    );
    cartItemsTodisable &&
      cartItemsTodisable.map((key) => {
        if (type == "disable") {
          cart[key].add_room = false;
        }
        if (type == "enable") {
          cart[key].add_room = true;
        }
      });
    Object.assign(newCart, cart);
    setCart(cart);
  };
  //Add room
  const addRoom = (room_type_id, rate_plan_id, minimum_inventory) => {
    // setAppliedCoupon('')
    //Check for the rooms count not more than minimum inventory
    if (
      cart[room_type_id + rate_plan_id.toString()] &&
      cart[room_type_id + rate_plan_id.toString()].rooms.length >=
      minimum_inventory
    ) {
      toggleAddRoomStatus(room_type_id, rate_plan_id);
      return false;
    }
    let cartItem = prepareCart(room_type_id, rate_plan_id);
    let roomCartItem = {};
    if (cartItem) {
      let noCart = true;
      if (
        cart[room_type_id + rate_plan_id.toString()] &&
        cart[room_type_id + rate_plan_id.toString()].room_type_id ==
        room_type_id
      ) {
        cartItem.rooms[0]["bar_price"] = getRoomPrice(
          room_type_id,
          rate_plan_id
        );
        cartItem.rooms[0]["bar_price"] = parseFloat(
          cartItem.rooms[0]["bar_price"]
        );
        let len = cart[room_type_id + rate_plan_id.toString()].rooms.length + 1;
        cartItem.rooms[0].room = cartItem.rooms[0].room + len;
        cart[room_type_id + rate_plan_id.toString()].rooms.push(
          cartItem.rooms[0]
        );
        noCart = false;
        Object.assign(roomCartItem, cart); //Assign it new object to re render*/
      }
      if (noCart) {
        cartItem.rooms[0]["bar_price"] = getRoomPrice(
          room_type_id,
          rate_plan_id
        );
        cartItem.rooms[0]["bar_price"] = parseFloat(
          cartItem.rooms[0]["bar_price"]
        );
        cartItem.rate_plan_id = rate_plan_id;
        cartItem.rooms[0].room = cartItem.rooms[0].room + 1; //Concatinating number 1 to room
        Object.assign(cart, {
          [room_type_id + rate_plan_id.toString()]: cartItem,
        }); //Set new room type into cart
        Object.assign(roomCartItem, cart); //Assign it new object to re render
      }
    }
    setCart(roomCartItem);
    if (coupons.length > 0) {
      applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
    } else {
      applyCoupon({}, 0);
    }
    updateCart();
  };

  //Add Room (Select Box)
  const totalRoomSelected = (
    e,
    index,
    room_type_id,
    rate_plan_id,
    minimum_inventory
  ) => {
    let length_room =
      cart[room_type_id + rate_plan_id.toString()] &&
      cart[room_type_id + rate_plan_id.toString()].rooms.length;

    for (let i = length_room; i > 1; i--) {
      let no_of_rooms =
        cart[room_type_id + rate_plan_id.toString()] &&
        cart[room_type_id + rate_plan_id.toString()].rooms.length;
      if (no_of_rooms > 1) {
        cart[room_type_id + rate_plan_id.toString()] &&
          cart[room_type_id + rate_plan_id.toString()].rooms.map((room) => {
            if (room.rate_plan_id == rate_plan_id) {
              cart[room_type_id + rate_plan_id.toString()].rooms.splice(
                no_of_rooms - 1,
                1
              );
              return;
            }
          });
      }
      if (coupons.length > 0) {
        applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
      } else {
        applyCoupon({}, 0);
      }
      updateCart();
      setCart(cart);
    }

    let total_rooms = parseInt(e);
    selectedNoOfRooms[index] = parseInt(e);
    setSelectedNoOfRooms([...selectedNoOfRooms]);

    for (let i = 1; i < total_rooms; i++) {
      //Check for the rooms count not more than minimum inventory
      if (
        cart[room_type_id + rate_plan_id.toString()] &&
        cart[room_type_id + rate_plan_id.toString()].rooms.length >=
        minimum_inventory
      ) {
        toggleAddRoomStatus(room_type_id, rate_plan_id);
        return false;
      }
      let cartItem = prepareCart(room_type_id, rate_plan_id);
      let roomCartItem = {};
      if (cartItem) {
        let noCart = true;
        if (
          cart[room_type_id + rate_plan_id.toString()] &&
          cart[room_type_id + rate_plan_id.toString()].room_type_id ==
          room_type_id
        ) {
          cartItem.rooms[0]["bar_price"] = getRoomPrice(
            room_type_id,
            rate_plan_id
          );
          cartItem.rooms[0]["bar_price"] = parseFloat(
            cartItem.rooms[0]["bar_price"]
          );
          let len =
            cart[room_type_id + rate_plan_id.toString()].rooms.length + 1;
          cartItem.rooms[0].room = cartItem.rooms[0].room + len;
          cart[room_type_id + rate_plan_id.toString()].rooms.push(
            cartItem.rooms[0]
          );
          noCart = false;
          Object.assign(roomCartItem, cart); //Assign it new object to re render*/
        }
        if (noCart) {
          cartItem.rooms[0]["bar_price"] = getRoomPrice(
            room_type_id,
            rate_plan_id
          );
          cartItem.rooms[0]["bar_price"] = parseFloat(
            cartItem.rooms[0]["bar_price"]
          );
          cartItem.rate_plan_id = rate_plan_id;
          cartItem.rooms[0].room = cartItem.rooms[0].room + 1; //Concatinating number 1 to room
          Object.assign(cart, {
            [room_type_id + rate_plan_id.toString()]: cartItem,
          }); //Set new room type into cart
          Object.assign(roomCartItem, cart); //Assign it new object to re render
        }
      }
      setCart(roomCartItem);

      if (coupons.length > 0) {
        applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
      } else {
        applyCoupon({}, 0);
      }
      updateCart();
    }
  };

  //Remove Rooms
  const removeRoom = (
    room_type_id,
    rate_plan_id,
    type,
    minimum_inventory,
    index
  ) => {
    //code for select box
    if (index >= 0) {
      selectedNoOfRooms[index]--;
      setSelectedNoOfRooms([...selectedNoOfRooms]);
    }
    //Check for the rooms count not more than minimum inventory
    if (
      minimum_inventory != 0 &&
      cart[room_type_id + rate_plan_id.toString()] &&
      cart[room_type_id + rate_plan_id.toString()].rooms.length ===
      minimum_inventory
    ) {
      toggleAddRoomStatus(room_type_id, rate_plan_id);
    }
    let newCart = {};
    let no_of_rooms =
      cart[room_type_id + rate_plan_id.toString()] &&
      cart[room_type_id + rate_plan_id.toString()].rooms.length;
    if (type != "removeCartItem" && no_of_rooms > 1) {
      cart[room_type_id + rate_plan_id.toString()] &&
        cart[room_type_id + rate_plan_id.toString()].rooms.map((room) => {
          if (room.rate_plan_id == rate_plan_id) {
            cart[room_type_id + rate_plan_id.toString()].rooms.splice(
              no_of_rooms - 1,
              1
            );
            return;
          }
        });
    }
    if (type != "removeCartItem" && no_of_rooms == 1) {
      delete cart[room_type_id + rate_plan_id.toString()];
    }
    if (type == "removeCartItem") {
      callBackParent(room_type_id, rate_plan_id, "enable", "remove", "");
    } else {
      // Object.assign(newCart, cart); //TO re render the component

      if (coupons.length > 0) {
        applyCoupon(coupons[coupons.length - 1], coupons.length - 1, coupons);
      } else {
        applyCoupon({}, 0);
      }
      updateCart(); //Update the cart
      setCart(cart);
    }
  };
  //prepair Cart item
  const prepareCart = (room_type_id, rate_plan_id) => {
    let cartItem = {
      room_type: "",
      bookable_name: "",
      plan_type: "",
      room_type_id: 0,
      price: 0,
      rooms: [],
      tax: [],
      discounted_price: 0,
      paid_amount: 0,
      paid_amount_per: 0,

      rates_for_coupons: [],
    }; //Initialises  every time to avoid the object overwrite issue
    inventoryNratesList &&
      inventoryNratesList.map((inv) => {
        if (room_type_id == inv.room_type_id) {
          cartItem["room_type"] = inv.room_type;
          cartItem["bookable_name"] = inv.bookable_name;
          cartItem["room_type_id"] = room_type_id;
          cartItem["price"] = 0;
          cartItem["added_to_cart"] = false;
          cartItem["add_room"] = true;
          cartItem["rate_plan_id"] = rate_plan_id;
          cartItem["max_room_capacity"] = inv.max_room_capacity;
          cartItem["max_child"] = inv.max_child;
          cartItem["max_people"] = inv.max_people;
          cartItem["extra_person"] = inv.extra_person;
          inv.rate_plans &&
            inv.rate_plans.map((rate_plan) => {
              if (rate_plan_id == rate_plan.rate_plan_id) {
                cartItem["plan_type"] = rate_plan.plan_type;
                cartItem["plan_name"] = rate_plan.plan_name;
                let total_adults =
                  parseInt(inv.max_people) + parseInt(inv.extra_person);
                let total_child =
                  parseInt(inv.max_child) + parseInt(inv.extra_child);
                // console.log("MAX OCC: ", inv.max_occupancy);

                let total_infant = parseInt(inv.max_infant);

                let count = [];
                let child_count = [];

                let infant_count = [];
                for (let i = 1; i <= total_adults; i++) {
                  let countdata = { option: i, value: i };
                  count.push(countdata);
                }
                for (let j = 0; j <= total_child; j++) {
                  let countdata = { option: j, value: j };
                  child_count.push(countdata);
                }

                for (let k = 0; k <= total_infant; k++) {
                  let countdata = { option: k, value: k };
                  infant_count.push(countdata);
                }

                let child_no;
                if (inv.max_occupancy !== 0) {
                  child_no = 0;
                } else {
                  child_no = parseInt(inv.max_child);
                }

                // change //
                // let infant_no;
                // if (inv.max_occupancy !== 0) {
                //   infant_no = 0;
                // } else {
                //   infant_no = parseInt(inv.max_infant);
                // }
                // change //

                cartItem["rooms"][0] = {
                  room: "Room",
                  adults: count,
                  selected_adult: parseInt(inv.max_people),
                  childs: child_count,
                  infants: infant_count,
                  // change //
                  selected_infant: 0,
                  // change //

                  // selected_child: parseInt(inv.max_child),
                  selected_child: child_no,
                  rate_plan_id: rate_plan_id,
                  extra_adult_price: 0,
                  extra_child_price: 0,
                  bar_price: parseFloat(rate_plan.bar_price),
                  // selected_infant: 0,

                  day_wise_rates: rate_plan.rates,
                };

                //MyCode
                cartItem["rates_for_coupons"] = rate_plan.rates;
                //MyCode
              }
            });
        }
      });
    return cartItem;
  };
  //Get Room price
  const getRoomPrice = (room_type_id, rate_plan_id) => {
    let curPrice = 0;
    inventoryNratesList &&
      inventoryNratesList.map((inv) => {
        if (room_type_id == inv.room_type_id) {
          inv.rate_plans &&
            inv.rate_plans.map((rate_plan) => {
              if (rate_plan_id == rate_plan.rate_plan_id) {
                rate_plan.rates &&
                  rate_plan.rates.map((rate) => {
                    curPrice += parseFloat(rate.bar_price);
                  });
              }
            });
        }
      });
    return curPrice;
  };
  //Set Extra adult message
  const [extraAdultMessage, setExtraAdultmessage] = useState("");
  const [modalTerms, setModalTerms] = useState(false);

  //Handles the adults selected
  const handleAdultChange = (
    e,
    room_type_id,
    max_occupancy,
    rate_plan_id,
    room,
    room_data
  ) => {
    // console.log("Adult Value: ", parseInt(e.target.value));
    let newCart = {};
    let invData = getAllInv(room_type_id, rate_plan_id);
    let selected_adults = parseInt(e.target.value);
    // console.log("Adults::::", e.target.value);
    setselectedAdults(selected_adults);
    let adult = selected_adults - 1; //to adjust index of the array
    let update_price = 0;
    let extra_adult_price = 0;
    let no_of_extra_adults = 0;
    let extra_child_price = 0;
    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      room_type_id: room_type_id,
      rate_plan_id: rate_plan_id,
      max_room_capacity:
        cart[room_type_id + rate_plan_id.toString()].max_room_capacity,
      max_adult: cart[room_type_id + rate_plan_id.toString()].max_people,
      extra_adult: cart[room_type_id + rate_plan_id.toString()].extra_person,
      selected_person: selected_adults,
      max_occupancy,
      room: room,
      type: "adult",
    };

    //Check For maximum room capacity
    checkMaxRoomCapacity(checkMaxRoomCapacityData);
    if (selected_adults < invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        updateExtraChildPrice(
          room_type_id,
          rate_plan_id,
          extra_child_price,
          room
        );
      }
      let obj = {
        room_type_id: room_type_id,
        selected_adults: selected_adults,
      };
      setSingleOccupency(obj);
      extra_adult_price = 0;
      // invData["multiple_occupancy"].map((occupancy) => {
      //   if (occupancy[adult] == 0 || occupancy[adult] == "" || !occupancy[adult]) {
      //     occupancy[adult] = invData["bar_price"];
      //   }
      //   if (adult > 0) {
      //     update_price += parseFloat(occupancy[adult] / no_nights);
      //   }
      //   else {
      //     update_price += parseFloat(occupancy[adult]);
      //   }
      // });

      invData["inv_multiple_occupancy"].map((occupancy) => {
        if (occupancy[adult]) {
          update_price += parseFloat(occupancy[adult]);
        }
      });

      if (!update_price) {
        update_price = getRoomPrice(room_type_id, rate_plan_id);
      }
    }
    if (selected_adults > invData["max_people"]) {
      extra_adult_price = 0;
      update_price = getRoomPrice(room_type_id, rate_plan_id);
      no_of_extra_adults = selected_adults - invData["max_people"];
      extra_adult_price = no_of_extra_adults * invData["extra_adult_price"];
      setExtraAdultmessage(
        baseCurrency !== currency
          ? (invData["display_extra_adult_price"] / no_nights).toFixed()
          : parseFloat(
            (invData["extra_adult_price"] / no_nights).toFixed()
          ).toLocaleString("en-IN")
      );
      setModalTerms(true);
    }
    if (selected_adults === invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        // extra_child_price = invData["extra_child_price"];
        updateExtraChildPrice(
          room_type_id,
          rate_plan_id,
          extra_child_price,
          room
        );
      }
      extra_adult_price = 0;
      update_price = getRoomPrice(room_type_id, rate_plan_id);
    }

    // added to fix the issue of extra adult selection
    room_data.no_of_extra_adult = no_of_extra_adults;
    // added to fix the issue of extra adult selection

    Object.assign(newCart, cart);
    setCart(newCart);
    updateExtraAdultPrice(room_type_id, rate_plan_id, extra_adult_price, room);
    updateOccupancy(room_type_id, rate_plan_id, update_price, room);
    updateCart();
  };
  //Set Extra adult message
  const [extraChildMessage, setExtraChildmessage] = useState("");
  const [modalChildTerms, setModalChildTerms] = useState(false);
  //Handles the children selected
  const handleChildChange = (
    e,
    room_type_id,
    max_occupancy,
    rate_plan_id,
    room,
    room_data
  ) => {
    let newCart = {};
    let selected_child = parseInt(e.target.value); //Selected child
    let invData = getAllInv(room_type_id, rate_plan_id);
    let extra_child_price = 0;
    let no_of_extra_child = 0;

    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      room_type_id: room_type_id,
      rate_plan_id: rate_plan_id,
      max_room_capacity:
        cart[room_type_id + rate_plan_id.toString()].max_room_capacity,
      max_adult: cart[room_type_id + rate_plan_id.toString()].max_people,
      max_child: cart[room_type_id + rate_plan_id.toString()].max_child,
      max_occupancy,
      extra_adult: cart[room_type_id + rate_plan_id.toString()].extra_person,
      selected_person: selected_child,
      room: room,
      type: "child",
    };
    let adult_person = selectedAdults;
    checkMaxRoomCapacity(checkMaxRoomCapacityData);
    // console.log(selectedAdults, invData["max_people"]);
    if (max_occupancy !== 0 && selectedAdults == invData["max_people"]) {
      invData["max_child"] = 0;
    }
    if (selected_child < invData["max_child"]) {
      extra_child_price = 0;
    }
    if (selected_child > invData["max_child"]) {
      no_of_extra_child = selected_child - invData["max_child"];
      extra_child_price = no_of_extra_child * invData["extra_child_price"];
      if (invData["extra_child_price"] > 0) {
        setExtraChildmessage(
          baseCurrency !== currency
            ? (invData["display_extra_child_price"] / no_nights).toFixed()
            : parseFloat(
              (invData["extra_child_price"] / no_nights).toFixed()
            ).toLocaleString("en-IN")
        );
        setModalChildTerms(true);
      }
    }
    if (selected_child === invData["max_child"]) {
      extra_child_price = 0;
    }

    // added to fix the issue of extra child selection
    room_data.no_of_extra_child = no_of_extra_child;
    // added to fix the issue of extra child selection

    Object.assign(newCart, cart);
    setCart(newCart);

    updateExtraChildPrice(room_type_id, rate_plan_id, extra_child_price, room);
    updateCart();
  };
  //Handle Infant Change
  const handleInfantChange = (e, room_type_id, rate_plan_id, room) => {
    let newCart = {};
    let selected_infant = parseInt(e.target.value); //Selected child
    cart[room_type_id + rate_plan_id.toString()].rooms &&
      cart[room_type_id + rate_plan_id.toString()].rooms.map((roomObj) => {
        if (roomObj.room == room && roomObj.rate_plan_id == rate_plan_id) {
          roomObj.selected_infant = selected_infant;
        }
      });
    Object.assign(newCart, cart);
    setCart(newCart);
    updateCart();
  };
  //Update Extra Adult price
  const updateExtraAdultPrice = (
    room_type_id,
    rate_plan_id,
    extra_adult_price,
    room
  ) => {
    let updatedCart = {};
    cart[room_type_id + rate_plan_id.toString()].rooms &&
      cart[room_type_id + rate_plan_id.toString()].rooms.map((roomObj) => {
        if (roomObj.room == room && roomObj.rate_plan_id == rate_plan_id) {
          roomObj.extra_adult_price = extra_adult_price;

          // added to solve the issue of extra adult price calculation
          if (extra_adult_price == 0) {
            roomObj.no_of_extra_adult = 0;
          }
          // added to solve the issue of extra adult price calculation
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  //Update Extra Child price
  const updateExtraChildPrice = (
    room_type_id,
    rate_plan_id,
    extra_child_price,
    room
  ) => {
    let updatedCart = {};
    cart[room_type_id + rate_plan_id.toString()].rooms &&
      cart[room_type_id + rate_plan_id.toString()].rooms.map((roomObj) => {
        if (roomObj.room == room && roomObj.rate_plan_id == rate_plan_id) {
          roomObj.extra_child_price = extra_child_price;

          // added to solve the issue of extra child price calculation
          if (extra_child_price == 0) {
            roomObj.no_of_extra_child = 0;
          }
          // added to solve the issue of extra child price calculation
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  //Update occupancy price
  const updateOccupancy = (room_type_id, rate_plan_id, update_price, room) => {
    let updatedCart = {};
    cart[room_type_id + rate_plan_id.toString()].rooms &&
      cart[room_type_id + rate_plan_id.toString()].rooms.map((roomObj) => {
        if (roomObj.room == room && roomObj.rate_plan_id == rate_plan_id) {
          roomObj.bar_price = update_price;
        }
      });
    Object.assign(updatedCart, cart);
    setCart(updatedCart);
  };
  const [modalMaxRoomCapacityTerms, setModalMaxRoomCapacityTerms] = useState(
    false
  );
  //Check Max Capacity
  const checkMaxRoomCapacity = (roomCapacityCheckObj) => {
    let updatedCart = {};
    let room_type_id = roomCapacityCheckObj.room_type_id;
    let rate_plan_id = roomCapacityCheckObj.rate_plan_id;
    let max_capacity_status = roomCapacityCheckObj.max_room_capacity;
    let max_adult = roomCapacityCheckObj.max_adult;
    let extra_adult = roomCapacityCheckObj.extra_adult;
    let selected_person = roomCapacityCheckObj.selected_person;
    let room = roomCapacityCheckObj.room;
    let type = roomCapacityCheckObj.type;
    const { max_occupancy } = roomCapacityCheckObj;
    // console.log("Max occupency: ", max_occupancy);
    cart[room_type_id + rate_plan_id.toString()].rooms &&
      cart[room_type_id + rate_plan_id.toString()].rooms.map((roomObj) => {
        if (roomObj.room == room && roomObj.rate_plan_id == rate_plan_id) {
          if (max_capacity_status === 0) {
            if (type === "adult") roomObj.selected_adult = selected_person;
            if (type === "child") roomObj.selected_child = selected_person;
          }
          if (max_capacity_status === 1) {
            //New Requirement for Shangri-la
            if (max_occupancy && max_occupancy !== 0 && max_occupancy !== "") {
              // if (roomObj.selected_adult < max_occupancy) {
              if (roomObj.selected_adult == max_adult + extra_adult) {
                roomObj.selected_child = 0;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child <= max_occupancy
              ) {
                roomObj.selected_adult = selected_person;
                // if (roomObj.selected_adult < max_adult + extra_adult) {
                //   roomObj.selected_child = 1;
                // }
              }

              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child <= max_occupancy
              ) {
                roomObj.selected_child = selected_person;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child > max_occupancy
              ) {
                roomObj.selected_adult = selected_person;
                roomObj.selected_child = max_occupancy - roomObj.selected_adult;
                updateExtraChildPrice(room_type_id, rate_plan_id, 0, room);
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child > max_occupancy
              ) {
                roomObj.selected_child = selected_person;
                roomObj.selected_adult = max_occupancy - roomObj.selected_child;
                updateExtraAdultPrice(room_type_id, rate_plan_id, 0, room);
              }
            }
            //Requirement for Shangri-la Ends
            else {
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child <=
                max_adult + extra_adult
              ) {
                roomObj.selected_adult = selected_person;
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child <=
                max_adult + extra_adult
              ) {
                roomObj.selected_child = selected_person;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child >
                max_adult + extra_adult
              ) {
                roomObj.selected_adult = selected_person;
                roomObj.selected_child =
                  max_adult + extra_adult - roomObj.selected_adult;
                updateExtraChildPrice(room_type_id, rate_plan_id, 0, room);
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child >
                max_adult + extra_adult
              ) {
                roomObj.selected_child = selected_person;
                roomObj.selected_adult =
                  max_adult + extra_adult - roomObj.selected_child;
                updateExtraAdultPrice(room_type_id, rate_plan_id, 0, room);
              }
            }
          }
        }
      });
    Object.assign(updatedCart, cart);

    setCart(updatedCart);
  };
  const [currencyValue, setCurrencyValue] = useState(1);
  useEffect(() => {
    if (baseCurrency !== null && baseCurrency !== "" && currency !== null) {
      getCurrencyValue(currency, baseCurrency, setCurrencyValue);
    }
  }, [currency, baseCurrency, setCurrencyValue]);
  const getAllInv = (room_type_id, rate_plan_id) => {
    let invDetail = [];
    invDetail["bar_price"] = 0;
    invDetail["extra_adult_price"] = 0;
    invDetail["extra_child_price"] = 0;
    invDetail["multiple_occupancy"] = [];
    invDetail["inv_multiple_occupancy"] = [];
    inventoryNratesList &&
      inventoryNratesList.map((inv) => {
        if (room_type_id == inv.room_type_id) {
          inv.rate_plans.map((rate_plan) => {
            if (rate_plan_id == rate_plan.rate_plan_id) {
              rate_plan.rates.map((rate) => {
                let rate_multiple_occupancy = rate.multiple_occupancy;
                invDetail["room_type"] = inv.room_type;
                invDetail["max_people"] = inv.max_people;
                invDetail["max_child"] = inv.max_child;
                invDetail["max_occupancy"] = inv.max_occupancy;
                invDetail["extra_person"] = inv.extra_person;
                invDetail["extra_child"] = inv.extra_child;
                invDetail["plan_type"] = rate_plan.plan_type;
                invDetail["bar_price"] += rate_plan.bar_price;
                invDetail["extra_adult_price"] += rate.extra_adult_price;
                invDetail["extra_child_price"] += rate.extra_child_price;
                invDetail["display_extra_adult_price"] = (
                  rate.extra_adult_price * currencyValue
                ).toFixed(2);
                invDetail["display_extra_child_price"] = (
                  rate.extra_child_price * currencyValue
                ).toFixed(2);
                // invDetail["multiple_occupancy"].push(rate.multiple_occupancy);
                invDetail["inv_multiple_occupancy"].push(
                  rate_multiple_occupancy
                );
              });
            }
          });
        }
      });
    return invDetail;
  };
  //Update Total
  const updateCart = () => {
    let updatedCart = {};
    //Calculate the cart Total price
    if (Object.values(cart).length > 0) {
      const cartItems = Object.entries(cart);
      cartItems.map((cartItem) => {
        cartItem[1].price = 0;
        let room_price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem[1].rooms.map((room) => {
          extra_adult_price += room.extra_adult_price;
          extra_child_price += room.extra_child_price;
          cart[cartItem[0]].price += room.bar_price;
          room_price += parseFloat(room.bar_price);
        });
        //Update the GSt Price into cart
        updateGstPrice(
          cartItem[1].rooms.length,
          cartItem[1].room_type_id,
          cartItem[1].rate_plan_id,
          room_price +
          extra_adult_price +
          extra_child_price -
          cartItem[1].discounted_price,

          //Minhaj's Code
          room_price + extra_adult_price + extra_child_price,
          // added for fixing gst calculation for individual
          room_price - cartItem[1].discounted_price
        );

        cartItem[1].display_price =
          cart[cartItem[0]].price + extra_adult_price + extra_child_price;
      });

      Object.assign(updatedCart, cart);
    }
    setCart(updatedCart); //Setting the updated cart to cart
  };

  //Callback to parent To send the cart data to parent
  const callBackParent = (
    room_type_id,
    rate_plan_id,
    statustype,
    type,
    toggleStatus,
    isUseRef = false
  ) => {
    setSingleOccupency([]);
    let newCart = {};

    if (isUseRef && roomDiv.current) {
      roomDiv.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }

    rate_plan_id = rate_plan_id.toString();
    //Disable all other Room When one room type is selected
    disabledStatus(room_type_id, rate_plan_id, statustype);
    if (cart[room_type_id + rate_plan_id] && type == "add") {
      if (cart[room_type_id + rate_plan_id])
        cart[room_type_id + rate_plan_id].added_to_cart = true;
    } else {
      if (cart[room_type_id + rate_plan_id])
        cart[room_type_id + rate_plan_id].added_to_cart = false;
    }
    Object.assign(newCart, cart);
    setCart(newCart);
    const cartItems = Object.values(cart);
    let addedTCartItems = [];
    if (cartItems.length > 0) {
      cartItems.map((room) => {
        if (room.added_to_cart) {
          addedTCartItems.push(room);
        }
      });
      // console.log(addedTCartItems);
    }
    if (toggleStatus !== "no-toggle") {
      toggleDropdown(room_type_id, rate_plan_id);
    }
    if (room_type_id === 0 && rate_plan_id === 0) {
      //Cart Reset
      props.addToCartCallBack([], [], []);
    } else {
      props.addToCartCallBack(
        addedTCartItems,
        // taxRules,
        coupons,
        appliedCoupon
      );
      // console.log('Applied Coupon' + appliedCoupon);
    }
    if (toggleStatus === "scroll" && window.screen.width <= 480) {
      document.getElementById("addroom").scrollIntoView({
        behavior: "smooth",
      });
    }
    if (coupons && coupons.length > 0) {
      coupons.map((coupon, index) => {
        applyCoupon(
          coupon,
          index === coupons.length - 1 ? true : false,
          coupons
        );
        // setAppliedCoupon(coupon);
      });
      setAppliedCoupon(coupons);
    }
  };

  //Tax calculation rules
  const updateGstPrice = (
    no_of_rooms,
    room_type_id,
    rate_plan_id,
    room_price,
    price_before_discount,
    price_for_individual
  ) => {
    if (cart[room_type_id + rate_plan_id.toString()]) {
      cart[room_type_id + rate_plan_id.toString()].tax.length = 0;
      cart[room_type_id + rate_plan_id.toString()].tax.push({
        // gst_price:
        //   taxRules.tax_type == "GST"
        //     ? getGstPrice(no_of_rooms, room_price, price_before_discount, price_for_individual)
        //     : 0,
        // other_tax:
        //   taxRules.tax_type == "NonGST"
        //     ? getOtherPrice(no_of_rooms, room_type_id, room_price)
        //     : [],

        gst_price: getGstPrice(
          no_of_rooms,
          room_price,
          price_before_discount,
          price_for_individual
        ),
        other_tax: [],
      });
    }
  };
  //Get GST TAX price
  //Minhaj's Changes
  const getGstPrice = (
    no_of_rooms,
    price,
    price_before_discount,
    price_for_individual
  ) => {
    let gstPrice = 0;

    if (localeDetails && localeDetails.gst_slab_id === 1) {
      let gstPercent = checkGSTPercent(
        Math.round(price / no_nights / no_of_rooms)
      );
      gstPrice = (price * gstPercent) / 100;
    }

    if (localeDetails && localeDetails.gst_slab_id === 2) {
      let gstPercent = checkGSTPercent(
        Math.round(price_for_individual / no_nights / no_of_rooms)
      );
      gstPrice = (price * gstPercent) / 100;
    }

    return gstPrice;
  };
  //Get other tax rules and Tax price
  // const getOtherPrice = (no_of_rooms, room_type_id, price) => {
  //   let taxes = [];
  //   if (taxRules.tax_applicable) {
  //     for (let i = 0; i < taxRules.tax_rules.length; i++) {
  //       let tax_price = (price * taxRules.tax_rules[i]["tax_percent"]) / 100;
  //       let tax_object = {
  //         tax_name: taxRules.tax_rules[i]["tax_name"],
  //         tax_price: tax_price,
  //       };
  //       taxes.push(tax_object);
  //     }
  //   }
  //   return taxes;
  // };
  //Check the GST Slabs
  const checkGSTPercent = (price) => {
    let tax_percent = 0;

    if (localeDetails && localeDetails.tax_range_type === "range") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (
            price >= parseFloat(items.start_range) &&
            price <= parseFloat(items.end_range)
          ) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    if (localeDetails && localeDetails.tax_range_type === "single") {
      if (localeDetails.tax_value.length > 0) {
        localeDetails.tax_value.map((items) => {
          if (price >= parseFloat(items.start_range)) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    return tax_percent;

    // if (price <= 1000) {
    //   return 0;
    // } else if (price > 1000 && price <= 7500) {
    //   return 12;
    // } else if (price > 7500) {
    //   return 18;
    // }
  };

  const aboutRoomStyle = {
    display: "inline-block",
    width: "100%",
    fontWeight: 400,
    cursor: "pointer",
  };

  //Set Modal is open Or Not
  //Intially Modal is Open false
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Modal Show or Hide
  const toggleModal = (room_type_id, display_bed_type) => {
    setDisplayBedType(display_bed_type);
    setIsModalOpen(!isModalOpen);
    getRoomDetails(room_type_id);
  };

  //Set Image Slider is open Or Not
  //Intially Image Slider is Open false
  // const [isSliderOpen, setSliderOpen] = useState(false);

  // //Show Image slider
  // const toggleSlider = (room_type_id) => {
  //   setSliderOpen(!isSliderOpen);
  //   setRoomDetails([]);
  //   if (room_type_id != 0) {
  //     getRoomDetails(room_type_id);
  //   }
  // };

  // Method is disabled in reference to BugFix [Date: 29-10-2024], previously used on line 1947 to remove `<p>` tags and their attributes.
  const htmlToReact = (template) => {
    const finalHtml = template.replace(/<p[^>]*>/g, "");
    return parse(finalHtml);
  };

  useEffect(() => {
    let coupon_arr = [];
    displayInventoryNratesList &&
      displayInventoryNratesList.map((il) => {
        const coupon_obj = { room_type: il.room_type, coupon_arr: [] };
        coupons.map((c) => {
          if (c.room_type_id === il.room_type_id || c.room_type_id === 0)
            coupon_obj.coupon_arr.push(c);
        });
        coupon_arr.push(coupon_obj);
      });
    coupon_arr.length > 0 &&
      coupon_arr.map((ca) => {
        let maxValue = Math.max.apply(
          Math,
          ca.coupon_arr.map((coup) => {
            return coup.discount;
          })
        );
        ca.max_discount = maxValue;
      });
    setCouponArr(coupon_arr);
  }, [displayInventoryNratesList, coupons]);

  useEffect(() => {
    props.setAllPublicApplied(allApplied);
  }, [allApplied]);
  const checkBlockStatus = (ratePlan, i) => {
    if (ratePlan && ratePlan[i] && ratePlan[i].rates) {
      let blocked = false;
      ratePlan[i].rates.map((rate) => {
        if (rate.block_status === 1) {
          blocked = true;
        }
      });
      if (blocked) {
        return false;
      } else {
        return true;
      }
    }
  };

  //room images listing
  const changeRoomImage = (img_name, idx) => {
    roomImageEndPoint[idx] = img_name;
    setroomImageEndPoint([...roomImageEndPoint]);
  };
  //end of room images listing

  const [displayMaxRoomCapacity, setDisplayMaxRoomCapacity] = useState(true);

  useEffect(() => {
    if (
      companyId === 2725 ||
      companyId === 2726 ||
      companyId === 2727 ||
      companyId === 2728 ||
      companyId === 2729 ||
      companyId === 2730 ||
      companyId === 2731
    ) {
      setDisplayMaxRoomCapacity(false);
    }
  }, [companyId]);

  // API Call to get isTaxable details [Modify Date: 22-03-2023, Author: Ramanujam a.k.a string1301]
  useEffect(() => {
    if (hotelId != "" && apiKey != 0) {
      getIsTaxableDetails();
    }
  }, [hotelId, apiKey]);

  const getIsTaxableDetails = async () => {
    fetch(
      config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotelId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 1) {
          setIsTaxable(responseJson.data.is_taxable);
        }
      })
      .catch((error) => { });
  };

  return (
    <div className="room-box-left" ref={roomDiv}>
      {/* <Offline>
        <Snackbar
          message={`Your internet got disconnected!`}
          setPopupOpen={setPopupOpen}
        />
      </Offline> */}
      <span>
        <Element name="scroll" className="element" />
      </span>
      {displayInventoryNratesList.length === 0 && (
        <div className="box-shadow-wrapper">
          <RoomPreviewLoader />
        </div>
      )}
      {displayInventoryNratesList.length > 0 &&
        displayInventoryNratesList &&
        displayInventoryNratesList.length > 0 &&
        displayInventoryNratesList.map((inventorynRate, index_inv) => (
          <div
            key={index_inv}
            className={
              index_inv === 0
                ? "box-shadow-wrapper"
                : "box-shadow-wrapper top-space"
            }
          >
            <div className="row">
              <div className="col s12 m4">
                <div
                  className="image-wrap"
                  onClick={() => {
                    toggleModal(
                      inventorynRate.room_type_id,
                      inventorynRate.display_bed_type
                    );
                  }}
                >
                  {couponArr &&
                    couponArr.map((ca, i) => {
                      var discount = 0;
                      if (
                        ca &&
                        ca.room_type === inventorynRate.room_type &&
                        ca.max_discount > 0
                      ) {
                        return (
                          <div key={i} className="ribbon">
                            <span>
                              {ca.value
                                ? ca.value + "%Off"
                                : ca.max_discount && ca.max_discount + "%Off"}
                            </span>
                          </div>
                        );
                      }
                    })}

                  {roomImageEndPoint && roomImageEndPoint[index_inv] && (
                    <img
                      src={
                        config.public_image_url +
                        roomImageEndPoint[index_inv].replace(" ", "+")
                      }
                      alt="Room Image Preview"
                    />
                  )}
                </div>

                {/* About modal box */}
                {/*Modal Structure */}

                {isModalOpen && (
                  <div className="modal-wrapper">
                    <div
                      id="model1"
                      className="modal scroll z-value room-details-modal"
                    >
                      <div className="card" style={{ margin: "0rem" }}>
                        <div className="modal-content padding-bottom-0">
                          <div className="modal-header">
                            <span
                              onClick={() => toggleModal()}
                              className="modalBtnClose"
                              style={{
                                position: "fixed",
                                top: "4.5rem",
                                right: "9rem",
                              }}
                            />
                          </div>

                          {isLoading ? (
                            <RoomDataLoader />
                          ) : (
                            <div className="row margin-bottom-0">
                              <div className="col s12 m6">
                                {roomDetails &&
                                  roomDetails.image &&
                                  roomDetails.image.length > 0 && (
                                    <div className="room-muliple-images">
                                      <RoomMultipleImagesSlider
                                        images={roomDetails.image}
                                      />
                                    </div>
                                  )}
                              </div>

                              <div className="col s12 m6">
                                <h3 style={{ fontWeight: "bold" }}>
                                  {roomDetails.room_type}
                                </h3>
                                {roomDetails.description && (
                                  // BugFix [Date: 29-10-2024] - Disable the block, as the method is removing the `<p>` tags along with their attributes, which is not preserving the original HTML formatting.
                                  // <div>
                                  // {htmlToReact(roomDetails.description + "")}
                                  // </div>
                                  <div className="reset-room-description" dangerouslySetInnerHTML={{ __html: roomDetails.description }} />
                                )}

                                <div className="room-initials">
                                  <div className="row">
                                    {displayBedType === 0
                                      ? null
                                      : roomDetails.bed_type && (
                                        <div className="col s12 m4">
                                          <h3>Bed Type</h3>
                                          <p>{roomDetails.bed_type}</p>
                                        </div>
                                      )}
                                    {roomDetails.room_view_type && (
                                      <div className="col s12 m4">
                                        <h3>Room View Type</h3>
                                        <p>{roomDetails.room_view_type}</p>
                                      </div>
                                    )}
                                    {roomDetails.room_size_value && (
                                      <div className="col s12 m4">
                                        <h3>Room Size</h3>
                                        <p>
                                          {roomDetails.room_size_value}{" "}
                                          {roomDetails.room_size_unit}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {roomDetails.display_room_amenities &&
                                  roomDetails.display_room_amenities.length !==
                                  0 && (
                                    <div className="rooms-amenity-container">
                                      <h3>Room Amenities</h3>
                                      {roomDetails.display_room_amenities.map(
                                        (ammenity, id) => {
                                          return (
                                            <div
                                              className="rooms-amenity-data"
                                              key={id}
                                            >
                                              <span className="rooms-amenity-text">
                                                <i className="fa fa-check-circle-o" />{" "}
                                                {ammenity.hotel_amenities_name}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal-overlay"
                      style={{
                        zIndex: "1002",
                        display: "block",
                        opacity: "0.2",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col s12 m8">
                <div className="room-preview-details">
                  <div className="col s12 m8 heading-wrap">
                    <h2
                      className="tooltip"
                      data-tooltip="Testing"
                      data-position="top"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleModal(inventorynRate.room_type_id)}
                    >
                      {inventorynRate.room_type}
                      <span className="tooltiptext">READ MORE</span>
                    </h2>
                    {inventorynRate.display_bed_type === 0 ? null : (
                      <p
                      // onClick={() => console.log("Status:", blockCount)}
                      >
                        Bed type: {inventorynRate.bed_type}
                      </p>
                    )}
                    {displayMaxRoomCapacity && (
                      <div>
                        <p>
                          Max Room capacity:
                          {inventorynRate.max_occupancy &&
                            inventorynRate.max_occupancy !== 0
                            ? inventorynRate.max_occupancy
                            : inventorynRate.max_people +
                            inventorynRate.extra_person}
                          { } <i className="fa fa-male" />
                          <i className="fa fa-child" />
                        </p>
                      </div>
                    )}
                    <div className="amenities">
                      <ul>
                        {inventorynRate.room_amenities &&
                          inventorynRate.room_amenities.map(
                            (amenity, index) => {
                              if (index < 11)
                                return (
                                  <li key={index}>
                                    <i className={amenity.font_class} />{" "}
                                    {/* {amenity.hotel_amenities_name}{index !== 10 && index < (inventorynRate.room_amenities.length - 2) && <span>,</span>} */}
                                    {amenity.hotel_amenities_name}
                                    {index !== 10 && <span>,</span>}
                                  </li>
                                );
                            }
                          )}
                        {inventorynRate.room_amenities.length > 10 && (
                          <li
                            onClick={() => {
                              toggleModal(
                                inventorynRate.room_type_id,
                                inventorynRate.display_bed_type
                              );
                            }}
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            View all...
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div
                    className="col s12 m4"
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "2%",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="room-price">
                      {/* {inventorynRate.coupons.length === 0 &&
                        inventorynRate.min_room_price > 0 &&
                        inventorynRate.rack_price >
                        inventorynRate.min_room_price && (
                          <span>
                            <p className="save-price">
                              <span>You Save</span>{" "}
                              <i className={currencySymbol} />
                              {currency == baseCurrency ? parseFloat((
                                inventorynRate.rack_price -
                                inventorynRate.min_room_price
                              ).toFixed(0)).toLocaleString('en-IN') : (((
                                inventorynRate.rack_price -
                                inventorynRate.min_room_price
                              ).toFixed(0)) * currencyValue).toFixed(2)}{" "}

                              <span className="tag">
                                {parseFloat((
                                  ((inventorynRate.rack_price -
                                    inventorynRate.min_room_price) /
                                    inventorynRate.rack_price) *
                                  100
                                ).toFixed(0)).toLocaleString('en-IN')}{" "}
                                %
                              </span>
                            </p>
                            <p className="strike-price">
                              <strike>
                                {" "}
                                <i className={currencySymbol} />{" "}

                                {currency == baseCurrency ? (
                                  inventorynRate.rack_price).toLocaleString('en-IN') : (
                                    inventorynRate.rack_price * currencyValue).toFixed(2)}

                              </strike>
                            </p>
                          </span>
                        )} */}
                      {/* {inventorynRate.coupons.length > 0 && (
                        <span>
                          <p className="save-price">
                            <span>You Save</span>{" "}
                            <i className={currencySymbol} />
                            {currency == baseCurrency ? parseFloat(

                              Math.floor(
                                inventorynRate.rack_price -
                                (
                                  inventorynRate.min_room_price -
                                  (inventorynRate.min_room_price *
                                    parseInt(
                                      inventorynRate.coupons.map(
                                        (coupon, index) => {
                                          if (
                                            coupon.room_type_id ===
                                            inventorynRate.room_type_id ||
                                            coupon.room_type_id === 0
                                          ) {
                                            return parseInt(coupon.discount);
                                          }
                                        }
                                      )
                                    )) /
                                  100
                                ).toFixed(0)
                              )).toLocaleString('en-IN') : (
                                (Math.floor(
                                  inventorynRate.rack_price -
                                  (
                                    inventorynRate.min_room_price -
                                    (inventorynRate.min_room_price *
                                      parseInt(
                                        inventorynRate.coupons.map(
                                          (coupon, index) => {
                                            if (
                                              coupon.room_type_id ===
                                              inventorynRate.room_type_id ||
                                              coupon.room_type_id === 0
                                            ) {
                                              return parseInt(coupon.discount);
                                            }
                                          }
                                        )
                                      )) /
                                    100
                                  ).toFixed(0)
                                )) * currencyValue).toFixed(2)}
                            <span className="tag">
                              {" "}
                              {parseFloat(Math.floor(
                                (
                                  ((inventorynRate.rack_price -
                                    (
                                      inventorynRate.min_room_price -
                                      (inventorynRate.min_room_price *
                                        parseInt(
                                          inventorynRate.coupons.map(
                                            (coupon, index) => {
                                              if (
                                                coupon.room_type_id ===
                                                inventorynRate.room_type_id ||
                                                coupon.room_type_id === 0
                                              ) {
                                                return parseInt(
                                                  coupon.discount
                                                );
                                              }
                                            }
                                          )
                                        )) /
                                      100
                                    ).toFixed(2)) /
                                    inventorynRate.rack_price) *
                                  100
                                ).toFixed(2)
                              )).toLocaleString('en-IN')}{" "}
                              %
                            </span>
                          </p>
                          <p className="strike-price">
                            <strike>
                              {" "}
                              <i className={currencySymbol} />{" "}

                              {currency == baseCurrency ? (inventorynRate.rack_price).toLocaleString('en-IN') : (inventorynRate.rack_price * currencyValue).toFixed(2)}

                            </strike>
                          </p>
                        </span>
                      )} */}
                      {inventorynRate.coupons.length > 0 &&
                        inventorynRate.coupons.map((coupon, index) => {
                          if (
                            coupon.room_type_id ===
                            inventorynRate.room_type_id ||
                            coupon.room_type_id === 0
                          ) {
                            return (
                              <p key={index} className="main-price">
                                <span>From</span>{" "}
                                <i className={currencySymbol} />
                                {currency == baseCurrency
                                  ? inventorynRate.min_room_price > 0
                                    ? parseFloat(
                                      (
                                        inventorynRate.min_room_price -
                                        (inventorynRate.min_room_price *
                                          coupon.discount) /
                                        100
                                      ).toFixed(0)
                                    ).toLocaleString("en-IN")
                                    : parseFloat(
                                      (
                                        inventorynRate.rack_price -
                                        (inventorynRate.min_room_price *
                                          coupon.discount) /
                                        100
                                      ).toFixed(2)
                                    ).toLocaleString("en-IN")
                                  : (
                                    (inventorynRate.min_room_price > 0
                                      ? (
                                        inventorynRate.min_room_price -
                                        (inventorynRate.min_room_price *
                                          coupon.discount) /
                                        100
                                      ).toFixed(0)
                                      : (
                                        inventorynRate.rack_price -
                                        (inventorynRate.min_room_price *
                                          coupon.discount) /
                                        100
                                      ).toFixed(2)) * currencyValue
                                  ).toFixed(2)}
                              </p>
                            );
                          }
                        })}
                      {inventorynRate.coupons.length === 0 && (
                        <p className="main-price">
                          {/* <span>From</span> <i className={currencySymbol} />{" "} */}
                          <i className={currencySymbol} />{" "}
                          {currency == baseCurrency
                            ? inventorynRate.min_room_price > 0
                              ? inventorynRate.min_room_price.toLocaleString(
                                "en-IN"
                              )
                              : inventorynRate.rack_price.toLocaleString(
                                "en-IN"
                              )
                            : (
                              (inventorynRate.min_room_price > 0
                                ? inventorynRate.min_room_price
                                : inventorynRate.rack_price) * currencyValue
                            ).toFixed(2)}
                        </p>
                      )}
                      {/* <p className="per-day-price">per room per night</p> */}
                      {/* {inventorynRate.bed_type === "Hall" ? <p className="per-day-price">Per Day</p>
                        : <p className="per-day-price">Per {inventorynRate.bed_type === "Dorm Bed" ? "Bed" : "Room"} / Night</p>} */}
                      {
                        <p className="per-day-price">
                          Per {inventorynRate.bookable_name} / Night
                        </p>
                      }

                      {isTaxable === 1 ? (
                        <p className="per-day-price">Excluding GST</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------------room images listing------------------------------ */}
            <div className="row">
              <div className="col s12 m5">
                <ul className="room-image-list">
                  {inventorynRate &&
                    inventorynRate.allImages.map(function (room_img, i) {
                      if (i < 4) {
                        return (
                          <li
                            key={i}
                            onClick={() =>
                              changeRoomImage(room_img.image_name, index_inv)
                            }
                          >
                            <img
                              className="room-images"
                              src={
                                config.public_image_url +
                                room_img.image_name.replace(" ", "+")
                              }
                              alt="room image"
                            />
                          </li>
                        );
                      }
                    })}
                  <div
                    className="room-images-view-all viewAllRooms"
                    style={{
                      backgroundImage: `url(${config.public_image_url +
                        inventorynRate.image.replace(" ", "+")})`,
                    }}
                    onClick={() => {
                      toggleModal(
                        inventorynRate.room_type_id,
                        inventorynRate.display_bed_type
                      );
                    }}
                  >
                    <span className="view-all-text">View More</span>
                  </div>
                </ul>
              </div>
            </div>
            {/*-------------------end of room images listing------------------------- */}

            {!inventorynRate.block && (
              <div className="row">
                <div className="room-select">
                  {inventorynRate.rate_plans &&
                    inventorynRate.rate_plans.map((ratePlans, index) => {
                      if (
                        inventorynRate.min_inv > 0 &&
                        checkBlockStatus(inventorynRate.rate_plans, index)
                      ) {
                        return (
                          <div
                            key={index}
                            id={
                              inventorynRate.room_type_id +
                              ratePlans.rate_plan_id.toString()
                            }
                            className="row room-select-margin"
                          >
                            {checkBlockStatus(
                              inventorynRate.rate_plans,
                              index
                            ) && (
                                <>
                                  <hr />
                                  <div className="col s12 m4">
                                    <ul>
                                      <li title={ratePlans.plan_name}>
                                        {ratePlans.plan_name}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="col s12 m3 small-device-margin"
                                    style={{ position: "relative" }}
                                  >
                                    <ul>
                                      <li className="room-guest-border waves-effect waves-light">
                                        {/* {inventorynRate.bed_type === "Dorm Bed" ? "Bed|Guests" : "Rooms|Guests"} */}
                                        {inventorynRate.bookable_name}|Guests
                                        {cart[
                                          inventorynRate.room_type_id +
                                          ratePlans.rate_plan_id.toString()
                                        ] &&
                                          ratePlans.rate_plan_id ===
                                          cart[
                                            inventorynRate.room_type_id +
                                            ratePlans.rate_plan_id.toString()
                                          ].rate_plan_id && (
                                            <span className="room-guest-details">
                                              {/*Total Rooms Selected*/}
                                              {cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ] &&
                                                cart[
                                                  inventorynRate.room_type_id +
                                                  ratePlans.rate_plan_id.toString()
                                                ].rooms.length > 0 &&
                                                cart[
                                                  inventorynRate.room_type_id +
                                                  ratePlans.rate_plan_id.toString()
                                                ].rooms.length +
                                                " " +
                                                inventorynRate.bookable_name +
                                                " "
                                                // (inventorynRate.bed_type === "Dorm Bed" ? "Bed" : "Room(s)")
                                              }

                                              {/*Total Adults Selected*/}
                                              {cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ] &&
                                                cart[
                                                  inventorynRate.room_type_id +
                                                  ratePlans.rate_plan_id.toString()
                                                ].rooms.reduce(
                                                  (totalAdults, room) => {
                                                    return (
                                                      totalAdults +
                                                      room.selected_adult
                                                    );
                                                  },
                                                  0
                                                ) + " Adults,"}

                                              {/*Total Child Selected*/}
                                              {cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ] &&
                                                cart[
                                                  inventorynRate.room_type_id +
                                                  ratePlans.rate_plan_id.toString()
                                                ].rooms.reduce(
                                                  (totalChild, room) => {
                                                    return (
                                                      totalChild +
                                                      room.selected_child
                                                    );
                                                  },
                                                  0
                                                ) + " Kids"}
                                            </span>
                                          )}
                                      </li>
                                      {openDropdown[
                                        inventorynRate.room_type_id.toString() +
                                        ratePlans.rate_plan_id.toString()
                                      ] && (
                                          <div className="room-guest-select">
                                            {/* My Code Select Box  */}
                                            <div className="row">
                                              <div className="multiple-room-wrap">
                                                <div
                                                  className="col s12 m12 hide-on-small-only"
                                                  style={{
                                                    fontSize: "80%",
                                                  }}
                                                >
                                                  <span>
                                                    {/* {inventorynRate.bed_type === "Dorm Bed" ? "No. Of Bed" : "No. Of Rooms"} */}
                                                    No. Of{" "}
                                                    {inventorynRate.bookable_name}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div
                                                className="multiple-room-wrap"
                                                style={{ marginTop: "1%" }}
                                              >
                                                <div className="col s12 m12">
                                                  <select
                                                    value={
                                                      selectedNoOfRooms[index_inv]
                                                    }
                                                    onChange={(e) =>
                                                      totalRoomSelected(
                                                        e.target.value,
                                                        index_inv,
                                                        inventorynRate.room_type_id,
                                                        ratePlans.rate_plan_id,
                                                        inventorynRate.min_inv
                                                      )
                                                    }
                                                  >
                                                    {inventorynRate.display_no_of_rooms.map(
                                                      (items, idx) => (
                                                        <option
                                                          value={items}
                                                          key={idx}
                                                        >
                                                          {items}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {/* My Code Select Box  */}

                                            {/* <div className="row">
                                              <div className="multiple-room-wrap">
                                                <div className="col s12 m2 hide-on-small-only" />
                                                <div
                                                  className="col s12 m3 hide-on-small-only"
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "80%",
                                                  }}
                                                >
                                                  <span>Adults</span>
                                                </div>
                                                <div
                                                  className="col s12 m3 hide-on-small-only"
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "80%",
                                                  }}
                                                >
                                                  <span>(5 - 12 yrs)</span>
                                                </div>
                                                <div
                                                  className="col s12 m3 hide-on-small-only"
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "80%",
                                                  }}
                                                >
                                                  <span>(0 &lt; 5 yrs)</span>
                                                </div>
                                              </div>
                                            </div> */}
                                            {cart[
                                              inventorynRate.room_type_id +
                                              ratePlans.rate_plan_id.toString()
                                            ] &&
                                              cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ].rooms &&
                                              cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ].rooms.map((room, index) => (
                                                <div key={index} className="row">
                                                  <div
                                                    className="multiple-room-wrap"
                                                    style={{ marginTop: "1%" }}
                                                  >
                                                    <div className="col s12 m2">
                                                      {/* <span className="room-text">{inventorynRate.bed_type === "Dorm Bed" ? `Bed${index + 1}` : room.room}</span> */}
                                                      <span className="room-text">
                                                        {inventorynRate.bookable_name +
                                                          (index + 1)}
                                                      </span>
                                                    </div>
                                                    <div className="col s12 m3">
                                                      <label
                                                        htmlFor="adult-label"
                                                        className="label-room-booking"
                                                      >
                                                        Adults
                                                      </label>
                                                      <select
                                                        value={room.selected_adult}
                                                        id="adult-label"
                                                        onChange={(e) =>
                                                          handleAdultChange(
                                                            e,
                                                            inventorynRate.room_type_id,
                                                            inventorynRate.max_occupancy,
                                                            ratePlans.rate_plan_id,
                                                            room.room,

                                                            // added to handle extra adult count
                                                            room
                                                          )
                                                        }
                                                      >
                                                        <option value={0}>
                                                          Adults
                                                        </option>
                                                        {room.adults &&
                                                          room.adults.map(
                                                            (adult, index) => (
                                                              <option
                                                                key={index}
                                                                value={adult.value}
                                                              >
                                                                {adult.option}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                    </div>
                                                    <div className="col s12 m3">
                                                      <label
                                                        htmlFor="child-label"
                                                        className="label-room-booking"
                                                      >
                                                        {occupancySetupDetails &&
                                                          occupancySetupDetails.children
                                                          ? occupancySetupDetails.children
                                                          : "\u00A0"}
                                                      </label>
                                                      <select
                                                        name="kids"
                                                        id="child-label"
                                                        value={room.selected_child}
                                                        onChange={(e) =>
                                                          handleChildChange(
                                                            e,
                                                            inventorynRate.room_type_id,
                                                            inventorynRate.max_occupancy,
                                                            ratePlans.rate_plan_id,
                                                            room.room,

                                                            room
                                                          )
                                                        }
                                                      >
                                                        {/* <option value={0}>
                                                          Child
                                                    </option> */}
                                                        {room.childs &&
                                                          room.childs.map(
                                                            (child, index) => (
                                                              <option
                                                                key={index}
                                                                value={child.value}
                                                              >
                                                                {child.option}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                    </div>
                                                    <div className="col s12 m4 infants">
                                                      <label
                                                        htmlFor="infant-label"
                                                        className="label-room-booking"
                                                      >
                                                        {occupancySetupDetails &&
                                                          occupancySetupDetails.infant
                                                          ? occupancySetupDetails.infant
                                                          : "\u00A0"}
                                                      </label>
                                                      <select
                                                        value={room.selected_infant}
                                                        id="infant-label"
                                                        onChange={(e) =>
                                                          handleInfantChange(
                                                            e,
                                                            inventorynRate.room_type_id,
                                                            ratePlans.rate_plan_id,
                                                            room.room
                                                          )
                                                        }
                                                      >
                                                        {/* <option value={0}>
                                                          Infants
                                                    </option> */}
                                                        {room.infants &&
                                                          room.infants.map(
                                                            (infant, index) => (
                                                              <option
                                                                key={index}
                                                                value={infant.value}
                                                              >
                                                                {infant.option}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                    </div>
                                                    {index !== 0 && (
                                                      <span
                                                        onClick={() =>
                                                          removeRoom(
                                                            inventorynRate.room_type_id,
                                                            ratePlans.rate_plan_id,
                                                            "",
                                                            inventorynRate.min_inv,
                                                            // added for select room
                                                            index_inv
                                                          )
                                                        }
                                                        className="material-icons icon deleteIcon hover delete-position"
                                                      >
                                                        delete_outline
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                            <div className="row">
                                              <div className="multiple-room-wrap">
                                                {/* <div className="col s12 m4">
                                              <ul>
                                                <li>
                                                  <a
                                                    disabled={
                                                      addRoomBtnStatus[
                                                        inventorynRate.room_type_id.toString() +
                                                          ratePlans.rate_plan_id.toString()
                                                      ]
                                                        ? "disabled"
                                                        : ""
                                                    }
                                                    className="waves-effect waves-light btn-small btn-guest"
                                                    onClick={() =>
                                                      addRoom(
                                                        inventorynRate.room_type_id,
                                                        ratePlans.rate_plan_id,
                                                        inventorynRate.min_inv
                                                      )
                                                    }
                                                  >
                                                    ADD
                                                  </a>
                                                </li>
                                              </ul>
                                            </div> */}
                                                <div className="col s12 m6">
                                                  <li>
                                                    <a
                                                      className="waves-effect waves-light btn-small btn-guest"
                                                      onClick={() =>
                                                        toggleDropdown(
                                                          inventorynRate.room_type_id,
                                                          ratePlans.rate_plan_id
                                                        )
                                                      }
                                                    >
                                                      CANCEL
                                                    </a>
                                                  </li>
                                                </div>

                                                <div className="col s12 m6">
                                                  {cart[
                                                    inventorynRate.room_type_id +
                                                    ratePlans.rate_plan_id.toString()
                                                  ] && (
                                                      <ul>
                                                        <li>
                                                          <a
                                                            className="waves-effect waves-light btn-small btn-guest-confirm"
                                                            onClick={() =>
                                                              callBackParent(
                                                                inventorynRate.room_type_id,
                                                                ratePlans.rate_plan_id,
                                                                "disable",
                                                                "add",
                                                                "scroll",
                                                                true
                                                              )
                                                            }
                                                          >
                                                            Confirm
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </ul>
                                  </div>

                                  <div className="col s12 m2">
                                    <ul>
                                      {inventorynRate.coupons &&
                                        inventorynRate.coupons.map(
                                          (coupon, index) => {
                                            if (
                                              coupon.room_type_id ===
                                              inventorynRate.room_type_id ||
                                              coupon.room_type_id === 0
                                            ) {
                                              return (
                                                <li
                                                  key={index}
                                                  className="price-small"
                                                // onClick={() =>
                                                //   console.log(
                                                //     "Discount percent: ",
                                                //     coupon.discount
                                                //   )
                                                // }
                                                >
                                                  {" "}
                                                  <span>
                                                    <strike>
                                                      <i
                                                        className={currencySymbol}
                                                      />{" "}
                                                      {currency == baseCurrency
                                                        ? ratePlans.bar_price.toLocaleString(
                                                          "en-IN"
                                                        )
                                                        : (
                                                          ratePlans.bar_price *
                                                          currencyValue
                                                        ).toFixed(2)}
                                                      {/* {ratePlans.bar_price} */}
                                                    </strike>
                                                  </span>
                                                  <br />
                                                  <i
                                                    className={currencySymbol}
                                                  />{" "}
                                                  {currency == baseCurrency
                                                    ? parseFloat(
                                                      (
                                                        ratePlans.bar_price -
                                                        (ratePlans.bar_price *
                                                          coupon.discount) /
                                                        100
                                                      ).toFixed(0)
                                                    ).toLocaleString("en-IN")
                                                    : (
                                                      (
                                                        ratePlans.bar_price -
                                                        (ratePlans.bar_price *
                                                          coupon.discount) /
                                                        100
                                                      ).toFixed(0) *
                                                      currencyValue
                                                    ).toFixed(2)}
                                                  {isTaxable === 1 ? (
                                                    <span className="excluding-tax">
                                                      Excluding Tax
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      {inventorynRate.coupons.length === 0 && (
                                        <li className="price-small">
                                          {" "}
                                          <i className={currencySymbol} />{" "}
                                          {currency == baseCurrency
                                            ? ratePlans.bar_price.toLocaleString(
                                              "en-IN"
                                            )
                                            : (
                                              ratePlans.bar_price *
                                              currencyValue
                                            ).toFixed(2)}
                                          {/* {ratePlans.bar_price} */}
                                          {isTaxable === 1 ? (
                                            <span className="excluding-tax">
                                              Excluding Tax
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )}
                                    </ul>
                                  </div>

                                  <div className="col s12 m3">
                                    {ratePlans.rates &&
                                      ratePlans.rates.length === no_nights ? (
                                      <ul>
                                        <li id="add-room-button">
                                          <a
                                            className="waves-effect waves-light btn-small select-btn"
                                            disabled={
                                              !props.calendarLoaded ||
                                              (cart[
                                                inventorynRate.room_type_id +
                                                ratePlans.rate_plan_id.toString()
                                              ] &&
                                                !cart[
                                                  inventorynRate.room_type_id +
                                                  ratePlans.rate_plan_id.toString()
                                                ].add_room)
                                            }
                                            onClick={() => {
                                              toggleDropdown(
                                                inventorynRate.room_type_id,
                                                ratePlans.rate_plan_id,
                                                inventorynRate.inv[0].los
                                              );
                                              setselectedAdults(
                                                inventorynRate.max_people
                                              );
                                            }}
                                          >
                                            {/* {inventorynRate.bed_type === "Dorm Bed" ? "Add Bed" : "Add Rooms"} */}
                                            Add {inventorynRate.bookable_name}
                                          </a>
                                        </li>
                                      </ul>
                                    ) : (
                                      <ul>
                                        <li>
                                          <a className="waves-effect waves-light btn-small not-available-btn">
                                            Not Available
                                          </a>
                                        </li>
                                      </ul>
                                    )}

                                    <div>
                                      {popupOpen && (
                                        <Snackbar
                                          message={`Minimum staying duration is ${los}!`}
                                          setPopupOpen={setPopupOpen}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            )}
            {(!inventorynRate.rate_plans ||
              inventorynRate.block ||
              inventorynRate.min_inv === 0) && (
                <div className="row" style={{ padding: "20px" }}>
                  <div className="col s12 m4" />
                  <div className="col s12 m3" />
                  <div className="col s12 m2" />
                  <div className="col s12 m3 not-available">
                    <ul>
                      <li>
                        <a className="waves-effect waves-light btn-small not-available-btn">
                          Not Available
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
          </div>
        ))}

      {modalTerms && (
        <div className="modal-wrapper">
          <div id="modal12" className="modal scroll z-value">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <div className="row">
                  <h3 className="center-align">Extra Adult Price</h3>
                  <hr />
                  <div className="coupon-wrapper">
                    {displayMaxRoomCapacity ? (
                      <p>
                        Please be aware that the number of adults entered in
                        your reservation has exceeded the number of adults
                        included in the Room price.The price for an additional
                        adult(s) in this room is{" "}
                        <i className={currencySymbol} />
                        {extraAdultMessage}.
                      </p>
                    ) : (
                      <p>
                        For extra adult @ {extraAdultMessage}/- per night is
                        chargeable.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0" }}>
                <div className="col m5" />
                <div className="col m3">
                  <a
                    style={{ marginBottom: "13%" }}
                    className="waves-effect waves-light btn-small select-terms-btn"
                    onClick={() => setModalTerms(!modalTerms)}
                  >
                    Accept
                  </a>
                </div>
                <div className="col m4" />
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1002", display: "block", opacity: "0.5" }}
          />
        </div>
      )}
      {modalChildTerms && (
        <div className="modal-wrapper">
          <div id="modal12" className="modal scroll z-value">
            <div className="card" style={{ margin: "0rem" }}>
              <div className="modal-content coupon-box">
                <div className="row">
                  <h3 className="center-align">Extra Child Price</h3>
                  <hr />
                  <div className="coupon-wrapper">
                    {displayMaxRoomCapacity ? (
                      <p>
                        Please be aware that the number of children entered in
                        your reservation has exceeded the number of children
                        included in the Room price.The price for an additional
                        children in this room is{" "}
                        <i className={currencySymbol} />
                        {extraChildMessage}.
                      </p>
                    ) : (
                      <p>
                        For child above 5 years extra @ {extraChildMessage}/-
                        per night is chargeable.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0" }}>
                <div className="col m5" />
                <div className="col m3">
                  <a
                    style={{ marginBottom: "13%" }}
                    className="waves-effect waves-light btn-small select-terms-btn"
                    onClick={() => setModalChildTerms(!modalChildTerms)}
                  >
                    Accept
                  </a>
                </div>
                <div className="col m4" />
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            style={{ zIndex: "1002", display: "block", opacity: "0.5" }}
          />
        </div>
      )}
    </div>
  );
};
//Export the Room
export default Rooms;
//
