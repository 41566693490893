import config from '../../config'
import moment from 'moment'
const fetchOtaRates = (hotelId, dateRange, baseCurrency, apiKey, setOtaRates) => {
    let startDate = moment(dateRange.startDate).format("DD-MM-YYYY")
    let endDate = moment(dateRange.endDate).format("DD-MM-YYYY")
    fetch(config.apiEndpoint + 'be/ota-rates', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            date: startDate,
            hotel_id: hotelId,
            api_key: apiKey,
            currency: baseCurrency
        })
    }).then(response => response.json())
        .then(responseJson => {
            // console.log(responseJson, " : ** responseJson");
            if (responseJson.status === 1) {
                setOtaRates(responseJson.data)
            }
        })
        .catch((err) => console.log(err, " ** error"))
}

//Export fetchOtaRates
export default fetchOtaRates
