import config from "../../../config";
import moment from "moment";
const getCouponsForModify = async (hotel_id, setCouponsForModify, datesSelected, modifyBookingData) => {

    let from_date = moment(datesSelected.startDate).format("YYYY-MM-DD");
    let to_date = moment(datesSelected.endDate).format("YYYY-MM-DD");
    let url =
        config.apiEndpoint +
        "be_coupons/public/" +
        hotel_id +
        "/" +
        from_date +
        "/" +
        to_date;
    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-type": "text/json",
        },
    })
        .then((response) => response.json())
        .then((responsejson) => {
            if (responsejson.status == 1) {
                let all_coupons = [];
                responsejson.data.map((coupon_data) => {
                    modifyBookingData[0].booking_object.map((item) => {
                        item.room_type_id = parseInt(item.room_type_id);
                        if (coupon_data.room_type_id === item.room_type_id) {
                            all_coupons.push(coupon_data);
                        }
                        else if (coupon_data.room_type_id === 0) {
                            all_coupons.push(coupon_data);
                        }
                    })
                })
                setCouponsForModify(all_coupons);
            } else {
                setCouponsForModify([]);
            }
        });
};
export default getCouponsForModify;