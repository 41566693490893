import config from "../../../config";
const getCountry = (setAllCountry, SetTravelerLocation, countryID, setUserState) => {
    fetch(
        config.apiEndpoint +
        "get-all-country",
        {
            method: "GET",
            ACCEPT: "application/json",
            Content_type: "application/json",
        }
    )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {

                responseJson.all_country.map((item) => {
                    if (item.country_id === countryID) {
                        setUserState((prevState) => ({
                            ...prevState,
                            countryName: item.country_name,
                            countryCode: item.country_dial_code,
                        }));
                        SetTravelerLocation(item);

                    }
                })

                //changes for sorting alphabetically
                let all_country = responseJson.all_country
                all_country.sort(function (a, b) {
                    var nameA = a.country_name.toLowerCase(), nameB = b.country_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
                //changes for sorting alphabetically

                setAllCountry(responseJson.all_country);
            }
        })
        .catch((e) => console.log(e));
};

//Export getCountry
export default getCountry;