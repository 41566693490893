import config from "../../../config";
const getState = (id, setAllState) => {
    fetch(
        config.apiEndpoint +
        "get-all-states/" + id,
        {
            method: "GET",
            ACCEPT: "application/json",
            Content_type: "application/json",
        }
    )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {


                //changes for sorting alphabetically
                let all_states = responseJson.states
                all_states.sort(function (a, b) {
                    var nameA = a.state_name.toLowerCase(), nameB = b.state_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
                //changes for sorting alphabetically


                setAllState(responseJson.states);

            }
            else {
                setAllState([]);
            }
        })
        .catch((e) => console.log(e));
};

//Export getState
export default getState;