import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <div className='row' style={{ zIndex: '100', position: 'absolute' }}>
        {props.amenitiesList.map((amenity, index) => (
          <div className='col s12 m6' key={index} style={{ zIndex: '1001' }}>
            <Accordion
              expanded={expanded === 'panel' + (index + 1)}
              onChange={handleChange('panel' + (index + 1))}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'>
                <p>
                  {' '}
                  <b> {amenity.category}</b>
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {amenity.amenities.map((a, i) => {
                    return <li key={i}>{a}</li>;
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
}
