import config from '../../config';
const getBaseCurrency= async (company_id,setBaseCurrency)=>{
  fetch(config.apiEndpoint + 'package_booking/basecurrency/'+company_id,{
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      }
      }).then((response) => response.json())
      .then((responseJson) => {
            
            if(responseJson.status===1)
            {
                setBaseCurrency(responseJson.currency_name)
            }
      })
  .catch((error) => {
  });
}
//Export getAccess 
export default getBaseCurrency;