import config from "../../../config";
import moment, { invalid } from "moment";
const getInventorynRatesForModify = async (
    hotelId,
    dateRange,
    currency,
    apiKey,
    modifyBookingData,
    setAvailablityModal,
    setAvailablityLoader,
    setInventoryForModify
    // setInventoryNratesListModify,
    // selectedRoomType,
    // selectedNoOfRooms,
    // selectedRatePlan,
    // setModifyAvailable,
    // setMaxRoomOccupancy,
    // setCart
) => {

    let startDate = moment(dateRange.startDate).format("DD-MM-YYYY");
    let endDate = moment(dateRange.endDate).format("DD-MM-YYYY");

    fetch(
        config.apiEndpoint +
        "bookingEngine/get-inventory/" +
        apiKey +
        "/" +
        hotelId +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        currency,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((responseJson) => {

            if (responseJson.status == 1) {
                let dropDowns = {};
                responseJson.data.map((inv, i) => {
                    inv.rate_plans &&
                        inv.rate_plans.map((rate, index) => {
                            let key =
                                inv.room_type_id.toString() + rate.rate_plan_id.toString();
                            let ob = { [key]: false }; //Initially all set dropdown to false
                            Object.assign(dropDowns, ob);
                        });
                });

                responseJson.data.map((inv_data, i) => {
                    let blockStatusCount = 0;
                    inv_data.inv.map((inv_Details) => {
                        if (parseInt(inv_Details.block_status) === 0) {
                            blockStatusCount++;
                        }
                    });
                    if (blockStatusCount === inv_data.inv.length) {
                        inv_data.block = false;
                    } else {
                        inv_data.block = true;
                    }
                });

                let availabilty_validation_array = [];
                let inventory_for_modify = [];

                responseJson.data.map((inv_data) => {
                    modifyBookingData[0].booking_object.map((item) => {
                        let extra_adult_price = 0
                        let extra_child_price = 0

                        if (inv_data.room_type_id == item.room_type_id) {
                            if (inv_data.min_inv >= item.no_of_rooms) {
                                inv_data.rate_plans.map((rate_plan) => {
                                    if (item.rate_plan_id == rate_plan.rate_plan_id) {
                                        rate_plan.rates.map((rate) => {
                                            extra_adult_price += rate.extra_adult_price;
                                            extra_child_price += rate.extra_child_price;
                                        })
                                    }
                                });
                                inv_data.extra_adult_price = extra_adult_price;
                                inv_data.extra_child_price = extra_child_price;
                                availabilty_validation_array.push(true);
                                inventory_for_modify.push(inv_data);
                            }
                            else {
                                availabilty_validation_array.push(false);
                            }
                        }
                    })
                });

                // console.log(modifyBookingData);


                if (availabilty_validation_array.includes(false)) {
                    setAvailablityModal(true);
                    setAvailablityLoader(false)
                }
                else {
                    setAvailablityModal(false);
                    setAvailablityLoader(false);
                }

                setInventoryForModify(inventory_for_modify);
            }
        })
        .catch((error) => { });
};
export default getInventorynRatesForModify;