import config from "../../../config";
import moment from "moment";
import md5 from "md5";
import ReactGaTagManager from "react-gtm-module";
import { toast } from "react-toastify";

const newBookRequest = (
  hotelId,
  dateRange,
  cart,
  coupon,
  paidservice,
  apiKey,
  token,
  //Refund Protect
  currency,
  total_product_price,
  selectedValueForRefund,
  optedForRefund,
  refundProtectPrice,
  //Refund Protect
  totalAddonPrice,
  totalGstAddonPrice,
  addOnChargesData,
  guestNote,
  arrivalTime,
  checkedStatus,
  refundProtectTaxPrice,
  partialPayPercent,
  selectedPaymentOptn,
  hotelData,
  userState
) => {
  // added for checking the hotel_id
  let check_hotel_id = sessionStorage.getItem("check_hotel_id");
  check_hotel_id = parseInt(check_hotel_id);

  let hash_key_value = btoa(check_hotel_id);

  if (check_hotel_id === hotelId) {
    let reference = sessionStorage.getItem("reference");
    var userCoupon;
    const gemsValue = sessionStorage.getItem("gems");
    const cart_with_coupons = JSON.parse(
      sessionStorage.getItem("new_cart_with_coupons")
    );

    var userCouponarray = [];
    var coupon_from_date = moment(dateRange.startDate).format("YYYY-MM-DD");
    var coupon_to_date = moment(dateRange.endDate).format("YYYY-MM-DD");
    let dateArray = getDateArray(coupon_from_date, coupon_to_date);
    let getDateRange = [];
    for (var i = 0; i < dateArray.length; i++) {
      getDateRange.push({
        valid_from: dateArray[i],
        valid_to: dateArray[i + 1],
      });
    }
    if (coupon.length === undefined) {
      userCouponarray.push(coupon);
    } else {
      userCouponarray = coupon;
    }

    let source_value = sessionStorage.getItem("source");

    if (!source_value) {
      source_value = "website";
    }

    //refund protect
    let refund_protect_data;
    if (optedForRefund.status === 1 && checkedStatus === false) {
      refund_protect_data = {
        productCode: "HTL",
        currencyCode: currency,
        productPrice: total_product_price,
        premiumRate: optedForRefund.mode === "optional" ? 9 : 7,
        offeringMethod:
          optedForRefund.mode === "optional" ? "OPT-IN" : "OPT-OUT",
        sold: selectedValueForRefund === 1 ? true : false,
        refundable_cancelation_status: optedForRefund.status,
        refundable_cancelation_mode: optedForRefund.mode,
        refund_protect_price: refundProtectPrice + refundProtectTaxPrice,
      };
    } else {
      refund_protect_data = {};
    }
    //refund protect

    let url = config.apiEndpoint + "bookingEngine/bookings/" + apiKey;
    let from_date = moment(dateRange.startDate).format("DD-MM-YYYY");
    let to_date = moment(dateRange.endDate).format("DD-MM-YYYY");

    let from_date_checksum = moment(dateRange.startDate).format("DD-MM-YYYY");
    let to_date_checksum = moment(dateRange.endDate).format("DD-MM-YYYY");
    let hotel_id_checksum = hotelId;
    let api_key_checksum = apiKey;
    let total_amount_checksum = cart[0].paid_amount;
    // let modified_cart = {};
    // const cartValue = () => {
    //   if (parseFloat(partialPayPercent) == 0) {
    //     cart.map((obj) => {
    //       obj.paid_amount = 0;
    //     })
    //   }
    //   return cart
    // }
    // cartValue()
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        hotel_id: hotelId,
        from_date: from_date,
        to_date: to_date,
        // coupon: userCouponarray,
        coupon: cart_with_coupons,
        // cart: cartValue(),
        cart: cart,
        paid_service: paidservice,
        visitors_ip: "1.1.1.1",
        reference: reference,

        //refund protect
        refund_protect_data: refund_protect_data,
        //refund protect

        type: "website",

        hash_key_value: hash_key_value,
        source: source_value,

        booking_reference: md5(
          `${from_date_checksum}|${api_key_checksum}|${hotel_id_checksum}|${total_amount_checksum}|${to_date_checksum}`
        ),

        total_addon_price: totalAddonPrice,
        total_gst_addon_price: totalGstAddonPrice,
        addon_charges_name:
          addOnChargesData.length > 0
            ? addOnChargesData[0].add_on_charges_name
            : "",
        guest_note: guestNote,
        arrival_time: arrivalTime,
        company_name: userState.companyName,
        GSTIN: userState.GSTIN,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 1) {
          // get the total room type price
          const totalValue = cart.reduce(
            (accumulator, item) => accumulator + item.price,
            0
          );

          // get the total tax of room type
          const totaltax = cart.reduce(
            (accumulator, item) => accumulator + item.tax[0].gst_price,
            0
          );

          // custom cart details for google tag
          const arrayGaItem = cart.map((item, index) => {
            return {
              item_id: item.room_type_id,
              item_name: item.room_type,
              affiliation: hotelData.hotel_name,
              coupon: "",
              discount: item.discounted_price,
              index: index,
              item_brand: "",
              item_category: "",
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: "",
              item_list_name: "",
              item_variant: "",
              location_id: "",
              price: item.price,
              quantity: item.rooms.length,
            };
          });

          // Access value of google tag id stored in session
          var google_tag_id = sessionStorage.getItem("google_tag_id");
          const tagManagerArgs = {
            gtmId: google_tag_id,
            dataLayer: {
              category: "Ecommerce",
              action: "Paynow",
              transaction_id: responseJson.invoice_id,
              value: totalValue,
              tax: totaltax,
              shipping: 0,
              currency: "INR",
              coupon: "",
              items: arrayGaItem,
            },
          };

          ReactGaTagManager.initialize(tagManagerArgs);

          if (selectedPaymentOptn === "payathotel") {
            let payAtHotelUrl = `https://be.bookingjini.com/pay-at-hotel-success/${
              responseJson.invoice_id
            }/pay_at_hotel`;
            // window.location.assign(payAtHotelUrl);
            fetch(payAtHotelUrl)
              .then((resp) => resp.json())
              .then((jsonResp) => {
                // console.log(jsonResp, " : jsonResp")
                if (jsonResp.status === 1) {
                  window.location.assign(jsonResp.data);
                }
              });
          } else {
            // if (JSON.parse(sessionStorage.getItem("source")) == true) {
            //   bookOffline(responseJson.invoice_id, gemsValue);
            // } else {
            if (sessionStorage.getItem("source") == "google") {
              localStorage.setItem("google-ads", true);
            }
            var invoice_id = btoa(responseJson.invoice_id);
            if (hotelData.payment_provider == "billdesk") {
              fetch(`https://be.bookingjini.com/billdesk-orderid/${invoice_id}`)
                .then((response) => response.json())
                .then((responseJson) => {
                  if (responseJson.status == 1) {
                    var flow_config = {
                      merchantId: responseJson.data.merchant_id,
                      bdOrderId: responseJson.data.bdorder_id,
                      authToken: responseJson.data.otoken,
                      childWindow: true,
                      returnUrl: "https://be.bookingjini.com/billdesk-response",
                      // retryCount: 3,
                      prefs: {
                        payment_categories:
                          responseJson.data.payment_categories,
                        // allowed_bins: ["459150", "525211"],
                      },
                      netBanking: {
                        showPopularBanks: responseJson.data.showPopularBanks,
                        popularBanks: responseJson.data.popularBanks,
                      },
                    };
                    var responseHandler = function(txn) {
                      if (txn.status == 111) {
                        toast.error("Booking Failed");
                        window.location.reload();
                      }
                    };
                    var theme_config = {
                      sdkPrimaryColor: responseJson.data.sdkPrimaryColor,
                      sdkAccentColor: responseJson.data.sdkAccentColor,
                      sdkBackgroundColor: responseJson.data.sdkBackgroundColor,
                      sdkBannerColor: responseJson.data.sdkBannerColor,
                    };
                    var config = {
                      responseHandler: responseHandler,
                      merchantLogo: responseJson.data.logo,
                      flowConfig: flow_config,
                      flowType: "payments",
                      themeConfig: theme_config,
                    };

                    window.loadBillDeskSdk(config);
                  }
                });
            } else {
              let str = window.location.origin;
              // let str = "https://heritancekandamal.bookingjini.com/property";

              if (str.search("bookingjini.com") >= 0) {
                var url =
                  "https://be.bookingjini.com/payment/" +
                  invoice_id +
                  "/" +
                  responseJson.invoice_secureHash;
              } else {
                var url =
                  str +
                  "/payment/" +
                  invoice_id +
                  "/" +
                  responseJson.invoice_secureHash;
              }
              window.location.assign(url);
            }
          }
        } else {
          console.log(responseJson.message);
        }
      });
  }
};

// const bookOffline = (invoice_id, gems) => {
//   console.log("called");
//   const token = sessionStorage.getItem("token");
//   const url = config.apiEndpoint + "gems-booking/" + invoice_id + "/" + gems;
//   // console.log("URL:" + url);
//   fetch(url, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "content-type": "text/json",
//       Authorization: "Bearer " + token,
//     },
//   })
//     .then((response) => response.json())
//     .then((responseJson) => {
//       if (responseJson.status === 1) {
//         // localStorage.setItem('test', JSON.stringify(responseJson));
//         let url1 = window.location.href + "";
//         const url = url1.replace("property", "invoice/" + invoice_id + "/123");
//         window.location.assign(url);
//       }
//     })
//     .catch((error) => console.log(error));
// };
const getDateArray = (startDate, stopDate) => {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
};

export default newBookRequest;
