import config from '../../config';
const userLogin = async (setToken, mobileno,redirectToUser)=>{
  fetch(config.apiEndpoint +'public_user/post',
    {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({mobileno:mobileno})
    }).then((response) => response.json())
      .then((responseJson) => {
            if(parseInt(responseJson.status)===1)
            {
              setToken(responseJson.auth_token)
                redirectToUser(responseJson.auth_token)
            }
      })
  .catch((error) => {
  });
}
//Export getAccess 
export default userLogin;