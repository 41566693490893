import config from '../../../config';
import moment from 'moment';
import applyPrivateCoupon from './applyPrivateCoupon';
const validateCupon = (
  coupon_code,
  hotel_id,
  dateRange,
  setIsModalOpen,
  setErrMessage,
  setValidCoupon,
  // setAppliedPrivateCoupon,
  // cart

  //changes
  all_rooms,
  setAppliedPublicCoupon,
  setPublicCouponApplied,
  setPrivateCouponApplied,

  manageAllAppliedPublicCoupons,
  setManageAllAppliedPublicCoupons,
  cartData
) => {
  let from_date = moment(dateRange.startDate).format('DD-MM-YYYY');
  let to_date = moment(dateRange.endDate).format('DD-MM-YYYY');
  let url = config.apiEndpoint + 'coupons/check_coupon_code';
  let allcoupons = [];

  //My Code
  let convert_to_date = moment(dateRange.endDate).format('YYYY-MM-DD');
  let end_date = moment(convert_to_date)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  //My Code
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      hotel_id: hotel_id,
      checkin_date: from_date, //From date
      checkout_date: to_date, //To date
      code: coupon_code
    })
  })
    .then(response => response.json())
    .then(responseJson => {

      if (responseJson.status === 1) {
        let coupon_to_date = moment(responseJson.data[0].valid_to).format(
          'YYYY-MM-DD'
        );
        let coupon_end_date = moment(coupon_to_date)
          .format('YYYY-MM-DD');

        let check_out_date = moment(end_date);
        let coupon_expiry_date = moment(coupon_end_date);

        let checkOutDate = [];

        cartData.forEach((cart) => {
          cart.rooms.forEach((roomsData) => {
            roomsData.day_wise_rates.forEach((dayRates) => {
              checkOutDate.push(dayRates.date);
            });
          });
        });

        coupon_expiry_date = moment(coupon_expiry_date).format("YYYY-MM-DD")

        if (checkOutDate[0] <= coupon_expiry_date) {
          if (responseJson.data[0].room_type_id === 0) {
            // setValidCoupon([responseJson.data]);
            setValidCoupon(responseJson.data);

            setIsModalOpen(false);
            setAppliedPublicCoupon({});
            setPublicCouponApplied(false);
            setPrivateCouponApplied(true);
            setErrMessage('');


            let public_coupons_apply = [];
            manageAllAppliedPublicCoupons.map((items) => {
              public_coupons_apply.push({ status: false, room_type_id: items.room_type_id })
            })
            setManageAllAppliedPublicCoupons(public_coupons_apply);


          } else {
            if (all_rooms) {
              // setValidCoupon([responseJson.data]);
              const responseValue = responseJson.data.filter((responseData) => {
                return all_rooms.includes(responseData.room_type_id);
              });
              if (responseValue.length === 0) {
                setErrMessage(
                  'Sorry, This coupon is not applicable to the selected room types!'
                );
                return;
              } else {
                setValidCoupon(responseValue);
                setErrMessage('');
              }


              setIsModalOpen(false);
              setPublicCouponApplied(false);
              setPrivateCouponApplied(true);


              let public_coupons_apply = [];
              manageAllAppliedPublicCoupons.map((items, i) => {
                public_coupons_apply.push({ status: true, room_type_id: items.room_type_id })
                // if (public_coupons_apply[i].room_type_id === responseJson.data[0].room_type_id) {
                //   public_coupons_apply[i].status = false;
                // }

                public_coupons_apply.forEach((coupon) => {
                  responseJson.data.forEach((response) => {
                    if (String(coupon.room_type_id) === String(response.room_type_id)) {
                      coupon.status = false;
                    }
                  });
                });



                // if (public_coupons_apply[i].room_type_id === 0) {
                //   public_coupons_apply[i].status = false;
                // }

              })
              setManageAllAppliedPublicCoupons(public_coupons_apply);

            } else {
              setErrMessage(
                'Sorry, This coupon is not applicable to the selected room types!'
              );
            }
          }
        } else {
          setErrMessage('Sorry, This Coupon Validity has expired!');
        }
      } else {
        setErrMessage('Invalid coupon');
      }
    });
};

//Export validateCupon
export default validateCupon;
