import config from "../../../config";

const fetchAddonChargesData = (hotel_id, setAddOnChargesData) => {

    fetch(`${config.apiEndpoint}add-on-charges/${hotel_id}`,
        {
            method: "GET",
            ACCEPT: "application/json",
            Content_type: "application/json",
        }
    )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {
                setAddOnChargesData(responseJson.data);
            }

        })
        .catch((e) => console.log(e));


}
export default fetchAddonChargesData;