import config from "../../../config";
import moment, { invalid } from "moment";
// const fetchPackages=(hotelId,currency,setPackageInventory,setConvertedPackageInventory,setLoading)=>{
//     if(hotelId && currency){
//         let url = config.apiEndpoint + 'package_booking/retrive_details/'+ hotelId+'/'+currency
//         fetch(url,{
//             method:'GET',
//             headers:{
//                 Accept:'application/json',
//                 'Content-Type':'application/json'
//             }
//         }).then((response)=>response.json())
//         .then(responseJson=>{
//             setLoading(false)
//             if(responseJson.status===1){
//                 setPackageInventory(responseJson.package_inventory)
//                 setConvertedPackageInventory(responseJson.converted_package_inventory)
//             }
//         })
//     }
// }
const fetchPackages = (
  hotelId,
  dateRange,
  currency,
  setPackageInventory,
  setConvertedPackageInventory,
  setpackageImageEndPoint,
  setLoading,
  setOpenDropdown
) => {
  let startDate = moment(dateRange).format("DD-MM-YYYY");
  if (hotelId && currency) {
    let url =
      config.apiEndpoint +
      "package_booking/get_package_details/" +
      hotelId +
      "/" +
      startDate +
      "/" +
      currency;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        if (responseJson.status === 1) {
          setPackageInventory(responseJson.package_inventory);
          // console.log("Package Inventory :" + JSON.stringify(responseJson));
          setConvertedPackageInventory(
            responseJson.converted_package_inventory
          );
          //-------------------package images listing----------------//
          let first_image = [];
          responseJson.converted_package_inventory.map((package_image, i) => {
            first_image.push(package_image.image_name)
          })
          setpackageImageEndPoint(first_image);
          //--------------------end of package images listing---------------//

          //---------------------bind multiple no of packages-----------------------//
          let dropDowns = {};
          responseJson.converted_package_inventory.map((packages_data, index) => {
            let display_no_of_packages = [];
            for (let i = 1; i <= packages_data.min_inv; i++) {
              display_no_of_packages.push(i);
            }
            packages_data.display_no_of_packages = display_no_of_packages;
          });
          setOpenDropdown(dropDowns);

          //---------------------bind multiple no of packages-----------------------//
        }
      });
  }
};
//Export Default
export default fetchPackages;
