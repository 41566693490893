import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./css/materialize.css";
import "./css/index.css";
import "./css/queries.css";
import "./css/icon.css";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dates/lib/css/_datepicker.css";
import "./css/style.css";
import BookingComponent from "./components/BookingComponent";
import HotelListComponent from "./components/HotelListComponent";
import UserComponent from "./components/UserComponent";
import NotFoundComponent from "./components/NotFoundComponent";
import PackageComponent from "./components/PackageComponent";
import InvoiceComponent from "./components/User/InvoiceComponent";
import { createBrowserHistory } from "history";
import config from "./config";

// Slick Carousel CSS

import Store, { NotFoundContext } from "./settings/Store";
// reload the app

import ThemeSwitcher from "react-css-vars";
import thankYouComponent from "./components/User/thankYouComponent";

import RouteChangeTracker from "./components/google-analytics/RouteChangeTracker";

import { hotjar } from 'react-hotjar'
import ReactPixel from 'react-facebook-pixel';


const history = createBrowserHistory();
const App = () => {
  const [notFound, setNotFound] = useState(false);

  const [themeColor, setThemeColor] = useState({ header_bgcolor: '', theme_color: '', web_menu_color: '', mobile_menu_color: '', mobile_header_bgcolor: '' });


  let curThirdPopupState = JSON.parse(localStorage.getItem("thirdPopupState"));
  if (!curThirdPopupState) {
    setThirdPopupState(1);
  }
  if (curThirdPopupState) {
    if (curThirdPopupState !== 3) {
      setThirdPopupState(curThirdPopupState + 1);
    } else {
      localStorage.setItem("thirdPopupDisplayed", true);
    }
  }
  function setThirdPopupState(state) {
    localStorage.setItem("thirdPopupState", state);
  }


  const options = {
    autoConfig: true,
    debug: false,
  };

  useEffect(() => {
    hotjar.initialize(3153059, 6)


    let facebook_pixel_data = JSON.parse(sessionStorage.getItem('facebook_pixel_data'));
    if (facebook_pixel_data && facebook_pixel_data.id) {
      ReactPixel.init(facebook_pixel_data.id, options);
      ReactPixel.pageView();
    }

  }, [])


  useEffect(() => {
    sessionStorage.setItem("theme_color", JSON.stringify(themeColor));
  }, [themeColor]);

  return (
    <div>
      {notFound ? (
        <NotFoundComponent />
      ) : (
        <Store setNotFound={setNotFound} setThemeColor={setThemeColor}>
          <ThemeSwitcher theme={themeColor}>
            <Router>
              <Switch>
                <Route
                  history={history}
                  exact
                  path={config.baseUrl + "/"}
                  component={HotelListComponent}
                />
                <Route
                  history={history}
                  exact
                  path={config.baseUrl + "property"}
                  component={BookingComponent}
                />
                <Route
                  history={history}
                  exact
                  path={config.baseUrl + "user"}
                  component={UserComponent}
                />
                <Route
                  history={history}
                  exact
                  path={config.baseUrl + "packages"}
                  component={PackageComponent}
                />
                {/* <Route
                  history={history}
                  exact
                  path={config.baseUrl + "invoice/:invoice_id/:trxn_id"}
                  component={InvoiceComponent}
                /> */}


                <Route
                  history={history}
                  exact
                  path={config.baseUrl + "thank-you/:invoice_id/:trxn_id"}
                  component={thankYouComponent}
                />

                <Route exact path="*" component={NotFoundComponent} />
              </Switch>
              <RouteChangeTracker />
            </Router>
          </ThemeSwitcher>
        </Store>
      )}
    </div>
  );
};
export default App;
