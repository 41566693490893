import React, { useState, useEffect, useContext } from "react";
// import jini from '../../img/bookingjiniLogo.png';
import jini from "../../img/logo_orange.png";
import maestrocard from "../../img/maestrocard.png";
import mastercard from "../../img/mastercard.png";
import rupay from "../../img/rupay.png";
import googlepay from "../../img/google-pay.png";
import pci from "../../img/pci.png";
import visa from "../../img/visa.png";
import facebookLogo from "../../img/facebook-logo.png";
import twitter from "../../img/twitter.png";
import linkedIn from "../../img/linkedIn.png";
import instagram from "../../img/instagram-logo.png";
import fetchHotelInfo from "../HotelInfo/fetchHotelInfo";
import { HotelIdContext, ApiKeyContext } from "../../settings/Store";
import config from "../../config";
const Footer = () => {
  const [hotelId] = useContext(HotelIdContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [hotelInfo, setHotelInfo] = useState({});
  useEffect(() => {
    if (hotelId !== 0 && apiKey !== "") {
      fetchHotelInfo(hotelId, apiKey, setHotelInfo);
    }
  }, [hotelId, apiKey, setHotelInfo]);


  const [versionData, SetVersionData] = useState();

  useEffect(() => {
    fetch(
      `${config.apiEndpoint}get-be-version-data`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(responseJson => {

        SetVersionData(responseJson);


      })
      .catch(error => { });
  }, [])


  return (
    <footer className="footer-login">
      <div className="container container-width top-margin">
        <div className="row">
          {/* <div className="col s12 m6">
            <h2>We accept payment mode</h2>
            <div className="col s4 m2 center-align">
              <img src={maestrocard} alt="Maestro card icon" />
            </div>
            <div className="col s4 m2 center-align">
              <img src={mastercard} alt="Master card icon" />
            </div>
            <div className="col s4 m2 center-align">
              <img src={rupay} alt="Rupay card icon" />
            </div>
            <div className="col s4 m2 center-align">
              <img src={googlepay} alt="Google pay card icon" />
            </div>
            <div className="col s4 m2 center-align">
              <img src={pci} alt="Pci card icon" />
            </div>
            <div className="col s4 m1 center-align">
              <img src={visa} alt="Visa card icon" />
            </div>
            <div className="col s4 m1" />
          </div> */}

          <div className="col s12 m3">
          </div>

          <div className="col s12 m6">
            <h2>Follow us</h2>
            <div className="col s3 m2 hide-small" />
            <div className="col s3 m2 center-align">
              <a href={hotelInfo.facebook_link ? hotelInfo.facebook_link : "#"}>
                <img src={facebookLogo} alt="Facebook icon" />
              </a>
            </div>
            <div className="col s3 m2 center-align">
              <a href={hotelInfo.twitter_link ? hotelInfo.twitter_link : "#"}>
                <img src={twitter} alt="Twitter icon" />
              </a>
            </div>
            <div className="col s3 m2 center-align">
              <a
                href={hotelInfo.linked_in_link ? hotelInfo.linked_in_link : "#"}
              >
                <img width={"30px"} src={linkedIn} alt="Linkedin icon" />
              </a>
            </div>
            <div className="col s3 m2 center-align">
              <a
                href={hotelInfo.instagram_link ? hotelInfo.instagram_link : "#"}
              >
                <img src={instagram} alt="instagram icon" />
              </a>
            </div>
            <div className="col s3 m2 center-align" />
          </div>

          <div className="col s12 m3">
          </div>
        </div>

        <div className="row top-border">
          <div className="col s12 m6">
            <h2>
              Copyright © {versionData && versionData.year} {hotelInfo.hotel_name}. All Rights Reserved.
            </h2>
          </div>

          <div className="col s12 m6" style={{ color: "#fff" }}>
            <h2>
              Powered by{"   "}
              <a href="https://bookingjini.com" target="_blank">
                <span style={{ fontWeight: 600, color: "#f37d29" }}>
                  BookingJini{"   "}
                  <i style={{ fontWeight: 300, color: "#fff" }}>
                    Version: {versionData && versionData.version}
                  </i>
                </span>
              </a>
            </h2>
            {/* <div className="col s12 m3" style={{ color: "#fff" }}>
              <a href="https://bookingjini.com/">
                <img
                  src={jini}
                  alt="BookingJini Logo"
                  style={{ width: "150px" }}
                />
              </a>
              <p />
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

//Export Footer
export default Footer;
