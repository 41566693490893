import React from 'react'
import  '../../css/hotelAboutLoader.css'
const AboutLoader=()=>{
    return(
        <div className="row">
            <div className="aboutHotelLoader">
            </div>
        </div>
    )
}

//Export hoterListLoader
export default AboutLoader