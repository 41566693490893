import config from '../../config';
const fetchInvoiceData = async (invoice_id, setInvocieData) => {
    fetch(config.apiEndpoint + 'bookingEngine/invoice-data/' + invoice_id, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }).then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {
                let invoicedata = responseJson.data
                setInvocieData(invoicedata);
                // console.log(invoicedata, " :: invoicedata");
            }
        })
        .catch((error) => {
            console.log(error, " error while fetching Invoice Data");
        });
}
export default fetchInvoiceData;