import config from '../../config';
import moment from "moment";
const fetchPublicCoupons = async (hotel_id, setPublicCoupons, start_date, end_date, dateRange) => {

    let from_date = moment(start_date).format("YYYY-MM-DD");
    let to_date = moment(end_date).format("YYYY-MM-DD");
    let url =
        config.apiEndpoint +
        "be_coupons/public/" +
        hotel_id +
        "/" +
        from_date +
        "/" +
        to_date;
    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-type": "text/json",
        },
    })
        .then((response) => response.json())
        .then((responsejson) => {
            if (responsejson.status == 1) {
                setPublicCoupons(responsejson.data);

            } else {
                setPublicCoupons([]);
            }
        });
};
//Export the getTaxRules
export default fetchPublicCoupons;
