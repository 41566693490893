import config from "../../../config";


const getLocaleDetails = (hotel_id, setLocaleDetails) => {

    fetch(`${config.kEndpoint}locale-details/${hotel_id}`,
        {
            method: "GET",
            ACCEPT: "application/json",
            Content_type: "application/json",
        }
    )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {
                setLocaleDetails(responseJson.states)
            }
        })
        .catch((e) => console.log(e));

}

export default getLocaleDetails;