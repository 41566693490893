import React, { useState, useEffect, useContext } from 'react';
import parse from 'html-react-parser';
// importing image for About tab
import ExternalImageSlide from './externalImageSlide';

import config from '../../config';
import AboutLoader from './aboutLoader';
import tripAdvisor from '../../img/tripadvisor-logotype.png';
import whatsapp from '../../img/whatsapp.png';
import holidayIq from '../../img/holidayIq.png';
import Amenities from './Amenities';
import { HotelIdContext } from '../../settings/Store';

import { Rating } from 'react-simple-star-rating'

const hotelAboutNew = props => {
    // Fetching data from API
    let regex1 = /(&nbsp;|<strong>|<\/strong>|↵↵|↵|)/gm;

    let airports = props.hotelInfo.airport_name;
    airports = airports && airports.replace(/[\[\]']+/g, '');
    airports = airports && airports.replace(/["]+/g, '');
    airports = airports && airports.split(',');

    let distance_airport = props.hotelInfo.distance_from_air
    distance_airport = distance_airport && distance_airport.split(',');

    let rail_station = props.hotelInfo.rail_station_name;
    rail_station = rail_station && rail_station.replace(/[\[\]']+/g, '');
    rail_station = rail_station && rail_station.replace(/["]+/g, '');
    rail_station = rail_station && rail_station.split(',');

    let distance_rail_station = props.hotelInfo.distance_from_rail
    distance_rail_station = distance_rail_station && distance_rail_station.split(',');


    let bus_stop = props.hotelInfo.bus_station_name;
    bus_stop = bus_stop && bus_stop.replace(/[\[\]']+/g, '');
    bus_stop = bus_stop && bus_stop.replace(/["]+/g, '');
    bus_stop = bus_stop && bus_stop.split(',');

    let distance_bus_stop = props.hotelInfo.distance_from_bus
    distance_bus_stop = distance_bus_stop && distance_bus_stop.split(',');

    let hotel_name = props.hotelInfo.hotel_name;
    let rail_station_name = props.hotelInfo.rail_station_name;
    let hotel_description = props.hotelInfo.hotel_description;
    let land_line = props.hotelInfo.land_line;
    // let star_rating = props.hotelInfo.star_of_property;
    // let star_rating = props.hotelInfo.total_star_property;



    const [hotelId] = useContext(HotelIdContext);
    const [hotelAmenities, setHotelAmenities] = useState();
    const [selectedAmenities, setSelectedAmenities] = useState([]);


    useEffect(() => {
        let url = config.kEndpoint + 'hotel_amenities/some';
        fetch(url,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "content-type": "text/json",
                },

            })
            .then(res => res.json())
            .then(responseJson => {

                let display_amenities_array = []

                let url = config.kEndpoint + '/hotel_amenities/hotelAmenity/' + hotelId;
                fetch(url, { method: 'GET' })
                    .then(res => res.json())
                    .then(resJson => {
                        if (resJson && resJson.amenities && resJson.amenities.facility) {
                            let selected_amenities;
                            selected_amenities = resJson.amenities.facility.split(',');
                            setSelectedAmenities(selected_amenities);
                            for (let i = 0; i < responseJson.amenities.length; i++) {
                                display_amenities_array.push({ category_icon: responseJson.amenities[i].category_icon, catgegories: responseJson.amenities[i].catgegories, amenity_name: [] })
                                for (let j = 0; j < responseJson.amenities[i].amenity_name.length; j++) {
                                    let hotel_amenities_id = responseJson.amenities[i].amenity_name[j].hotel_amenities_id.toString();
                                    if (selected_amenities.includes(hotel_amenities_id)) {
                                        display_amenities_array[i].amenity_name.push(responseJson.amenities[i].amenity_name[j].hotel_amenities_name);
                                    }
                                }
                            }
                        }
                    });
                setHotelAmenities(display_amenities_array);
            });
    }, [hotelId]);






    // Striping html tags using regex expression
    var regex = /(<([^>]+)>)/gi;
    let about_hotel = hotel_description + '';
    const removeAllHtmlTags = str => {
        // console.log('test string:' + str);
        if (str === null || str === '') return false;
        if (str.trim() == '.') return false;
        else str = str.toString();
        return str.replace(/<[^>]*>/g, '').trim();
    };
    about_hotel = about_hotel.replace(/\&nbsp;/g, ''); //Removes the &nbsp
    let hotelImage =
        props.hotelInfo.exterior_image &&
        config.public_image_url +
        props.hotelInfo.exterior_image[0].image_name.replace(' ', '+');

    // Uppercase text to Capitalize
    hotel_name = hotel_name + ''.toLowerCase();

    function htmlToReact(hotel_policy_value) {
        return parse(hotel_policy_value);
    }
    let checkIn = props.hotelInfo.check_in + '';
    let checkOut = props.hotelInfo.check_out + '';

    let round_clock_check_in_out = props.hotelInfo.round_clock_check_in_out;

    // console.log('Here');
    // let hotel_policy = props.hotelInfo.hotel_policy + '';
    // console.log(hotel_policy);

    const email_id_arr =
        props.hotelInfo.email_id && props.hotelInfo.email_id.split(',');
    const mobile_arr =
        props.hotelInfo.mobile && props.hotelInfo.mobile.split(',');
    // Function convert first character to uppercase
    function titleCase(string) {
        return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }

    //Set Image Slider is open Or Not
    //Intially Image Slider is Open false
    const [isSliderOpen, setSliderOpen] = useState(false);

    const removeHtmlAndTrim = input => { };

    const hotel_amenities = props.hotelInfo.facility;
    let amenities;
    if (hotel_amenities) {
        amenities = hotel_amenities.map(a => a.hotel_amenities_name);
    }

    let tourist_places = (props.hotelInfo.nearest_tourist_places + '').replace(
        regex1,
        ''
    );

    let imp_tourist_places = (props.hotelInfo.tour_info + '').replace(regex1, '');

    let tourist_places1 = (props.hotelInfo.nearest_tourist_places + '')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(regex, '')
        .trim();
    let imp_tourist_places1 = (props.hotelInfo.tour_info + '')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(regex, '')
        .trim();
    let tripadvisor_link = (props.hotelInfo.tripadvisor_link + '').trim();
    let holiday_iq_link = (props.hotelInfo.holiday_iq_link + '').trim();
    let whatsapp_no = props.hotelInfo.whatsapp_no + '';
    const removeSpaces = input => {
        const output = input.replace(/&nbsp;/g, '').trim();
        return output;
    };
    //Show Image slider
    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    const [hotelImg, setHotelImg] = useState();
    const changeAboutImage = (img) => {
        setHotelImg(config.public_image_url + img.replace(' ', '+'))
    }

    const [themeColor, setThemeColor] = useState("");

    useEffect(() => {
        setThemeColor(JSON.parse(sessionStorage.getItem('theme_color')));
    }, [sessionStorage.getItem('theme_color')])

    return (
        // Start about us section
        <div className='col s12'>
            <div className='tab-width'>

                <div className='row'>
                    <div className='about-us-wrapper'>

                        {typeof props.hotelInfo.hotel_name === 'undefined' && (
                            <AboutLoader />
                        )}

                        {typeof props.hotelInfo.hotel_name === 'string' && (
                            <div>
                                <div className="h-image-wrapper">
                                    <div className="left-first-image-wrap">
                                        <div className="h-image-first">
                                            {props && props.hotelInfo && props.hotelInfo.exterior_image && props.hotelInfo.exterior_image[0] &&
                                                <img className="h-image" src={config.public_image_url + props.hotelInfo.exterior_image[0].image_name.replace(' ', '+')} />}
                                        </div>
                                    </div>
                                    <div className="right-first-image-wrap">
                                        <div className="h-image-right-first" >
                                            {props && props.hotelInfo && props.hotelInfo.exterior_image && props.hotelInfo.exterior_image[1] &&
                                                <img className="h-image" src={config.public_image_url + props.hotelInfo.exterior_image[1].image_name.replace(' ', '+')} />}
                                        </div>
                                    </div>

                                    <div className="right-second-image-wrap">
                                        <div className="h-image-right-second" >
                                            {props && props.hotelInfo && props.hotelInfo.exterior_image && props.hotelInfo.exterior_image[2] &&
                                                <img className="h-image" src={config.public_image_url + props.hotelInfo.exterior_image[2].image_name.replace(' ', '+')} />}
                                        </div>
                                    </div>

                                    <div className="right-third-image-wrap" onClick={() => toggleSlider()}>
                                        <div className="h-image-right-third">
                                            {props && props.hotelInfo && props.hotelInfo.exterior_image && props.hotelInfo.exterior_image[3] &&
                                                <img className="h-image" src={config.public_image_url + props.hotelInfo.exterior_image[3].image_name.replace(' ', '+')} />}
                                            <div className="overlay-wrapper">
                                                <div className="h-overlay-class">
                                                    <div className="view-more-wrapper">
                                                        <div className="view-more-class">View More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {isSliderOpen && (
                                    <div className='modal-wrapper'>
                                        <div id='model1' className='modal scroll z-value'>
                                            <div
                                                className='card'
                                                style={{
                                                    marginTop: '0rem',
                                                    marginBottom: '0rem',
                                                    border: 'none'
                                                }}>
                                                <div className='imgSlider footer-img-slider'>
                                                    <span
                                                        onClick={() => toggleSlider()}
                                                        style={{ zIndex: '3400', position: "fixed", top: "4.5rem", right: "21rem" }}
                                                        className='modalBtnClose'
                                                    />
                                                    <ExternalImageSlide
                                                        images={props.hotelInfo.exterior_image}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='modal-overlay'
                                            style={{
                                                zIndex: '1201',
                                                display: 'block',
                                                opacity: '0.3'
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="about-tab-wrapper">
                                    <div className="h-wrapper">
                                        <div>
                                            <h1 className="h-wrapper-name">{props.hotelInfo.hotel_name}</h1>
                                            <span className="h-wrapper-rating">
                                                {/* {props.hotelInfo.total_star_property && props.hotelInfo.total_star_property.length > 0 && props.hotelInfo.total_star_property.map((item, idx) => (
                                                    <i className="fa fa-star property-star" aria-hidden="true" key={idx}></i>
                                                ))} */}
                                                {props.hotelInfo.total_star_property.length > 0 && props.hotelInfo.star_of_property && themeColor.theme_color &&
                                                    <Rating
                                                        initialValue={props.hotelInfo.star_of_property}
                                                        fillColor={themeColor.theme_color}
                                                        allowFraction={true}
                                                        readonly={true}
                                                        size={25}
                                                    />
                                                }
                                            </span>
                                        </div>
                                        <div className="h-wrapper-location">
                                            <span className="h-wrapper-address">{props.hotelInfo.hotel_address}</span>
                                        </div>
                                        <div>
                                            <div className="h-wrapper-contact-details">
                                                <span>
                                                    <i className='material-icons icon'>email</i>{' '}
                                                    {email_id_arr &&
                                                        email_id_arr.length > 0 &&
                                                        email_id_arr.map((item, i) => {
                                                            return (
                                                                <span className="h-wrapper-phone-email" key={i}> <a href={`mailto: ${item}`}>{item}</a> {i < email_id_arr.length - 1 && <span>,</span>}</span>
                                                            )
                                                        })
                                                    }
                                                </span>
                                                <span className="h-phone">
                                                    <i className='material-icons icon'>contact_phone</i>{' '}
                                                    {mobile_arr &&
                                                        mobile_arr.length > 0 &&
                                                        mobile_arr.map((item, i) => {
                                                            return (
                                                                <span className="h-wrapper-phone-email" key={i}> <a href={`tel: ${item}`}>{item}</a> {i < mobile_arr.length - 1 && <span>,</span>}</span>
                                                            )
                                                        })
                                                    }
                                                </span>

                                                {land_line && (
                                                    <span className="h-phone">
                                                        <i className='material-icons icon'>phone_in_talk</i>{'  '}
                                                        <span className="h-wrapper-phone-email" > <a href={`tel: ${land_line}`}>{land_line}</a></span>
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {about_hotel && (
                                    <div className="about-tab-wrapper">
                                        <div className="h-wrapper">
                                            <div className="header-wrapper"><span className="tab-title">Description</span></div>
                                            <hr />
                                            <div>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: about_hotel }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="tourist-places-wrapper">
                                    <div className="row">
                                        <div className="col m6 s12">
                                            {props && props.hotelInfo && props.hotelInfo.nearest_tourist_places && props.hotelInfo.nearest_tourist_places !== "<p><br></p>" &&
                                                (
                                                    <div className="about-tab-wrapper tourist-tab">
                                                        <div className="h-wrapper">
                                                            <div className="header-wrapper"><span className="tab-title">Nearest Tourist Places</span></div>
                                                            <hr />
                                                            <div>
                                                                <span
                                                                    dangerouslySetInnerHTML={{ __html: props.hotelInfo.nearest_tourist_places }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="col m6 s12">
                                            {props && props.hotelInfo && props.hotelInfo.tour_info && props.hotelInfo.tour_info !== "<p><br></p>" &&
                                                (
                                                    <div className="about-tab-wrapper tourist-tab">
                                                        <div className="h-wrapper">
                                                            <div className="header-wrapper"><span className="tab-title">Important Tourist Places</span></div>
                                                            <hr />
                                                            <div>
                                                                <span
                                                                    dangerouslySetInnerHTML={{ __html: props.hotelInfo.tour_info }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>




                                {hotelAmenities && hotelAmenities.length > 0 && <div className="about-tab-wrapper">
                                    <div className="h-wrapper">
                                        <div className="header-wrapper"><span className="tab-title">Amenities</span></div>
                                        <hr />
                                        <div className="row">
                                            {hotelAmenities.map((amenity, index) => {
                                                if (amenity.amenity_name.length > 0) {
                                                    return (
                                                        <div className='col s12 m6 mb0' key={index}>
                                                            <div>
                                                                <p className="amenities-title">
                                                                    <span>
                                                                        <i className={amenity.category_icon} />&nbsp; {amenity.catgegories}{' '}
                                                                    </span>{' '}:
                                                                </p>
                                                                <span className="amenities-list">
                                                                    {amenity.amenity_name.map((a, i, am) => {
                                                                        return i < am.length - 1
                                                                            ? a.trim() + ', '
                                                                            : a.trim();
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                };
                                            })}
                                        </div>
                                    </div>
                                </div>}


                                <div className="about-tab-wrapper">
                                    <div className="h-wrapper">
                                        <div className="header-wrapper"><span className="tab-title">Details</span></div>
                                        <hr />
                                        <div className="h-wrapper-details">


                                            {round_clock_check_in_out === 0 && checkIn && <span className="h-details-span-wrpper">
                                                <i className='material-icons icon'>alarm_on</i>{' '}
                                                <span className="h-details-list">Check In :{'  '}
                                                    {checkIn.length === 8
                                                        ? checkIn.slice(0, 5)
                                                        : checkIn}</span>
                                            </span>}


                                            {round_clock_check_in_out === 0 && checkOut && <span className="h-details-span-wrpper">
                                                <i className='material-icons icon'>alarm_off</i>{' '}
                                                <span className="h-details-list">Check Out :{'  '}
                                                    {checkOut.length === 8
                                                        ? checkOut.slice(0, 5)
                                                        : checkOut}</span>
                                            </span>}

                                            {round_clock_check_in_out === 1 && <span className="h-details-span-wrpper">
                                                <i className='material-icons icon'>alarm_on</i>{' '}
                                                <span className="h-details-list">24/7 Check-In / Check-Out{'  '}</span>
                                            </span>}


                                            {airports && airports.length > 0 && airports[0] !== 'null' && <span className="h-details-span-wrpper">
                                                <i className='material-icons icon'>local_airport</i>{' '}
                                                {airports.map((item, id) => {
                                                    return (
                                                        <span className="h-details-list" key={id}>{item} {distance_airport && distance_airport.length > 0 && distance_airport[id] &&
                                                            <span>
                                                                ({distance_airport[id]} K.M)
                                                            </span>} {id < airports.length - 1 && <span>,</span>}</span>
                                                    )
                                                })
                                                }
                                            </span>}


                                            {rail_station && rail_station.length > 0 && rail_station[0] !== 'null' && <span className="h-details-span-wrpper">
                                                <i className='material-icons icon'>train</i>{' '}
                                                {rail_station.map((item, id) => {
                                                    return (
                                                        <span className="h-details-list" key={id}>{item} {distance_rail_station && distance_rail_station.length > 0 && distance_rail_station[id] &&
                                                            <span>
                                                                ({distance_rail_station[id]} K.M)
                                                            </span>} {id < rail_station.length - 1 && <span>,</span>}</span>
                                                    )
                                                })
                                                }
                                            </span>}


                                            {bus_stop && bus_stop.length > 0 && bus_stop[0] !== 'null' && <span className="h-details-span-wrpper">
                                                <i className='fa fa-bus '></i>{' '}
                                                {bus_stop.map((item, id) => {
                                                    return (
                                                        <span className="h-details-list" key={id}>{item} {distance_bus_stop && distance_bus_stop.length > 0 && distance_bus_stop[id] &&
                                                            <span>
                                                                ({distance_bus_stop[id]} K.M)
                                                            </span>} {id < bus_stop.length - 1 && <span>,</span>}</span>
                                                    )
                                                })
                                                }
                                            </span>}


                                            {/* {star_rating && star_rating.length > 0 && <span className="h-details-span-wrpper">
                                                <span className="h-details-list">Star Rating :{'  '}

                                                    {star_rating.map((item, idx) => (
                                                        <i className="fa fa-star property-star" aria-hidden="true" key={idx}></i>
                                                    ))}

                                                </span>
                                            </span>} */}


                                            <div>

                                                <div className='row'>
                                                    <div className='col m6 s12'>
                                                        {((tripadvisor_link && tripadvisor_link !== '') ||
                                                            (holiday_iq_link && holiday_iq_link !== '')) && (
                                                                <div>
                                                                    <p className="h-feedback-list">
                                                                        <i className='material-icons icon'>
                                                                            feedback
                                                                        </i>{' '}
                                                                        Feedback Links:
                                                                        {tripadvisor_link &&
                                                                            tripadvisor_link !== '' && (
                                                                                <a href={tripadvisor_link}>
                                                                                    <img
                                                                                        src={tripAdvisor}
                                                                                        width='25'
                                                                                        style={{ marginLeft: '10px' }}
                                                                                        alt='trip advisor'
                                                                                    />
                                                                                </a>
                                                                            )}{' '}
                                                                        {holiday_iq_link && holiday_iq_link !== '' && (
                                                                            <a href={holiday_iq_link}>
                                                                                <img
                                                                                    width='25'
                                                                                    src={holidayIq}
                                                                                    alt='holiday iq'
                                                                                    style={{ marginLeft: '10px' }}
                                                                                />
                                                                            </a>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>

                                                    <div className='col m6 s12'>
                                                        {whatsapp_no && (
                                                            <div className="whatsapp-wrapper">
                                                                <img width='25' src={whatsapp} alt='whatsapp' />
                                                                &nbsp;
                                                                <span className="whatsapp-hyperlink">
                                                                    <a className="whatsapp-class" href={"https://api.whatsapp.com/send?phone=91" + whatsapp_no} >
                                                                        {whatsapp_no}
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
        </div>
        // End about us section
    );
};
//Export About Section
export default hotelAboutNew;