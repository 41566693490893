import config from '../config';
const VisitorSaveComponent = (company_id, hotel_id, browser, href, referer) => {
  if (
    company_id != '' &&
    hotel_id != '' &&
    browser != '' &&
    href != '' &&
    referer != ''
  ) {
    fetch(config.apiEndpoint + 'dashboard/uniqueVisitors/' + hotel_id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'text/json'
      },
      body: JSON.stringify({
        company_id: company_id,
        visitor_browser: browser,
        visitor_refferer: referer,
        visitor_page: href,
        visitor_ip: '1.1.1.1'
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 1) {
        }
      });
  }
};

//Export getCurrencyValue
export default VisitorSaveComponent;
