import config from "../../../config";
const fetchHotelInfo = async (
  hotelId,
  apiKey,
  setOverSeaFilter,
  setIsTaxable,
  setisModify,
  setIsCancellable
) => {
  fetch(
    config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotelId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        setOverSeaFilter(responseJson.data.is_overseas);
        setIsTaxable(responseJson.data.is_taxable);
        if (responseJson.data.is_cancellable) {
          setIsCancellable(true);
        } else {
          setIsCancellable(false);
        }
        if (responseJson.data.is_modified) {
          setisModify(true);
        } else {
          setisModify(false);
        }
        sessionStorage.setItem(
          "facebook_pixel_data",
          JSON.stringify(responseJson.data.facebook_pixel)
        );
        sessionStorage.setItem(
          "google_tag_manager",
          JSON.stringify(responseJson.data.google_tag_manager)
        );
      }
    })
    .catch((error) => {});
};
//Export getAccess
export default fetchHotelInfo;
