import config from '../../config'
const getPaidServices = (hotelId, setPaidServices, setNoPaidServices, setPaidServicesLoader) => {
    let url = config.apiEndpoint + 'paidServices/' + hotelId
    fetch(url,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json'
            }
        }).then(response => response.json())
        .then((responseJson) => {
            if (responseJson.status === 1) {
                responseJson.paidServices.map((item) => {
                    let gst_price = 0;
                    if (item.service_tax !== 0) {
                        gst_price = item.service_amount * item.service_tax / 100
                        item.service_tax_price = gst_price;
                    }
                    else {
                        item.service_tax_price = '-'
                    }
                })

                setPaidServices(responseJson.paidServices);
                setNoPaidServices(false);
                setPaidServicesLoader(false);
            }
            else {
                setPaidServices([]);
                setNoPaidServices(true);
                setPaidServicesLoader(false);

            }
        })
}

//Export default getPaidServices
export default getPaidServices

