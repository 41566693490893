import React, { useState, useEffect } from 'react';
import getReviews from './getReviews';
import ReviewLoader from './reviewLoader';
import booking from '../../img/booking.png'
const hotelReviews = (props) => {
    const [reviews, setReviews] = useState([])
    const [noReviewsData, setNoReviewsData] = useState('')
    const [loading, setLoading] = useState(true)
    const [showNoReview, setShowNoReview] = useState(false);
    //Call api to get review getReviews
    useEffect(() => {
        if (props.bookingDotComPropertyId && props.bookingDotComPropertyId !== 0) {
            getReviews(props.bookingDotComPropertyId, setReviews, setLoading)
            setLoading(true)
        }
    }, [props.bookingDotComPropertyId, setReviews])
    //Calculate the scores of Facility,Comfort,Review Score,Value,Staff and Location
    const [scores, setScores] = useState({ clean: 0, comfort: 0, facilities: 0, location: 0, review_score: 0, staff: 0, value: 0 })
    const [reviewContent, setReviewContent] = useState([])
    useEffect(() => {
        if (reviews.length > 0) {
            for (let i = 0; i < reviews.length; i++) {
                scores.clean += reviews[i].scoring && reviews[i].scoring.clean
                scores.comfort += reviews[i].scoring && reviews[i].scoring.comfort
                scores.facilities += reviews[i].scoring && reviews[i].scoring.facilities
                scores.location += reviews[i].scoring && reviews[i].scoring.location
                scores.review_score += reviews[i].scoring && reviews[i].scoring.review_score
                scores.staff += reviews[i].scoring && reviews[i].scoring.staff
                scores.value += reviews[i].scoring && reviews[i].scoring.value
                //Reviews content
                if (reviewContent.length < 5) {
                    if (reviews[i].hasOwnProperty('content')
                        && reviews[i].content !== null
                        && reviews[i].content.hasOwnProperty('positive')
                        && reviews[i].content.positive !== null
                        && reviews[i].content.positive !== ''
                        && reviews[i].reviewer !== null) {
                        reviewContent.push({
                            positive: reviews[i].content.positive,
                            guest_name: reviews[i].reviewer !== null && reviews[i].reviewer.hasOwnProperty('name') ? reviews[i].reviewer.name : "",
                            genius: reviews[i].reviewer !== null && reviews[i].reviewer.hasOwnProperty('is_genius') && reviews[i].reviewer.is_genius ? 'Genius' : '',
                            timeStamp: reviews[i].last_change_timestamp,
                            rating: ((parseInt(reviews[i].scoring.clean) +
                                parseInt(reviews[i].scoring.comfort) +
                                parseInt(reviews[i].scoring.facilities) +
                                parseInt(reviews[i].scoring.location) +
                                parseInt(reviews[i].scoring.review_score) +
                                parseInt(reviews[i].scoring.staff) +
                                parseInt(reviews[i].scoring.value)) / 7).toFixed(2)
                        })
                    }
                }
            }
            scores.clean = (scores.clean / reviews.length).toFixed(2)
            scores.comfort = (scores.comfort / reviews.length).toFixed(2)
            scores.facilities = (scores.facilities / reviews.length).toFixed(2)
            scores.location = (scores.location / reviews.length).toFixed(2)
            scores.review_score = (scores.review_score / reviews.length).toFixed(2)
            scores.staff = (scores.staff / reviews.length).toFixed(2)
            scores.value = (scores.value / reviews.length).toFixed(2)
            let scoreObjet = {}
            Object.assign(scoreObjet, scores)
            setScores(scoreObjet)
            let newReviewContent = [...reviewContent]
            setReviewContent(newReviewContent)
            setLoading(false)
            setNoReviewsData(false)
        } else {
            setLoading(false)
        }
    }, [reviews])
    //Set No reviews data to true when loading is false
    useEffect(() => {
        if (!loading) { setNoReviewsData(true) }
    }, [loading])
    return (
        // Start reviews section
        <div id="tab-reviews" className="col s12">
            <div className="tab-width">
                <div className="row reviews-right-padding">
                    {/* Start review wrapper */}
                    <div className="reviews-wrapper">
                        {loading && <ReviewLoader />}
                        {reviews.length === 0 &&
                            <div className="row">
                                <div className="col s12 m12">
                                    <p style={{ textAlign: 'center', marginBottom: '1rem' }}>No Reviews found for this property!</p>
                                </div>
                            </div>
                        }
                        {reviewContent.length > 0 &&
                            <div>
                                <div className="row">
                                    <div className="col s12 m9">
                                        {scores &&
                                            <div>
                                                <img src={booking} style={{ float: 'left', marginTop: '-16px' }} alt="booking.com logo" /><span style={{ float: 'left' }}>Verified Reviews</span>

                                                <div style={{ clear: 'both' }} className="card-action">
                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Facility  <i>{scores.facilities}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Comfort  <i>{scores.comfort}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Review Score  <i>{scores.review_score}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Value  <i>{scores.value}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Clean  <i>{scores.clean}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Staff  <i>{scores.staff}/10</i></span>
                                                    </span>

                                                    <span className="hover-wrapper">
                                                        <span className="reviews-btn">Location  <i>{scores.location}/10</i></span>
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="rating-box flow-text">
                                            <div className="ratingWrap">
                                                <strong>{(((parseInt(scores.facilities) + parseInt(scores.comfort) + parseInt(scores.review_score) + parseInt(scores.value) + parseInt(scores.clean) + parseInt(scores.staff) + parseInt(scores.location)) / 7)).toFixed(2)}/10</strong>
                                                <p>{reviews.length} Ratings</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr style={{ margin: '1%' }} />
                                    {reviewContent && reviewContent.map((reviewName, index) =>
                                        <div key={index} className="col s12 m12">
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="row">
                                                        <div className="col s12 m10">
                                                            <span className="userProfile">
                                                                <span className="initial">{reviewName.guest_name.charAt(0).toUpperCase()}</span>
                                                            </span>

                                                            <span className="userReviewName">
                                                                <p>{reviewName.guest_name}</p>
                                                                <p>{reviewName.genius !== '' && reviewName.genius + ' | '}{reviewName.timeStamp}</p>
                                                            </span>
                                                        </div>

                                                        <div className="col s12 m2">
                                                            <div className="rankingWrapGreen">
                                                                <p>{reviewName.rating + '/10'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <hr style={{ margin: '1%' }} />
                                                        <div className="col s12 m12">
                                                            <p>{reviewName.positive}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {/*End review wrapper*/}
                </div>
            </div>
        </div>
        // End reviews section
    )
}

export default hotelReviews;