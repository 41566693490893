import React, { useContext, useState } from 'react';
import Cart from './Cart/Cart';
import Packages from './Packages/Packages';

const PackagePanel = props => {
  const [cart, setCart] = useState([]);
  // const [taxRules, setTaxRules] = useState([]);
  //Set the values required to cart component
  const getCart = (cart, taxRules) => {
    setCart(cart);
    // setTaxRules(taxRules);
  };
  //Remove the cart Item From cart component
  const [removedCartItem, setRemovedCartItem] = useState({});
  const updateCartInRooms = cartItem => {
    let removeObject = {};
    Object.assign(removeObject, cartItem);
    setRemovedCartItem(removeObject);
  };
  const setNewCheckAvail = checkAvail => {
    props.callBackToSetCheckAvail(checkAvail);
  };
  return (
    <section>
      <div className='container container-width top-margin'>
        <div className='row'>
          <Cart
            cart={cart}
            callbackCart={updateCartInRooms}
            // taxRulesObject={taxRules}
            callBackToSetCheckAvail={setNewCheckAvail}
            checkAvail={props.checkAvail}
          />
          <Packages
            addToCartCallBack={getCart}
            removedCartItem={removedCartItem}
            callBackToSetCheckAvail={setNewCheckAvail}
            checkAvail={props.checkAvail}
          />
        </div>
      </div>
    </section>
  );
};
//export PackagePanel class
export default PackagePanel;
