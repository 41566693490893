import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useGoogleMap, useMap } from "./mapHook";
const API_KEY = "AIzaSyDhShnWZr1GVyY4fI8Xu6d6l4V954Mlktg";


// hook
const hotelMap = (props) => {
  const initialConfig = props.hotelInfo.hasOwnProperty("latitude") && {
    zoom: 14,
    center: { lat: parseFloat(props.hotelInfo.latitude), lng: parseFloat(props.hotelInfo.longitude) },
    hotel_address: props.hotelInfo && props.hotelInfo.hotel_address
  };
  const googleMap = useGoogleMap(API_KEY);
  const mapContainerRef = useRef(null);
  useMap({ googleMap, mapContainerRef, initialConfig });
  return (
    <div style={{ height: '60vh', width: '100%', padding: '2%', border: '1px solid #aaa', borderTop: 'none' }}>
      <div
        style={{
          height: "54vh",
          width: "100%"
        }}
        ref={mapContainerRef}
      />
    </div>
  );
};

export default hotelMap;
