import config from "../../../config";

const changeUserMobileNumber = (
  mobile_no,
  change_mobile,
  company_id,
  mobileNumberChangeStatus,
  setMobileNumberChangeMessage,
  setMobileNumberChangeIcon
) => {
  mobileNumberChangeStatus++;
  fetch(config.apiEndpoint + "public_user/change_mobile_number", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      company_id: company_id,
      status: mobileNumberChangeStatus,
      mobileno: mobile_no,
      new_mobile_no: change_mobile,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        setMobileNumberChangeMessage(
          "Mobile Number has been changed successfully !"
        );
        setMobileNumberChangeIcon("success-icon");
      } else {
        setMobileNumberChangeMessage("Mobile Number updation failed!");
        setMobileNumberChangeIcon("cancel-icon");
      }
    })
    .catch((error) => {});
};

export default changeUserMobileNumber;
