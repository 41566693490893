import config from '../config';

const fetchNotificationSlider = async (hotel_id, setSliderImages) => {
    let url =
        config.apiEndpoint +
        "fetch-be-notification-slider-images/" +
        hotel_id;
    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-type": "text/json",
        },
    })
        .then((response) => response.json())
        .then((responsejson) => {
            if (responsejson.status == 1) {
                let image_routes = [];
                if (responsejson.notification_slider_details && responsejson.notification_slider_details.image_name && responsejson.notification_slider_details.image_name.length > 0) {
                    let image_data = responsejson.notification_slider_details.image_name;
                    for (let i = 0; i < image_data.length; i++) {
                        image_routes.push(config.public_image_url + image_data[i]);
                    }
                }
                setSliderImages(image_routes);
            } else {
                setSliderImages([]);
            }
        });
};
export default fetchNotificationSlider;
